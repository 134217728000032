@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaLight.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaLight-Italic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaRegular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaRegular-Italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaMedium.woff") format("woff");
  font-weight: 500;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaMedium-Italic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaBold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaBold-Italic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaBlack.woff") format("woff");
  font-weight: 800;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaBlack-BlackItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaUltra.otf") format("woff");
  font-weight: 900;
}

@font-face {
  font-family: "Gordita";
  src: url("../../fonts/GorditaUltraItalic.otf") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Monaco";
  src: url("../../fonts/Monaco.woff") format("woff");
  font-weight: 400;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Tue Jun 16 2020 */
@font-face {
  font-family: "Glyphter";
  src: url("../../fonts/Glyphter.eot");
  src: url("../../fonts/Glyphter.eot?#iefix") format("embedded-opentype"), url("../../fonts/Glyphter.woff") format("woff"), url("../../fonts/Glyphter.ttf") format("truetype"), url("../../fonts/Glyphter.svg#Glyphter") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class*='icon-']:before {
  display: inline-block {
    font-family: "Glyphter";
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  };
}

.icon-arrow:before {
  content: "A";
}

.icon-arrow-down:before {
  transform: rotate(90deg);
  content: "A";
}

.icon-close:before {
  content: "B";
}

.icon-document:before {
  content: "C";
}

.icon-download:before {
  content: "D";
}

.icon-history:before {
  content: "E";
}

.icon-info:before {
  content: "F";
}

.icon-logout:before {
  content: "G";
}

.icon-members:before {
  content: "H";
}

.icon-person:before {
  content: "I";
}

.icon-plus:before {
  content: "J";
}

.icon-reminder:before {
  content: "K";
}

.icon-search:before {
  content: "L";
}

.icon-settings:before {
  content: "M";
}

.icon-share:before {
  content: "N";
}

.icon-signed:before {
  content: "O";
}

.icon-trash:before {
  content: "P";
}

.icon-waiting:before {
  content: "Q";
}

.icon-validate:before {
  content: "R";
}

.icon-warning:before {
  content: "S";
}

.icon-arrow-right:before {
  content: "T";
}

.icon-lock:before {
  content: "U";
}

.icon-edit:before {
  content: "V";
}

.icon-visibility-off:before {
  content: "W";
}

.icon-visibility:before {
  content: "X";
}

.icon-triangle-down:before {
  content: "Y";
}

.icon-dots:before {
  content: "Z";
}

.icon-checked:before {
  content: "a";
}

.icon-arrow-left:before {
  content: "b";
}
