@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes heightIn {
  0% {
    height: 44px;
    border-radius: 40px;
  }
  100% {
    height: 206px;
    border-radius: 16px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  }
}

@keyframes heightOut {
  0% {
    height: 206px;
    border-radius: 16px;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  }

  100% {
    height: 44px;
    border-radius: 40px;
  }
}

.navbar {
  background: $white;
  height: 90px;
  margin-bottom: 23px;

  @media (max-width: 1000px) {
    margin-bottom: 0;
    height: 73px;
  }

  &__content-desktop {
    display: flex;
    justify-content: space-between;
    width: 86%;
    height: 100%;
    margin: auto;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__left-side {
    @include fleXCenterCenter;

    img {
      max-width: 97px;
    }

    a {
      &:nth-child(2), &:nth-child(3) {
        font-weight: 500;
        margin-left: 68px;
      }
    }
  }

  &__right-side {
    display: flex;
    margin: 22px 0 0 0;

    .btn--dropdown {
      min-width: 247px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-right: 23px;
      animation: heightOut .3s ease-in-out forwards;
      z-index: 10;

      &:hover {
        animation: heightIn .3s ease-in-out forwards;
      }

      .dropdown-content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 5px;

        & > * {
          height: 20px;
          background-color: $white;
        }

        .account-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .arrow-down {
          transition: transform 0.4s ease-out;
        }
      }

      &:hover {
        .dropdown-content {
          .account-title {
            white-space: unset;
            overflow: unset;
            text-overflow: unset;
            height: auto;
          }

          .arrow-down {
            transform: rotate(-180deg);
          }
        }
      }
    }

    p:not(.account-title) {
      margin: 0 16px 0 9px;
      @include fleXCenterCenter;
    }
  }

  .btn--dropdown:hover .dropdown-menu--animated {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
  }
  .dropdown-menu--animated {
    display: none;
    height: 0px;
  }

  .dropdown-menu-animation {
    animation: slideDown .5s ease-in-out forwards;
    height: 100%;

    li {
      display: block;
      opacity: 1;
    }
  }

  .dropdown-menu {
    padding-top: 32px;

    a {
      padding: 3px 20px;
      display: flex;
      font-size: 15px;
      width: 100%;

      p {
        color: $white;
        font-size: 12px;
      }
    }

    li {
      display: flex;
      justify-content: space-between;
      font-weight: initial;
      margin-bottom: 14px;

      p {
        color: $black;
        font-weight: 500;
      }
    }
  }

  &__mobile {

    &-header {
      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        height: 73px;

        li {
          img {
            max-width: 80px;
            z-index: 2;
            position: relative;
          }
        }
      }
    }

    &-content {
      display: none;
      flex-direction: column;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 90px 15px 0 15px;
      overflow-y: scroll;
      z-index: 1;
      background: white;

      .navbar__mobile-user {
        display: flex;
        align-items: center;
        width: 100%;
        padding-bottom: 12px;
        border-bottom: 2px solid $mediumGray;

        p {
          margin-left: 10px;
        }
      }

      ul {
        &:nth-child(1) {
          border-radius: 8px;
          border: 1px solid $pacificBlue;
          padding: 15px 22px 0;

          li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;

            p {
              font-weight: 500;
            }

            a {
              display: flex;
              justify-content: center;
              margin: auto;

              p {
                margin-left: 5px;
              }
            }
          }
        }

        &:nth-child(2) {
          li {
            display: flex;
            align-items: center;
            margin-top: 22px;
            font-weight: 500;

            img {
              margin-right: 8px;
              width: 16px;
            }
          }

          .sidebar__language {
            border: none;

            .language--current {
              height: 20px;

              &:before {
                color: $black;
                left: 85px;
                top: 58%;
              }

              .language {
                padding-left: 24px;
                color: $black;

                img {
                  left: 0;
                }
              }
            }

            .language--list {
              height: 120px;
            }

            .sidebar__menu-item {
              @media (max-width: 1000px) {
                &::before {
                  left: -3px;
                }
              }
            }
          }
        }
      }

      @media screen and (max-width: 1000px) {
        display: flex;
        transform: translate3d(100%, 0, 0);
        transition: transform 0.4s ease-out;
        will-change: transform;

        &.active {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .mobile-menu {
      position: relative;
      cursor: pointer;
      margin-left: 13px;
      width: 23px;
      height: 29px;
      display: none;
      z-index: 6;

      @media screen and (max-width: 1050px) {
        display: block;
      }

      .menu__icon {
        position: absolute;
        right: 0;

        @include translateY;

        &__line {
          width: 20px;
          height: 2px;
          background-color: $pacificBlue;
          margin-bottom: 7px;
          transition: all 0.3s ease-out;
          will-change: transform, opacity;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            width: 15px;
          }
        }
      }

      &.active {
        .menu__icon {
          &__line {
            &:first-child {
              transform: translateY(9px) rotate(45deg);
            }

            &:nth-child(2) {
              opacity: 0;
            }

            &:last-child {
              transform: translateY(-9px) rotate(-45deg);
            }
          }
        }
      }
    }

    @media (min-width: 1000px) {
      display: none;
    }
  }
}