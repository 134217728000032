.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;

  .page-size-filters {
    width: 75px;
    margin-left: 2px;
  }

  .page-size-label {
    margin: 10px 0;
  }
}

.pagination__number {
  span {
    font-weight: 500;
    margin: 0 5px;
  }
}

.pagination__buttons {
  .btn {
    width: 32px;
    height: 32px;
    background: none;
    border: 0;
    position: relative;
    outline: none;
    padding: 10px 39px;

    &::before {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      font-family: "Glyphter", serif;
      line-height: 32px;
    }

    &.next {
      &::before {
        content: "A";
      }
    }

    &.prev {
      transform-origin: center;
      transform: rotate(180deg);

      &::before {
        content: "A";
      }
    }

    @media (max-width: 1024px) {
      padding: 10px 39px;
    }

    @media (max-width: 601px) {
      padding: 10px 30px;
    }

    @media (max-width: 390px) {
      padding: 10px 24px;
    }

    @media (max-width: 360px) {
      padding: 10px 13px;
    }
  }

  &--center-items {
    display: flex;
    align-items: center;
  }

  &--right-align {
    position: absolute;
    z-index: 10;
    right: 0;

    @media (max-width: 700px) {
      position: relative;
    }
  }

  &--center-items {
    display: flex;
    align-items: center;
  }

  &--right-align {
    position: absolute;
    z-index: 10;
    right: 0;

    @media (max-width: 700px) {
      position: relative;
    }
  }
}