.login-full-height-container {
  background-color: $white;
  height: 100vh;
  display: flex;
  min-height: 770px;

  @media only screen and (max-width: 688px) {
    flex-direction: column;
    background-color: transparent;
    align-items: center;
  }

  .leftbar-container {
    background-color: $darkBlue;
    width: 40%;
    min-height: 770px;

    @media only screen and (max-width: 688px) {
      width: 100%;
      min-height: auto;
    }

    .heading-text {
      margin-top: 223px;

      @media only screen and (max-width: 688px) {
        margin-top: 120px;
        padding: 0 10px 80px 10px;
      }

      @media (min-width: 688px) and (max-width: 1000px) {
        margin: 223px 0 0 0;
      }

      @media (max-width: 688px) {
        margin: 120px 0 0 0;
      }

      .text-heading {
        font-size: 33px;
        font-weight: bold;
        color: $skyBlue;
        line-height: 43px;
        margin: 0 0 30px 0;

        span {
          color: $white;
        }

        @media only screen and (max-width: 1180px) {
          font-size: 21px;
        }

        @media only screen and (max-width: 688px) {
          margin-bottom: 0;
          text-align: center;
          font-size: 24px;
        }

        @media only screen and (max-width: 280px) {
          font-size: 18px;
        }
      }
    }

    .heading-middle {
      width: 68%;
      margin-bottom: 30px;

      @media only screen and (max-width: 1000px)  {
        width: 100%;
      }

      @media only screen and (max-width: 688px) {
        margin-top: -60px;
        width: 90%;
        margin-left: 5%;

        p {
          text-align: center;
          line-height: 24px !important;
        }
      }

      .padding--top-none {
        padding-top: 0 !important;
      }

      .middle-login {
        @media only screen and (max-width: 688px) {
          margin-top: -60px;
          width: 90%;
          margin-left: 5% !important;

          p {
            text-align: center;
            line-height: 24px !important;
          }
        }
      }

      .quotation {
        margin: 0 !important;
        width: 30px;
        height: 30px;

        @media only screen and (max-width: 688px) {
          margin-left: 130px !important;
        }
      }

      .middle-paragraph {
        color: $white;
        margin-bottom: 30px;
      }

      .quotes {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media only screen and (max-width: 688px) {
          margin-left: 11px !important;
        }

        img {
          margin: 0 !important;
          padding-top: 20px;

          @media only screen and (max-width: 688px) {
            padding-top: 4px;
          }
        }

        p {
          color: $white;
          font-size: 16px;
          line-height: 21px;
          padding: 20px;
          padding-bottom: 0px;

          @media only screen and (max-width: 688px) {
            text-align: justify;
            padding: 12px;
          }
        }
      }
    }

    .paragraph-part {
      @media only screen and (max-width: 688px) {
        padding-bottom: 65px;
      }
    }

    .person {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 30px;

      @media only screen and (max-width: 688px) {
        justify-content: center;
        margin-left: -12px !important;
        margin-top: 0 !important;
        margin-bottom: 5px !important;
      }

      .person-img {
        margin: 0 !important;
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }

      .person-text {
        color: $white;
        font-size: 14px;
        margin-left: 10px;

        .person-occupation {
          color: $skyBlue;
        }
      }
    }

    .last_part {
      width: 60%;

      @media only screen and (max-width: 688px) {
        margin: 0;
        position: relative;
        top: 37px;
        left: 47%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }

      .img-mono {
        margin: 10px 0 !important;
        width: 60%;

        @media only screen and (max-width: 688px) {
          margin-left: 45px !important;
          padding-bottom: 20px !important;
        }

        @media only screen and (min-width: 370px) {
          padding-bottom: 0px !important;
        }
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5% 0;
        background-color: $skyBlue;
        color: $white;
        height: 50px;
        border: none;
        width: 231px;
        border-radius: 100px;

        img {
          margin-left: 10px !important;
        }

        @media only screen and (max-width: 688px) {
          margin-left: 9px !important
        }

        @media only screen and (max-width: 280px) {
          margin-left: -41px !important
        }
      }
    }

    .bottom-part {
      p {
        text-align: start;
        color: $skyBlue;
        font-size: 16px;
        font-weight: 600;

        @media only screen and (max-width: 688px) {
          margin-left: -90px;
          text-align: center;
          font-weight: 400;
        }
      }

      .partnership-logo {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-row-gap: 24px;
        grid-column-gap: 10px;
        margin-top: 40px;

        @media only screen and (max-width: 688px) {
          margin-left: 46px;
          margin-bottom: 15px;
        }

        img {
          margin: 0 !important;
          padding: 0 !important;
          max-width: 85px;
        }

        .raso {
          padding-top: 8px !important;
        }
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
      padding: 0 24px;
      max-width: 585px;
      position: relative;

      .heading-text {
        width: 100%;
      }

      &--marksign-logo{
        .logo {
          width: 130px;
          height: 52px;
          position: absolute;
          left: 0;
          top: 38px;
        }
      }
    }
  }

  .rightbar-container {
    width: 60%;

    @media only screen and (max-width: 688px) {
      width: 95%;
    }

    &__wrapper {
      background-color: $white;
      margin: auto;
      max-width: 568px;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (min-width: 1281px) {
        min-width: 566px;
      }

      @media only screen and (max-width: 688px) {
        margin-top: -50px;
        border-radius: 10px;
        padding-top: 75px !important;
      }

      button {
        @media (max-width: 540px) {
          padding: 10px 39px;
        }
      }
    }

    &--signature-type {
      background-color: $white;
      margin: 32px auto 0 auto;
      max-width: 568px;
      position: relative;

      @media only screen and (min-width: 1281px) {
        min-width: unset;
      }
    }

    .login-content {
      p {
        @media only screen and (max-width: 688px) {
          font-size: 14px;
          font-weight: 400;
          padding-top: 10px;
        }
      }
    }

    .login-method {
      @media only screen and (min-width: 688px) {
        margin-left: 0px !important;
        margin-top: 70px;
      }
    }

    .confirm-phone-number {
      display: flex;
      justify-content: space-between;

      .css-26l3qy-menu {
        left: 0;
      }

      .form-number-code {
        width: 70px;
        min-width: 70px;
        padding-right: 0;
        border: 1px solid #EDEFF2;

        &:after {
          content: '';
          position: absolute;
          background: url("../../../web/illustration/chevron-down.svg");
          background-repeat: no-repeat;
          height: 10px;
          width: 13px;
          top: 17px;
          left: 8px;
        }

        div {
          &:first-child {
            border-right: none;
            border-radius: 0;
            border-color: #EDEFF2;
            justify-content: flex-end;
            padding-right: 0;
          }
        }
      }
    }

    h5 {
      font-weight: 500;
      margin-bottom: 30px;
      font-size: 20px;
      padding-top: 65px;
    }

    .login-type {
      font-weight: 400;
      margin-bottom: 30px;
      margin-top: 30px;
      text-align: center;

      a {
        color: $pacificBlue;
      }
    }

    #enter-email-address {
      color: $darkGray;
      margin-bottom: 10px;
    }

    #enter-phone-number {
      color: $darkGray;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    #login-checkbox {
      font-weight: unset;
      margin-top: 20px;
    }
  }

  .show-hide-button {
    display: none;

    @media only screen and (max-width: 688px) {
      display: inline;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      padding: 25px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 50%;
      background-color: $skyBlue;
      border: none;
      color: $white;
      z-index: 1;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .image-roate {
      position: relative;
      left: -3.8px;
      top: -7px;
      transform: rotate(-90deg);
    }

    .image-roated {
      position: relative;
      left: -3.8px;
      top: -7px;
      transform: rotate(90deg);
    }
  }
}

.registration {
  text-align: justify !important;
  margin-top: 5px !important;

  @media only screen and (max-width: 688px) {
    padding-left: 0 !important;
    font-size: 22px !important;
    font-weight: bold !important;
    text-align: center !important;
  }

  @media only screen and (max-width: 280px) {
    font-size: 12px;
  }
}

.padding--top-none {
  padding-top: 0 !important;
}

.login-tabs-new {
  display: flex;
  justify-content: center;

  button {
    padding: 8px 19px;
    color: $gray;
    display: flex;
    width: 139px;
    height: 50px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    border-bottom: 2px solid transparent;
    border-radius: 25px;
    transition: color 0.2s ease;
    position: relative;
    box-shadow: 0 3px 6px rgba(50, 62, 100, 0.04);
    white-space: nowrap;

    @media (max-width: 375px) {
      justify-content: center;
      display: flex;
    }

    @media (max-width: 688px) {
      width: 202px;
    }

    img {
      height: 25px;
      width: 34px;
      object-fit: contain;
      padding-right: 5px;
      margin: 0 !important;
    }

    p {
      align-items: center;
      display: flex;
      text-align: justify;
    }

    &.active {
      box-shadow: 0 3px 6px rgba(50, 62, 100, 0.2);
    }
  }

  .usb {
    font-size: 9px;
    font-weight: 500;
  }
}

.login-mode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 1000px) {
    margin-top: 70px;
  }

  @media only screen and (min-width: 1300px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 1000px) {
    margin-top: 70px;
  }

  img {
    margin: 0 !important;
  }
}

.login-form {
  margin: 20px auto;
  width: 292px;

  @media only screen and (max-width: 688px) {
    margin-left: 35px !important;
    width: 80%;
  }

  .form-group {
    padding: 10px !important;
  }

  .btn {
    width: 100% !important;
    margin-top: 25px !important;
    height: 50px !important;

    @media only screen and (max-width: 688px) {
      margin-top: 14px !important;
      padding: 14px 19px !important;
    }
  }

  .form-number-code {
    position: absolute;
    width: 48px !important;
    background: transparent;
    border: none;
    padding-left: 11px;
    padding-right: 0;
    color: #000;
  }

  .form-rest-phone-number {
    width: 100% !important;
    padding-left: 48px;
  }

  .form-group__input {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.login-left-side {
  @media (min-width: 700px) {
    margin-left: 73px;
  }

  @media (min-width: 1400px) {
    margin-left: 76px;
  }
}

.country-image {
  display: flex;
  align-items: center;

  img {
    margin: 0 !important;
    margin-right: 10px !important;
    width: 25px;
    height: 25px;
  }
}

.country-image-pop-up {
    height: 19px !important;
}

.tabs-list__login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  width: 58%;
  margin: 5% auto;
  border-bottom: none;
  margin-bottom: 40px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;

  @media only screen and (max-width: 688px) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
  }

  @media only screen and (max-width: 929px) {
    width: 74%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 1000px) {
    overflow-x: auto;
  }

  &--center {
    justify-content: center;

    @media (max-width: 375px) {
      display: grid;
    }
  }

  li {
    font-size: 0;

    padding-bottom: 10px;
    margin: 0 7px;

    @media (max-width: 1000px) {
      margin-bottom: 10px;
    }

    @media (max-width: 688px) {
      margin-bottom: 0px;
    }

    button {
      padding: 16px;
      color: $gray;
      font-size: 14px;
      border: none;
      transition: color 0.2s ease;
      position: relative;
      white-space: nowrap;
      background: $white;
      cursor: pointer;

      @media only screen and (max-width: 1000px) {
        padding: 12px;
      }

      span {
        width: auto;
        height: 16px;
        border-radius: 30px;
        background: $pacificBlue;
        text-align: center;
        line-height: 16px;
        color: $white;
        font-size: 0.717em;
        font-weight: 500;
        vertical-align: middle;
        margin-left: 10px;
        padding: 0 10px;
      }

      &::before {
        display: block;
        content: attr(title);
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &.active,
      &:hover {
        color: $color-primary;
      }
      &:disabled {
        color: $gray !important;
        cursor: default;
      }
    }
  }

  &--with-numbers {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      p {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.temporary_login {
  width: 90%;
}

.faq-links {
  @media only screen and (max-width: 688px) {
    background: $lightGray;
    margin: 0px !important;
    padding: 73px 18px;
    position: relative;
    top: 22px !important;
  }
}

.login-form-container {
  .sidebar__language {
    position: absolute;
    right: 60px;
    top: 30px;
    width: 175px;
    border-top: none !important;
    z-index: 10;

    &.js-show {
      .language--list {
        display: block;
      }
    }

    .language {
      height: 64px;
      padding-left: 49px;
      color: $gray;
      position: relative;
      transition: background 0.2s ease, color 0.2s ease;

      @media (max-width: 688px) {
        color: $white;
      }

      @media (max-width: 688px) {
        padding-left: 50px;
      }

      img {
        width: 16px;
        height: 12px;
        position: absolute;
        left: 25px;

        @include translateTop;
      }

      &--current {
        height: 64px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        padding-left: 0;

        &:hover {
          background: $pacificLight;
          color: $pacificBlue !important;
        }

        &::before {
          content: "A";
          width: 15px;
          height: 15px;
          font-family: "Glyphter", serif;
          position: absolute;
          right: 20px;
          top: 50%;
          line-height: 15px;
          transform: rotate(90deg) translateY(-50%);
          transform-origin: top;
          transition: opacity 0.1s ease 200ms, visibility 0.1s ease 200ms;
          z-index: 5;
        }

        .shrink & {
          &::before {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0s ease, visibility 0s ease;
          }
        }
      }

      &--list {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 132px;
        background: $white;

        @include wideShadow;
        padding-top: 10px;
        z-index: 10;
        display: none;

        .shrink & {
          width: 100%;
          left: 0;
        }

        .language {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          margin: 6px 0;
        }
      }
    }

    @media (max-width: 767px) {
      right: 10px;
    }

    .sidebar__menu-item::before {
      left: 15px;

      @media (max-width: 688px) {
        color: $white;
      }
    }
  }

  .sidebar__menu-item {
    &:hover {
      &::before {
        @media (max-width: 688px) {
          color: $pacificBlue !important;
        }
      }
    }
  }

  .margin-left-none {
    margin-left: 0 !important;
  }

  .language--current {
    height: 66px;

    @media (max-width: 688px) {
      color: $white;
    }
  }

  .login--language {
    @media (max-width: 688px) {
      color: $gray !important;
      font-weight: 500;
    }
  }
}

.tabs-content {
  width: 100%;

  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

#tabs-content {
  margin-top: 24px;
}

.tabs-mobile-overlay {
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.tabs--document {
  position: relative;
}

.display--none {
  display: none !important;
}
.btn--usb {
  &.loading {
    &::after {
      content: "";
      position: absolute;
      top: 22%;
      left: calc(50% - 15px);
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 5px solid transparentize($pacificBlue, 0.6);
      border-top: 5px solid $pacificBlue;
      animation: spin 2s linear infinite;

      @keyframes spin {
        100% {
          transform: rotateZ(360deg);
        }
      }
    }
  }
}

.login-error {
  margin: 20px 20px 0 20px;
  display: flex;
  align-items: stretch;
  border: 1px solid $red;
  border-radius: 6px;

  &--icon {
    background-color: $red;
    border-radius: 4px 0 0 4px;
    padding: 13px;

    img {
      margin: 0 !important;
    }
  }

  div {
    padding: 10px;
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }

  .reback-button {
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $white;
      border: 2px solid $mediumGray;
      padding: 7px 33px 7px 27px;
      border-radius: 5px;
      margin: 5px;

      span {
        padding-left: 7px;
        @media all and (max-width: 768px) {
          display: none !important;
        }
      }

      @media all and (max-width: 768px) {
        padding: 10px;
      }

      @media all and (max-width: 750px) {
        overflow: unset;
      }
    }

    a {
      color: $pacificBlue;
      text-decoration: underline;
    }
  }
}
.hight_full {
  height: 100% !important;
}
.identity_text {
  width: 55%;
  margin: 0 auto;

  p {
    color: $grayWhite;
    font-size: 14px;
    text-align: center;
  }

  @media only screen and (max-width: 688px) {
    background: $lightGray;
    position: relative;
    top: 115px !important;
  }
}

.smart-card-login{
  p {
    font-size: 12px;
    font-weight: 400;
  }
}

.christmas-login {
  background-color: $greenDark;
  color: $white;
  width: 43%;
  position: relative;
  overflow: hidden;

  @media (max-width: 689px) {
    overflow: initial;
    height: auto;
    width: 100%;
  }

  @keyframes glow {
    0% {filter: drop-shadow(0 0 0.75rem $black)}
    50% {filter: drop-shadow(0 0 1.75rem $pacificBlue)}
    100% {filter: drop-shadow(0 0 0.75rem $black)}
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    padding: 0 0 0 24px;
    max-width: 585px;
    height: 100%;
    position: relative;

    @media (max-width: 689px){
      padding: 0 24px;
    }
  }

  &__logo {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }

  &__information {
    display: flex;
    align-content: center;
    width: 100%;

    @media (max-width: 1319px) and (min-width: 689px) {
      flex-direction: column;
      align-items: center;

      img {
        width: 220px;
      }
    }

    @media (max-width: 689px) {
      align-items: flex-start;
      height: 100%;
      justify-content: center;
    }

    img {
      @media (max-width: 535px) {
        width: 140px;
      }
    }

    &--text {
      h1 {
        text-transform: uppercase;

        p {
          line-height: 32px;
          font-weight: 900;

          @media (max-width: 535px) {
            line-height: 18px;
          }

          &:first-child {
            font-size: 38px;

            @media (max-width: 535px) {
              font-size: 26px;
            }
          }

          &:last-child {
            font-size: 26px;

            @media (max-width: 535px) {
              font-size: 16px;
            }
          }
        }
      }

      h2 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 500;
        margin: 10px 0;

        @media (max-width: 535px) {
          font-size: 10px;
        }
      }

      .discount-text {
        background: $darkRed;
        font-size: 20px;
        border-radius: 50px;
        padding: 9px 14px;
        margin-bottom: 10px;
        max-width: 297px;

        @media (max-width: 535px) {
          font-size: 12px;
        }
      }

      p {
        font-size: 12px;

        @media (max-width: 535px) {
          font-size: 10px;
        }
      }
    }
  }

  &__info-button {
    margin: 20px 0 40px 0;
    width: 100%;

    button {
      display: flex;
      min-width: 174px;
      min-height: 50px;
      background: transparent;
      border: solid 1px $white;
      border-radius: 100px;

      a {
        display: flex;
        align-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50px;
        padding: 0 17px;
        color: $white;
      }
    }
  }
}

.christmas-mobile-open {
  @media (max-width: 689px) {
    height: 375px;
    margin-bottom: 85px;
  }

  @media (max-width: 535px) {
    margin-bottom: 40px;
  }
}

.christmas-mobile-closed {
   @media (max-width: 689px) {
     height: 223px;
     margin-bottom: 50px;
   }

  @media (max-width: 535px) {
    margin-bottom: 20px;
  }
}

.hide-only-mobile {
  @media (max-width: 688px) {
    display: none;
  }
}

.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #f7f9fc57;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}

@-webkit-keyframes snowflakes-shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0)
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px)
  }
}

@keyframes snowflakes-fall {
  0% {
    top: -10%
  }
  100% {
    top: 100%
  }
}

@keyframes snowflakes-shake {
  0%, 100% {
    transform: translateX(0)
  }
  50% {
    transform: translateX(80px)
  }
}

.snowflake {
  position: absolute;
  top: -10%;
  z-index: 10;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running
}

.snowflake:nth-of-type(0) {
  top: -15px;
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
  top: -15px;
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
  top: -15px;
  left: 20%;
  -webkit-animation-delay: 6s, .5s;
  animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
  top: -15px;
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
  top: -15px;
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
  top: -15px;
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
  top: -15px;
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
  top: -15px;
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
  top: -15px;
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
  top: -15px;
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
  top: -15px;
  left: 25%;
  -webkit-animation-delay: 2s, 0s;
  animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
  top: -15px;
  left: 65%;
  -webkit-animation-delay: 4s, 2.5s;
  animation-delay: 4s, 2.5s
}

.login-form-container--temporary {
  .rightbar-container {
    width: 100%;
    height: 100%;
  }

  .rightbar-container__wrapper {
    padding: 0 12px;
  }

  .faq-links {
    @media only screen and (max-width: 688px) {
      background: unset;
      padding: unset;
    }
  }

  @media only screen and (max-width: 688px) {
    .rightbar-container {
      &__wrapper {
        margin-top: 0px;
        border-radius: 10px;
        padding-top: 0px !important;
        max-width: 100%;
      }

      .login-form {
        margin: 20px auto !important;
        width: 292px;
      }

      .login-mode {
        margin-top: 0px;
      }

      h5 {
        padding-top: 0px;
      }
    }

    .sidebar__language .language {
      color: $gray !important;
      &:hover {
        color: $pacificBlue !important;
        &::before {
          color: $pacificBlue;
        }
      }
      &::before {
        color: $gray;
      }
    }
  }
}
