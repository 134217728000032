.loader {
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & &__text {
    color: $color-primary;
  }
}

#loader {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  position: absolute;
  right: -6px;
  top: -6px;
  border: 6px solid $pacificBlue;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: loading 1500ms linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}

.loader_background-border {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  border: 6px solid $mediumGray;
  position: relative;
}

#loader circle {
  stroke-dashoffset: -327px;

  // transition: stroke-dashoffset 1s linear
  stroke-width: 6px;
}

#loader #bar {
  stroke: #0096C7;
}

.progressbar {
  display: block;
  height: 110px;
  width: 110px;
  margin: 2em auto;

  // box-shadow: 0 0 1em black
  border-radius: 100%;
  position: relative;
  transform: rotate(-85deg);
}

.dropzone-preview {
  .upload-item {
    margin-top: -30px;
  }
}
