.table {
  &--download-invoice {
    @media (min-width: 1000px) {
      position: relative;
      left: -80px;
    }
  }

  &__head {
    @include flexCenter;

    padding: 0 9px 0 24px;

    @media all and (max-width: 1000px) {
      display: none;
    }
  }

  &__head li,
  &__th {
    text-transform: uppercase;
    font-size: 0.71em;
    color: $primaryGray;
    padding: 4px 0;
    flex: 1 1 0;
  }

  &__th--sort label {
    padding-right: 18px;
    position: relative;
    cursor: pointer;
  }

  &__th--sort label:before,
  &__th--sort label:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: 0;
    top: 50%;
    position: absolute;
    width: 0;
  }

  &__th--sort label:before {
    border-bottom-color: $primaryGray;
    margin-top: -10px;
  }

  &__th--sort label:after {
    border-top-color: $primaryGray;
    margin-top: 0;
  }

  &__th--sort-asc label:before {
    border-bottom-color: $color-primary;
  }

  &__th--sort-desc label:after {
    border-top-color: $color-primary;
  }

  &__body {
    padding-top: 10px;
  }

  &__body li,
  &__row {
    background-color: $white;
    padding: 16px 9px 16px 24px;
    margin-bottom: 16px;
    min-height: 64px;
    font-size: 1em;
    line-height: 1.5em;

    @include flexCenter;

    @media all and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 16px 0;
      border-bottom: 1px solid $mediumGray;
      position: relative;
    }
  }

  &__col,
  &__body__col {
    padding-right: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1 1 0;

    @media all and (max-width: 1000px) {
      flex: 1 1 auto;
    }

    &--overflow {
      overflow: visible !important;
    }
  }

  &--cart {
    width: calc(34% + 250px);

    @media all and (max-width: 1000px) {
      width: 100%;
    }

    .table__head {
      padding: 0;
    }

    .table__body li {
      border-bottom: 0;

      @media all and (max-width: 1000px) {
        flex-wrap: wrap;
      }
    }

    .table__head li,
    .table__body__col {
      &:first-child {
        max-width: 10%;

        @media all and (max-width: 1000px) {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          height: 1.5em;
          min-width: 30px;
          padding: 0;
          margin: auto 0;
        }
      }

      &:nth-child(2) {
        max-width: 57%;

        @media all and (max-width: 1000px) {
          min-width: 100%;
          text-align: left;
          padding: 0 45px 0 35px;
        }
      }

      &:nth-child(3) {
        max-width: 23%;

        @media all and (max-width: 1000px) {
          min-width: 100%;
          text-align: left;
          padding: 0 45px 0 35px;
        }
      }

      &:nth-child(4) {
        display: flex;
        justify-content: end;
        padding-right: 0;
        max-width: 10%;

        @media all and (max-width: 1000px) {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          height: 30px;
          min-width: 30px;
          margin: auto 0;
        }
      }
    }
  }
}

.table--settings {
  .table__head li {
    flex: none;

    &:first-child {
      width: 15%;

      @media all and (max-width: 1350px) {
        width: 25%;
      }
    }

    &:nth-child(2) {
      width: 20%;

      @media all and (max-width: 1350px) {
        width: 30%;
      }
    }

    &:nth-child(3) {
      width: 12%;

      @media all and (max-width: 1350px) {
        width: 17%;
      }
    }

    &:nth-child(4) {
      width: 10%;

      @media all and (max-width: 1350px) {
        width: 15%;
      }
    }

    &:nth-child(5) {
      width: 17%;

      @media all and (max-width: 1350px) {
        width: 27%;
      }
    }
  }

  .table__body {
    li {
      @media all and (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
        padding: 16px 0;
        border-bottom: 1px solid $mediumGray;
        position: relative;
      }

      .table__body__col {
        padding-right: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: none;

        @media all and (max-width: 1000px) {
          overflow: unset;

          * {
            line-height: 2em;
          }
        }

        &:first-child {
          width: 15%;

          @media all and (max-width: 1350px) {
            width: 25%;
          }
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }

        &:nth-child(2) {
          width: 20%;

          @media all and (max-width: 1350px) {
            width: 30%;
          }

          @media all and (max-width: 1000px) {
            width: 100% !important;
          }
        }

        &:nth-child(3) {
          width: 12%;

          @media all and (max-width: 1350px) {
            width: 17%;
          }

          @media all and (max-width: 1000px) {
            width: 100% !important;
          }
        }

        &:nth-child(4) {
          width: 10%;

          @media all and (max-width: 1350px) {
            width: 15%;
          }

          @media all and (max-width: 1000px) {
            width: 100% !important;
          }
        }

        &:nth-child(5) {
          width: 17%;

          @media all and (max-width: 1350px) {
            width: 27%;
          }

          @media all and (max-width: 1000px) {
            width: 100% !important;
          }
        }

        &:last-child {
          margin-left: auto;
          text-align: right;
          width: auto;

          @media all and (max-width: 1000px) {
            position: absolute;
            right: -5px;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        a {
          &.remove-category {
            &::before {
              margin-left: 5px;
              cursor: pointer;
              content: url("../../img/icons/close.svg");
            }
          }
        }
      }
    }
  }

  .table__body__col {
    .col__right {
      width: 185px;

      @media all and (max-width: 1000px) {
        text-align: left;
      }
    }

    .full__width {
      width: 100%;
    }
  }
}

.table--contacts {
  .table__head {
    border-bottom: 1px solid $mediumGray;
    padding: 0;

    li {
      flex: none;

      &:first-child {
        width: 15%;

        @media all and (max-width: 1350px) {
          width: 25%;
        }
      }

      &:nth-child(2) {
        width: 20%;

        @media all and (max-width: 1350px) {
          width: 30%;
        }
      }

      &:nth-child(3) {
        width: 12%;

        @media all and (max-width: 1350px) {
          width: 17%;
        }
      }

      &:nth-child(4) {
        width: 10%;

        @media all and (max-width: 1350px) {
          display: none;
        }
      }

      &:nth-child(5) {
        width: 17%;

        @media all and (max-width: 1350px) {
          display: none;
        }
      }

      &:nth-child(6) {
        @media all and (max-width: 1350px) {
          display: none;
        }
      }
    }
  }

  .table__body li {
    margin: 0;
    padding: 0;
    min-height: 40px;
    font-size: 0.85em;

    @media all and (max-width: 1000px) {
      padding: 16px 0;
    }

    .table__body__col {
      padding-right: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: none;

      @media all and (max-width: 1000px) {
        overflow: unset;

        * {
          line-height: 2em;
        }
      }

      &:first-child {
        width: 15%;

        @media all and (max-width: 1350px) {
          width: 25%;
        }
      }

      @media all and (max-width: 1000px) {
        width: 100% !important;
      }

      &:nth-child(2) {
        width: 20%;

        @media all and (max-width: 1350px) {
          width: 30%;
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }
      }

      &:nth-child(3) {
        width: 12%;

        @media all and (max-width: 1350px) {
          width: 17%;
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }
      }

      &:nth-child(4) {
        width: 10%;

        @media all and (max-width: 1350px) {
          display: none;
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }
      }

      &:nth-child(5) {
        width: 17%;

        @media all and (max-width: 1350px) {
          display: none;
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }
      }

      &:nth-child(6) {
        @media all and (max-width: 1350px) {
          display: none;
        }

        @media all and (max-width: 1000px) {
          width: 100% !important;
        }
      }

      &:last-child {
        margin-left: auto;
        text-align: right;
        width: auto;

        @media all and (max-width: 1000px) {
          position: absolute;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.table--usb {
  .table__head {
    border-bottom: 1px solid $mediumGray;
    padding: 0;

    li {
      flex: none;

      label {
        color: $black;
        text-transform: none !important;
        font-size: 14px !important;
        font-weight: bold !important;

        @media all and (max-width: 400px) {
          font-size: 13px !important;
        }
      }

      &:first-child {
        width: 51.9%;

        @media all and (max-width: 1350px) {
          width: 47%;
        }

        @media all and (max-width: 999px) {
          width: 50%;
        }

        @media all and (max-width: 280px) {
          width: 38% !important;
        }
      }

      &:nth-child(2) {
        width: 50%;

        @media all and (max-width: 1350px) {
          width: 30%;
        }

        @media all and (max-width: 999px) {
          width: 50%;
        }
      }
    }

    @media all and (max-width: 768px) {
      display: flex;
    }
  }

  .table__body li {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid $mediumGray;
    font-size: 14px !important;

    @media all and (max-width: 1000px) {
      flex-direction: row;
      align-items: center;
      padding-left: 5px;
      flex: 1 1 auto !important;
    }

    .table__body__col {
      padding-right: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      flex: none;

      .error {
        color: $tomato;
        position: absolute;
        font-weight: 500;
        max-width: 135px;
        height: 32px;
        margin-top: -16px;
        padding: 8px 0px 8px 0px;
        border-color: $lightRedBorder;
        border-radius: 14.5% / 50%;
        text-align: center;
        background: $lightRed;

        @media all and (max-width: 300px) {
          width: 84px !important;
        }

        @media all and (max-width: 400px) {
          width: 91px;
          font-size: 10px;
          height: 28px;
          margin-left: 10px;
          margin-top: -20px;
        }
      }

      .unknown {
        color: $gray;
        position: absolute;
        font-weight: 500;
        width: 112px;
        height: 32px;
        margin-top: -16px;
        padding: 8px 0px 8px 0px;
        border-color: $lightGray;
        border-radius: 14.5% / 50%;
        text-align: center;
        background: $lightGray;

        @media all and (max-width: 300px) {
          width: 84px !important;
        }

        @media all and (max-width: 400px) {
          width: 91px;
          font-size: 10px;
          height: 28px;
          margin-left: 10px;
          margin-top: -20px;
        }
      }

      .success {
        color: $green;
        position: absolute;
        font-weight: 500;
        width: 112px;
        height: 32px;
        border-color: $greenLightBorder;
        margin-top: -16px;
        padding: 8px 34px 8px 26px;
        border-radius: 14.5% / 50%;
        text-align: center;
        background: $greenLight;

        @media all and (max-width: 300px) {
          width: 84px !important;
        }

        @media all and (max-width: 400px) {
          width: 91px;
          font-size: 10px;
          height: 28px;
          margin-left: 10px;
          margin-top: -20px;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border: 2px solid $mediumGray;
        padding: 7px 33px 7px 27px;
        border-radius: 5px;
        width: 85%;

        span {
          padding-left: 7px;

          @media all and (max-width: 768px) {
            display: none !important;
          }
        }

        @media all and (max-width: 999px) {
          width: 50%;
        }

        @media all and (max-width: 768px) {
          padding: 10px;
          width: 42%;
        }

        @media all and (max-width: 700px) {
          overflow: unset;
        }
      }

      &:first-child {
        width: 50%;

        @media all and (min-width: 1350px) {
          width: 50%;
        }

        @media all and (max-width: 1350px) {
          width: 44%;
        }

        @media all and (max-width: 999px) {
          width: 40%;
        }

        @media all and (max-width: 400px) {
          font-size: 11px;
        }

        @media all and (max-width: 350px) {
          width: 33% !important;
        }

        @media all and (max-width: 280px) {
          width: 30% !important;
        }
      }

      @media all and (max-width: 999px) {
        width: 40%;
      }

      @media all and (max-width: 280px) {
        width: 33% !important;
      }

      &:nth-child(2) {
        width: 38%;

        @media all and (max-width: 1350px) {
          width: 44.3%;
        }

        @media all and (min-width: 1350px) {
          width: 39.5%;
        }

        @media all and (min-width: 1500px) {
          width: 40.5%;
        }

        @media screen and (max-width: 999px) {
          width: 34%;
        }

        @media screen and (max-width: 720px) {
          width: 40% !important;
        }

        @media screen and (max-width: 350px) {
          width: 47% !important;
          font-size: 11px;
        }

        @media all and (max-width: 280px) {
          width: 48% !important;
        }
      }

      &:last-child {
        border: none;
      }
    }
  }
}