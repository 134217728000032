.sharing-settings {
  @media all and (max-width: 1000px) {
    .info-details {
      li {
        flex-direction: column;
      }

      .info-details__label, .info-details__info {
        width: 100%;
      }

      .info-details__info {
        flex-direction: row;
        margin-top: 20px;
      }
    }
  }

  + .info-details {
    margin-top: 0;
  }
}
