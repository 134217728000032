.statistics {
  &__datepicker-row {
    display: flex;
    margin-bottom: 24px;

    @media (max-width: 550px) {
      flex-direction: column;
    }

    .form-group--select {
      margin-right: 8px;

      @media (max-width: 550px) {
        margin-bottom: 12px;
      }
    }
  }

  .info-details {
    @media (min-width: 1000px) {
      padding-left: 24px;
    }

    &__label {
      min-width: 200px;
      width: 30%;
    }
  }

  &__pin-one {
    .wrapped-section__headline {
      padding-left: 24px;
    }
  }
}