.document-list {
  width: 100%;

  li {
    width: 100%;
    margin-bottom: 8px;
  }

  &__select-all {
    padding-left: 20px;
    height: 64px;

    @include flexCenter;

    display: inline-block;
    padding-top: 15px;

    @media all and (max-width: 768px) {
      height: 110px;
      padding-left: 20px;
      padding-top: 10px;
    }

    .controls-buttons {
      display: none;

      .controls__btn {
        width: 48px;
        height: 39px;

        .icon {
          height: 23px;
        }
      }

      &.show {
        display: flex;
        float: right;
        pointer-events: none;

        &.active {
          .icon::before, p {
            color: $pacificBlue;
            pointer-events: auto;
          }
        }

        @media all and (max-width: 768px) {
          position: absolute;
          right: 10px;
        }
      }
    }
  }

  &__select-filters {
    float: right;
    width: 25%;

    @media all and (max-width: 768px) {
      width: 100%;
      margin-top: 25px;
      margin-right: 10px;
    }
  }

  &__element {
    width: 100%;
    min-height: 72px;
    background: $white;
    padding: 16px 20px;

    @include flexCenter;

    justify-content: space-between;

    @include softShadow;

    @media all and (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 6px;
    }
  }

  &__part {
    @include flexCenter;

    overflow: hidden;

    @media all and (max-width: 1000px) {
      width: 100%;

      &:nth-child(2) {
        justify-content: space-between;
        padding-left: 40px;

        @media all and (max-width: 1000px) {
          padding-left: 30px;
        }
      }
    }
  }

  &__part--overflow {
    overflow: visible;
  }

  &__preview {
    @include flexCenter;

    overflow: hidden;

    &:hover {
      color: $pacificBlue;
    }

    &--not-viewed {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $green;
      margin-left: 6px;
    }
  }

  &__checkbox {
    margin-right: 21px;

    @media all and (max-width: 1000px) {
      margin-right: 10px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;

    &--pdf {
      background: url("../../img/icons/pdf.svg") center center no-repeat;
      background-size: contain;
    }

    &--bdoc {
      background: url("../../img/icons/bdoc.svg") center center no-repeat;
      background-size: contain;
    }

    &--adoc {
      background: url("../../img/icons/adoc.svg") center center no-repeat;
      background-size: contain;
    }

    &--asice {
      background: url("../../img/icons/asice.svg") center center no-repeat;
      background-size: contain;
    }

    &--xml {
      background: url("../../img/icons/xml.svg") center center no-repeat;
      background-size: contain;
    }
  }

  &__info {
    padding-left: 20px;
    max-width: calc(100% - 32px);
    overflow: hidden;
    overflow-wrap: break-word;

    &--not-viewed {
      padding-left: 8px;
    }

    span {
      font-size: 0.85em;
      color: $gray;
      margin-top: 6px;
      display: block;

      &.document-category {
        display: inline;
        margin-right: 15px;
      }

      &.not-viewed-document {
        margin-top: 0;
      }
    }

    .status-tooltip {
      margin: 0;
      position: absolute;
      top: 6px;
      left: 50%;

      &::after {
        font-size: 1em;
      }
    }
  }

  &__title {
    font-size: 1em;
    font-weight: 400;
    transition: color 0.2s ease;
  }

  &__shared {
    color: $gray;
    font-size: 0.85em;
    position: relative;
    white-space: nowrap;
    @media (max-width: 387px) {
      margin-right: 5px;
    }

    .icon {
      display: inline-block;
      width: 25px;
      height: 12px;
      font-style: normal;
      position: relative;
      margin-right: 2px;
    }

    .icon::before {
      font-family: "Glyphter";
      content: "I";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0px;

      @include translateTop;

      line-height: 13px;
      text-align: center;
    }
  }

  &__status {
    padding-left: 40px;
    width: 190px;
    @media (max-width: 400px) {
      display: contents;
    }

    @media all and (max-width: 1000px) {
      padding-left: 20px;
    }

    @media (max-width: 400px) {
      display: flex;
      padding-left: 0;
    }
  }

  &__controls {
    padding-left: 30px;

    @include flexCenter;

    @media all and (max-width: 1000px) {
      padding-left: 0px;
    }

    .controls__btn {
      width: 30px;
      height: 40px;
    }
  }
}

.invisible-checkbox-spacer {
  display: inline;
  float: left;
  padding-top: 10px;
  height: 26px;
  width: 160px;
}
