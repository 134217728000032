#header {
  padding-top: 32px;
  padding-bottom: 32px;

  &--pricing-table {
    padding-top: 32px;
    padding-bottom: 32px;

    .header-switch {
      margin-right: 25px;
      margin-top: 5px;
      display: inline;
      float: right;

      @media all and (max-width: 1000px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
      @media all and (max-width: 556px) {
        position: relative;
        top: 31px;
      }

      .monthly-payment {
        display: inline;
        margin-right: 5px;

        @media all and (max-width: 1000px) {
          margin-left: 15px;
        }
      }

      .annual-payment {
        display: inline;
        margin-left: 5px;
        margin-bottom: 15px;

        @media all and (max-width: 1000px) {
          margin-left: 15px;
        }
      }
    }

    @media all and (max-width: 1000px) {
      padding-top: 30px;
      padding-bottom: 50px;
    }
    @media all and (max-width: 556px) {
      padding-bottom: 80px;
    }
  }

  @media all and (max-width: 1000px) {
    padding-top: 30px;
    padding-bottom: 27px;
  }

  .grid {
    justify-content: space-between;
  }

  @media all and (max-width: 1000px) {
    &.header--mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  @media all and (max-width: 370px) {
    &.header--mobile {
      flex-direction: column;
      align-items: flex-start;

      .show-tablet {
        display: flex;
        margin: auto;
        justify-content: center;
      }
    }
  }
}

.header-text__label {
  position: relative;
  top: 25px;
  @media (max-width: 1000px) {
    top: 21px;
  }
}

.header--document {
  .header__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.header__search {
  margin-left: 48px;

  @media all and (max-width: 1075px) {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }

  @media (min-width: 1076px) and (max-width: 1119px) {
    margin-left: 24px;
  }
}

.header__page-navigation {
  font-size: 1.14em;
  font-weight: 500;
  color: $darkGray;
  position: relative;
  padding: 10px 10px 10px 32px;

  @media all and (max-width: 1000px) {
    font-size: 1em;
  }

  &::before {
    content: "b";
    font-family: "Glyphter";
    width: 15px;
    height: 15px;
    line-height: 15px;
    position: absolute;
    left: 0;

    @include translateTop;

    color: $gray;
    transition: transform 0.3s ease;
  }

  &:hover {
    &::before {
      transform: translate(-3px, -50%);
    }
  }
}

.validation_header{
  display: flex;
  flex-direction: column;
}

#header-pricing {
  padding-top: 62px;
  padding-bottom: 62px;
  background: $lightGray;

  h2 {
    margin-bottom: 19px;
    font-size: 36px;
  }

  p {
    font-size: 23px;
  }
}

@media print {
  #cookiescript_injected_wrapper {
    display: none !important;
  }

  #cookiescript_badge {
    display: none !important;
  }
}
