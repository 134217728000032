.tabs-list {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  border-bottom: 1px solid $mediumGray;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

  @media all and (max-width: 1000px) {
    overflow-x: auto;
  }

  &--center {
    justify-content: center;
    @media (max-width: 375px) {
      display: grid;
    }
  }

  li {
    font-size: 0;
    margin: 0 7px;

    @media (max-width: 1000px) {
      margin-bottom: 10px;
    }

    &:last-child {
      position: relative;
      z-index: 2;
    }

    a {
      padding: 16px 16px 16px 16px;
      color: $gray;
      display: block;
      font-size: 14px;
      border-bottom: 2px solid transparent;
      transition: color 0.2s ease;
      position: relative;
      white-space: nowrap;

      @media all and (max-width: 1000px) {
        padding: 12px;
      }

      span {
        width: auto;
        height: 16px;
        border-radius: 30px;
        background: $pacificBlue;
        text-align: center;
        line-height: 16px;
        color: $white;
        font-size: 0.717em;
        font-weight: 500;
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        padding: 0 6px;
      }

      &::before {
        display: block;
        content: attr(title);
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &.active, &:hover {
        color: $color-primary;
      }

      &.active {
        border-color: $pacificBlue;
      }
    }
  }

  &--with-numbers {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      p {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.tabs-content {
  width: 100%;

  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

#tabs-content {
  margin-top: 24px;
}

.tabs-mobile-overlay {
  width: 100px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.tabs--document {
  position: relative;
}
