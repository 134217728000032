main {
  float: right;
  width: calc(100% - 340px);
  transition: width 0.3s ease-in-out;
  padding-bottom: 100px;

  @media all and (max-width: 1000px) {
    width: 100%;

    &.no-pad-bottom {
      padding-bottom: 0;
    }
  }

  &.temporary-not-found {
    float: unset;
    width: unset;
  }

  &.widget-shrink {
    width: calc(100% - 40px);

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  &.shrink {
    width: calc(100% - 105px);

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  &.checkout {
    height: 100%
  }

  &.documents-list {
    float: unset;
    width: 100%;
    height: 100%;
  }
}

.section {
  background: $white;

  @include smallShadow;

  padding: 42px 32px;

  &--plan {
    height: 100%;
    padding: 32px 32px;
    width: 100%;

    .info-details {
      padding-left: 0;
      margin-bottom: 20px;

      &__info {
        padding-left: 80px;

        @media (max-width: 420px) {
          padding-left: 30px;
        }
      }
    }
  }
}

.tabs--documents {
  margin-top: 8px;
  position: relative;
}

#document-list, #document-list-filter {
  .grid {
    flex-direction: column;
  }
}

/* Safari 10.1+ */

@media not all and (min-resolution:.001dpcm) {
  @supports (-webkit-appearance:none) {
    .wrapped-section {
      @media print {
        height: 100%;
        width: 250mm;
        position: relative;
        left: -300px;

        label, h3 {
          color: $black !important;
        }
      }
    }
  }
}

.wrapped-section:last-child {
  margin-bottom: 50px;

  @media print {
    margin-bottom: 0;
  }

  @media all and (max-width: 1000px) {
    margin-bottom: 0;
  }
}

@media print {
  #fb-root {
    display: none !important;
  }

  ._hj_feedback_container {
    display: none !important;
  }
}
