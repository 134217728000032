.bottom-bar {
  position: fixed;
  bottom: 0;
  width: calc(100% - 320px);
  right: 0;
  height: 64px;
  background: $white;
  z-index: 11;
  border-top: 1px solid $mediumGray;
  border-left: 1px solid $mediumGray;
  padding: 0 24px 0 44px;

  @include flexCenter;

  justify-content: flex-start;
  transition: width 0.3s ease-in-out;

  @media (max-width: $mq-mobile) {
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    height: 90px;
  }

  @media (max-width: $mq-mobile-stop) {
    height: 102px;
  }

  @media all and (max-width: 1000px) {
    width: 100%;
    padding: 0 16px;
    justify-content: center;
  }

  @media print {
    display: none;
  }

  &.widget-shrink {
    width: 100%;
  }

  &.shrink {
    width: calc(100% - 72px);

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  &.checkout {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .btn:not(:first-child) {
    margin-left: 12px;
  }
}

.bottom-bar.hide-tablet {
  .btn {
    margin-right: 16px;

    &--red {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

.bottom-bar.show-tablet {
  .btn {
    margin-left: 5px;

    @media (max-width: 1000px) {
      padding: 10px 20px;
      min-height: 37px;
    }

    @media (max-width: 450px) {
      padding: 3px 20px;
      max-height: 38px;
    }

    &--red {
      margin-right: auto;
      margin-left: 0;
      padding: 10px 27px;

      @media (max-width: 1000px) {
        margin-right: initial;
      }
    }
  }
}

.bottom-bar.bottom-bar--iframe {
  flex-wrap: wrap;

  .btn {
    width: 40%;
    margin-top: 15px;
  }
}
