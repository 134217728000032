#usb-page-container {
  overflow-x: hidden;
  background-color: $lightGray !important;
  font-family: Gordita !important;

  &.ovh {
    height: 100vh;
  }

  &.usb-page-container--full-height {
    width: 100%;
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    min-height: 700px;
    z-index: 1;

    @media all and (min-width: 600px) {
      min-height: auto;
      display: block;
    }

    img {
      display: inline !important;
      margin: 0;
      z-index: 1;
    }
  }

  &.usb-page-container--full-width {
    position: relative;
    height: 100vh;
    display: initial !important;
    min-height: 700px;
    background-color: $white !important;

    @media all and (max-width: 700px) {
      overflow-y: auto;
      min-height: auto;
      display: block;
    }

    main {
      float: initial;
      width: initial;
    }
  }
}

.usb__container {
  background-color: $white;
  padding: 80px 0;
  max-width: 100%;
  position: relative;
  top: -20%;
  border-radius: 5px;

  @media all and (min-width: 600px) {
    padding-top: 60px;
    margin-top: 7%;
    margin-bottom: 3%;
    top: 0px;
  }

  @media all and (min-width: 1281px) {
    min-width: calc(100vw - 7vw);
    margin-top: 7%;
    margin-bottom: 3%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 10px 0;
    top: 0;
  }

  h5 {
    font-weight: 400;
    line-height: 1.4;
    font-size: 16px;

    @media screen and (max-width: 768px) {
      margin: 5px 40px;
      padding: 10px 25px;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.usb__status {
  margin: 2% 7.5%;

  .usb__status_success {
    display: flex;
    align-items: center;
    border: 1px solid $green;
    border-radius: 8px;

    .usb__status_icon {
      background-color: $green;
      border-radius: 6px 0 0 6px;
      width: 66px;
      padding: 18px 24px;
    }

    div {
      padding-left: 24px;
      font-size: 14px;

      @media all and (max-width: 600px) {
        font-size: 11px;
      }
    }
  }

  .usb__status_error {
    display: flex;
    align-items: center;
    border: 1px solid $redError;
    border-radius: 8px;

    .usb__status_icon {
      background-color: $redError;
      border-radius: 6px 0 0 6px;
      width: 66px;
      padding: 18px 24px;
    }

    div {
      padding-left: 24px;
      font-size: 14px;

      @media all and (max-width: 600px) {
        font-size: 11px;
      }
    }

    .reback-button {
      margin-left: auto;

      button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        border: 2px solid $mediumGray;
        padding: 7px 33px 7px 27px;
        border-radius: 5px;
        margin: 5px;

        .spin {
          animation: spin 2s linear infinite;
          animation-direction: reverse;
        }

        span {
          padding-left: 7px;

          @media all and (max-width: 768px) {
            display: none !important;
          }
        }

        @media all and (max-width: 768px) {
          padding: 10px;
          overflow: unset;
        }
      }
    }
  }
}

.usb-error {
  @media screen and (max-width: 768px) {
    background: $white !important;
    padding: 81px 0 0 0 !important;
  }

  .login__logo {

    @media screen and (max-width: 768px) {
      margin-bottom: 20px;
      left: 38% !important;
    }

    @media screen and (max-width: 400px) {
      left: 35% !important;
    }
  }

  .grid {
    @media screen and (max-width: 768px) {
      padding: 0px !important;
    }
  }
}

.usb-lang {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.mark-sign-one {
  margin-left: 5px;

  @media screen and (max-width: 768px) {
    margin-left: -2px;
  }

  h4 {
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 20px !important;
    }
  }
}

.text-two {
  margin: 5px;
  padding: 5px;

  @media screen and (max-width: 768px) {
    text-align: center;
    margin: 20px 0px !important;
  }
}

.heading-text {
  font-size: 28px;
  font-weight: bold;
  color: $pacificBlue;
  margin-bottom: 1%;

  @media screen and (max-width: 768px) {
    margin: 5px 40px;
    padding: 10px;
  }

  @media screen and (max-width: 650px) {
    margin-top: 50px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 70px;
  }
}

.middle-bar {
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    padding: 0px;
  }
}

.middle-logo-round {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 100%;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 300px) {
    width: 135px;
  }

  img {
    width: 66px;
    height: 66px;
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2%;
    text-align: center;
    line-height: 25px;
    word-wrap: break-word;

    @media screen and (max-width: 768px) {
      margin: 0px 15px !important;
    }
  }
}

.middle-logo-left {
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    margin-top: 17px;
  }

  h6 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
  }
}

.middle-logo-right {
  margin-top: 49px;

  @media screen and (max-width: 768px) {
    margin-top: 66px;
  }

  h6 {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
  }
}

.center-round-icon {
  margin-top: -40px;
  margin-left: -30px;
  margin-right: -20px;

  img {
    display: inline !important;
    margin-left: 4px !important;
    padding-left: 4px;

    @media screen and (max-width: 300px) {
      margin-left: 0px !important;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0px;
    width: 13%;
    margin-top: -55px;
  }

  @media screen and (max-width: 700px) {
    width: 17%;
  }

  @media screen and (max-width: 540px) {
    width: 20%;
  }

  @media screen and (max-width: 420px) {
    padding: 0px;
    width: 30%;
    margin-top: -40px;
  }
}

.middle-section {
  margin: 4% 7%;

  h4 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2%;
    margin-top: 20px;

    @media screen and (max-width: 668px) {
      font-size: 15px;
      line-height: 2;
    }
  }
}

.border-none {
  border: none !important;
}

.software_accordion {
  body {
    color: $black;
    background: $lightWhite;
    padding: 0;
  }

  h1 {
    margin: 0;
    line-height: 2;
    text-align: center;
  }

  h2 {
    margin: 0 0 .5em;
    font-weight: normal;
  }

  .checkbox--input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  .row {
    display: flex;

    .col {
      flex: 1;

      &:last-child {
        margin-left: 1em;
      }
    }
  }

  .tabs {
    overflow: hidden;
  }

  .tab {
    width: 100%;
    color: $black;
    overflow: hidden;
    border-bottom: 1px solid $mediumGray;

    &-label {
      display: flex;
      justify-content: space-between;
      padding: 1em;
      background: $lightWhite;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background: darken($white, 10%);
      }

      &::after {
        content: "\276F";
        position: relative;
        width: 1em;
        height: 1em;
        text-align: center;
        border-bottom: 1px solid $mediumGray;
        transform: rotate(90deg);
        transition: all .35s;
      }
    }

    &-content {
      position: relative;
      max-height: 0;
      padding: 0 1em;
      color: $black;
      background: $white;
      transition: all .35s;
    }

    &-close {
      display: flex;
      justify-content: flex-end;
      padding: 1em;
      font-size: 0.75em;
      background: $white;
      cursor: pointer;

      &:hover {
        background: darken($white, 10%);
      }
    }
  }

  .checkbox--input:checked {
    +.tab-label {
      background: $whiteGray;
      border-top: 1px solid $mediumGray;
      border-bottom: 1px solid $mediumGray;

      &::after {
        transform: rotate(-90deg);
      }
    }

    ~.tab-content {
      max-height: 100vh;
      padding: 1em;

      @media screen and (max-width: 660px) {
        margin-right: -30px;
        margin-left: -15px;
      }
    }
  }
}