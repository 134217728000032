.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 40px;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  border: 0;
  outline: 0;
  transition: background 0.2s ease, border 0.2s ease, color 0.2s ease;
  font-family: $font-primary;
  width: 14rem;
  text-align: center;

  @media all and (max-width: 1000px) {
    font-size: 0.85em;
    width: 10rem;
  }

  @media all and (max-width: 540px) {
    padding: 9px 19px;
    font-size: 0.75em;
  }

  &.loading {
    &:disabled {
      background: $pacificBlue;
      color: transparent;

      &:hover {
        cursor: unset;
        background: $lightGray;
        color: $gray;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 20%;
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 3px solid transparentize($white, 0.4);
      border-top: 3px solid $white;
      animation: spin 2s linear infinite;

      @keyframes spin {
        100% {
          transform: rotateZ(360deg);
        }
      }
    }
  }

  &:disabled {
    color: $gray;
    background: $lightGray;
    border: 2px solid transparent;

    &:hover {
      cursor: unset;
      background: $lightGray;
      color: $gray;
      border-color: transparent;
    }
  }

  &--primary {
    background: $pacificBlue;
    color: $white;
    border: 2px solid transparent;
    font-weight: 500;
    text-align: center;

    &.loading:disabled {
      background: $pacificBlue;
    }

    &:hover {
      background: $pacificDark;
      color: $white;
    }
  }

  &--secondary {
    color: $pacificBlue;
    border: 2px solid $pacificBlue;
    background: transparent;
    font-weight: 500;
    text-align: center;

    &:hover {
      background: $white;
    }
  }

  &--red {
    background: $red;
    color: $white;
    border: 2px solid $red;
    font-weight: 500;

    &.loading:disabled {
      background: $red;
    }
  }

  &--green {
    background: $green;
    color: $white;
    border: 2px solid $green;
    font-weight: 500;

    &:hover {
      background: $greenDark;
      border-color: $greenDark;
    }
  }

  &--grey {
    background: $white;
    color: $color-primary;
    border: 2px solid $mediumGray;
  }

  &--medium-grey {
    background: $mediumGray;
    color: $gray;
  }

  &--assign {
    display: flex;
    align-items: center;

    #assigned-contacts-cont {
      margin: 0 5px;
    }
  }

  &--transparent {
    color: $pacificBlue;
    background: transparent;
  }

  &--icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    &::before {
      font-family: "Glyphter";
    }
  }

  &--add {
    color: $pacificBlue;
    padding-left: 30px;

    @media all and (max-width: 1000px) {
      padding-right: 0;
    }

    @media all and (max-width: 540px) {
      padding: 9px 19px;
      font-size: 0.75em;
    }

    &::before {
      content: "J";
      left: -2px;
    }

    &:hover {
      color: $pacificDark;
    }
  }

  &--dots {
    padding: 12px;

    &::before {
      content: "Z";
      left: 0;
      color: $gray;
    }
  }

  &--reminder {
    color: $pacificBlue;
    padding-left: 25px;

    &::before {
      content: "K";
      left: 0;
    }

    &:hover {
      color: $pacificDark;
    }

    &__disabled {
      color: $gray;
      padding-left: 25px;
      cursor: initial;

      &::before {
        content: "K";
        left: 0;
      }
    }
  }

  &--arrow-down {
    color: $pacificBlue;
    padding-right: 25px;

    &::before {
      content: "A";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      color: $color-primary;
    }

    &:hover {
      color: $pacificDark;
    }

    .js-show-less & {
      &::before {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }

  &--visible {
    color: $pacificBlue;
    padding-right: 25px;

    &::before {
      content: "A";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      color: $color-primary;
    }

    &:hover {
      color: $pacificDark;
    }
  }

  &#copy-link {
    width: 110px;
    height: 40px;
    margin-left: 1em;

    @media all and (max-width: 1000px) {
      height: 33px;
    }

    span {
      position: absolute;
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      transition: top 0.2s ease, opacity 0.1s ease;

      &.primary-state {
        top: 50%;
      }

      &.secondary-state {
        top: 70%;
        opacity: 0;
      }
    }

    &.copied {
      span {
        &.primary-state {
          opacity: 0;
        }

        &.secondary-state {
          top: 50%;
          opacity: 1;
        }
      }
    }
  }

  &--dropdown {
    border: solid 1px $pacificBlue;
    background: $white;
    padding: 10px 14px;
    display: flex;
    color: $darkGray;
  }

  &--margin-right-validate-btn {
    margin-right: 5px;
  }
}

.controls__btn {
  width: 30px;
  height: 40px;
  position: relative;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: color 0.2s ease;
  padding: 0;

  .icon {
    display: inline-block;
    width: 100%;
    height: 30px;
    font-style: normal;
    position: relative;
    text-align: center;

    &::before {
      font-family: "Glyphter";
      width: 100%;
      height: 100%;

      @include flexCenter;

      color: $gray;
      justify-content: center;
      transition: color 0.2s ease;
    }

    &--bigger {
      font-size: 1.2em;
    }
  }

  p {
    font-size: 0.64em;
    font-weight: 500;
    color: $gray;
    transition: color 0.2s ease;
  }

  &:hover {
    color: $pacificBlue;

    p {
      color: $pacificBlue;
    }

    .icon {
      &::before {
        color: $pacificBlue;
      }
    }
  }

  &--download {
    .icon {
      &::before {
        content: "D";
      }
    }
  }

  &--share {
    .icon {
      &::before {
        content: "N";
      }
    }
  }

  &--delete {
    .icon {
      &::before {
        content: "P";
      }
    }

    &:disabled {
      visibility: hidden;
    }
  }

  &--quit {
    .icon {
      &::before {
        content: "G";
      }
    }

    &:disabled {
      visibility: hidden;
    }
  }

  &--edit {
    .icon {
      &::before {
        content: "V";
      }
    }
  }

  &--add {
    .icon {
      &::before {
        content: "J";
      }
    }
  }

  &--view {
    .icon {
      &::before {
        content: "X";
      }
    }
  }

  &--migrate {
    .icon {
      &::before {
        content: "h";
      }
    }
  }

  &--print {
    .icon {
      &::before {
        content: "z";
      }
    }
  }

  &--validate {
    top: 2px;
    @media screen and (max-width: 1080px){
      top: 0px;
    }

    .icon {
      &::before {
        content: "R";
        font-size: 21px;
      }
      @media screen and (max-width: 1080px){
        height: 27px !important;
      }
    }
  }

  &--copy-link {
    .icon {
      &::before {
        content: "V";
      }
    }
  }
}

.form-btn {
  display: inline-block;
  background: none;
  width: 40px;
  height: 40px;
  position: relative;
  outline: none;
  border: 0;
  cursor: pointer;
  color: $primaryGray;
  transition: color 0.2s ease;

  &::before {
    font-family: "Glyphter";
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    color: $color-primary;
  }

  &--visible {
    &::before {
      content: "X";
    }
  }

  &--invisible {
    &::before {
      content: "W";
    }
  }
}

.icon-single {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: none;
  color: $green;
  font-size: 0.85em;

  &::before {
    font-family: "Glyphter";
  }

  &--checked {
    &::before {
      content: "a";
    }
  }
}

.disabled {
  pointer-events: none;
}

.documents-btn-margin-right {
  border-radius: 40px 0 0 40px;
  border-right: 1px solid;
  width: 12rem;
}

.generate-iframe-lt-btn {
  padding: 2px 20px !important;
}

.drop-up-list {
  width: 100%;

  &.js-show {
    .option--list {
      display: block;
    }

    .option {
      &--current {
        &::before {
          transform: rotate(90deg) translateY(-50%);
        }
      }
    }
  }

  .option {
    width: 100%;
    color: $white;
    position: relative;
    transition: background 0.2s ease, color 0.2s ease;

    &--current {
      height: 41px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding-left: 0;
      border-radius: 0 40px 40px 0;
      background: #0096C7;

      @media all and (max-width: 1000px) {
        height: 37px;
      }

      @media all and (max-width: 540px) {
        height: 35px;
      }

      &::before {
        content: "A";
        width: 15px;
        height: 15px;
        font-family: "Glyphter";
        position: absolute;
        right: 20px;
        top: 50%;
        line-height: 15px;
        transform: rotate(268deg) translateY(-50%);
        transform-origin: top;
        transition: opacity 0.1s ease 200ms, visibility 0.1s ease 200ms;
        z-index: 5;

        &:hover {
          background: $pacificLight;
          color: #0087B3 !important;
        }
      }

      .shrink & {
        &::before {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0s ease, visibility 0s ease;
        }
      }

      &:hover {
        background: #0087B3;
      }
    }

    &--list {
      position: absolute;
      bottom: 4em;
      padding-top: 0;
      z-index: 10;
      display: none;
      margin-left: -15em;
      background: hsl(0,0%,100%);
      border: 2px solid $pacificBlue;
      border-radius: 8px;

      .shrink & {
        width: 100%;
        left: 0;
      }

      .option {
        height: 40px;
        display: flex;
        align-items: center;
        margin: 2px 0;
      }

      .flex-option {
        margin: 8px 0;
      }
    }
  }
}

.drop-up__menu-item {
  height: 41px;
  display: flex;
  align-items: center;
  padding-left: 46px;
  border-left: 2px solid transparent;
  color: $gray;
  font-weight: 500;
  position: relative;
  border-radius: 0 40px 40px 0;

  @media all and (max-width: 540px) {
    height: 35px;
  }

  @media all and (max-width: 912px) {
    height: 37px;
  }

  &::before {
    width: 21px;
    height: 22px;
    font-size: 21px;
    position: absolute;

    @include translateTop;

    left: 25px;
    color: $gray;
    font-family: "Glyphter";
  }
}

.drop-up-btn {
  width: 18rem;
  border-radius: 0;
  border: 0 !important;
  background: $white !important;
  color: $pacificBlue;
  padding: 10px 0;

  @media all and (max-width: 414px) {
    font-size: 0.9em;
  }

  &:hover {
    background: #EBF7FB !important;
  }
}

.drop-up-pro-icon {
  top: 5px !important;
}
