.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 101;
  top: 0;
  left: 0;
  display: none;

  .cancel {
    font-size: 0.857em;
    color: $red;
    font-weight: 500;
  }

  @media all and (max-width: 1000px) {
    padding: 16px;
  }

  &.js-active {
    display: block;
  }

  label {
    display: block;
    font-size: 0.7em;
    color: $gray;
    margin: 16px 0 3px 0;

    @media all and (max-width: $mq-tablet) {
      font-size: 0.7em !important;
    }
  }

  .form-group__checkbox--single {
    margin-right: 20px;
  }

  input[type="checkbox"] ~ label {
    margin: 0;
  }

  .btn {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &__timer {
    color: $color-primary;
    font-weight: 500;
    font-size: 1.5em;
    padding-left: 25px;
    position: relative;

    &::before {
      font-family: "Glyphter";
      width: 22px;
      height: 22px;
      position: absolute;
      left: 0;

      @include translateTop;

      text-align: center;
      line-height: 20px;
      content: "Q";
    }
  }

  .zealid-sign {
    h3 {
      display: none;
    }

    h5 {
      margin-bottom: 8px;
    }
  }
}

.popup__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.67);
}

.popup__content {
  background: $white;
  padding: 32px 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 565px;
  overflow: auto;
  max-height: 100%;

  .form-group {
    width: 53%;
    margin: auto;
  }

  @media all and (max-width: 688px) {
    .form-group {
      width: 100%;
    }
  }

  .name-td {
    width: 80%;
    padding-bottom: 10px;
  }

  .btn-td {
    vertical-align: top;
  }

  .email-link {
    padding-bottom: 3px;
  }

  .invite-name {
    font-weight: 500;
  }

  .standard-title {
    margin-bottom: 0;
  }

  @media all and (max-width: 1000px) {
    width: calc(100% - 32px);
    padding: 24px;
  }

  .tabs-list {
    margin-bottom: 24px;
    margin-top: 0;
  }

  &--small {
    @media all and (max-width: 1000px) {

      width: calc(100% - 32px);

      .form-group {
        width: 100%;
      }

      .form-group-v2 {
        width: 90%;
      }

      .popup__filter {
        form {
          flex-direction: initial;
        }

        .form-group--select {
          width: 100%;
          margin-right: 0;
        }

        .form-wrap--search {
          width: 100%;
          margin-left: 10px;
        }
      }

      .list__users {
        li {
          height: initial;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 10px;

          div {
            display: flex;
            flex-direction: column;
          }

          .btn {
            width: initial;
            padding: 10px 0 10px 39px;
          }
        }
      }
    }
  }

  &--medium {
    width: 480px;
    padding: 24px 75px;

    @media all and (max-width: 1000px) {
      width: calc(100% - 32px);
      padding: 24px;
    }

    form {
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        margin-top: 25px;
      }
    }

    .btn {
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        margin-top: 25px;
      }
    }
  }

  &--large {
    width: 665px;
  }

  &--full {
    width: 75%;
    height: 95%;

    @media all and (max-width: 1000px) {
      width: 100%;
      height: 100%;
      padding: 24px;
    }
  }

  &--auto {
    width: auto;
  }

  &--form-submit {
    padding: 24px 155px;

    form {
      margin: 32px 0 24px 0;
    }

    .icon-back {
      display: flex;
      position: absolute;
      top: 30px;
      left: 25px;
      font-weight: 500;
      color: $darkGray;

      &::before {
        content: url("../../img/icons/arrow-left.svg");
        margin-right: 16px;
      }
    }

    @media all and (max-width: 1000px) {
      padding: 24px;
    }
  }

  .invite-user {
    margin-top: 10px;

    li {
      height: 100%;
      padding: 6px 0;
      display: flex;
      flex-direction: row;

      @media (max-width: 1000px) {
        .btn {
          margin-left: 12px;
          position: relative;
          right: 0;
          font-size: 1em;
          top: initial;
          transform: initial;
        }
      }

      @media (max-width: 370px) {
        flex-direction: column;
        align-items: flex-start;

        .btn {
          margin-left: initial;
        }
      }

      &:last-child {
        padding-bottom: 40px;
      }
    }

    &__info-content {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .form-group.form-group--select {
      max-width: 200px;
      margin: initial;

      @media (max-width: 370px) {
        max-width: 100%;
      }
    }

    .form-group__checkbox {
      label[for="document-all"] {
        padding-left: 32px;
        margin-bottom: 14px;
      }
    }
  }
}

.tab-popup__content {
  width: 780px;
  padding-bottom: 0;

  @media all and (max-width: 1000px) {
    width: calc(100% - 32px);
    padding: 24px;
  }
}

.modal-company-user {
  .popup__content {
    height: 500px;
  }
}

.popup__close {
  width: 40px;
  height: 40px;
  position: absolute;
  font-size: 0.8em;
  top: 0;
  right: 0;

  @include fleXCenterCenter;

  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;

  &::before {
    font-family: "Glyphter";
    width: 20px;
    height: 20px;
    content: "B";
    text-align: center;
    line-height: 20px;
  }

  &:hover {
    opacity: 1;
  }
}

.popup__smartid {
  .popup__content {
    width: 415px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      height: 50px;
    }

    .standard-title {
      margin: 32px 0 18px 0;
    }

    .code {
      font-size: 3em;
      font-weight: 500;
    }

    .content__message {
      display: flex;
      align-items: center;
      margin: 20px 0 32px 0;

      p {
        color: $gray;
        font-size: 0.857em;
        margin-left: 12px;
      }
    }

    @media all and (max-width: 1000px) {
      width: calc(100% - 32px);
    }
  }
}

.popup__redirect {
  .popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .standard-title {
    font-size: 1em;
    margin-top: 25px;
  }

  .status {
    font-size: 1.5em;
    color: $color-primary;
    margin: 18px 0 30px 0;

    &::before {
      width: 22px;
      height: 22px;
      color: $color-primary;
    }
  }
}

.popup__user {
  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    color: $darkGray;
  }

  span {
    color: $color-primary;
  }

  .btn {
    margin-top: 43px;

    @media all and (max-width: 1000px) {
      margin-top: 25px;
    }
  }

  .form-group {
    width: 100%;
  }

  .form-group__label {
    font-size: .95em;
  }

  .form-group__input {
    height: 100px;
    resize: none;
  }

  .form-group__input--reason-text {
    padding: 12px;
  }

  p[for="form-group__input--reason-text"] {
    span {
      display: flex;
      flex-direction: column;
    }
  }

  .form-group__error {
    color: $red;
    bottom: initial;
  }
}

.popup__assign {
  &--subtitle {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: center;

    p {
      color: $gray;
      margin-top: 3px;
    }
  }

  .popup__filter {
    form {
      justify-content: center;
    }
  }

  .form-group__checkbox {
    @media all and (max-width: 1000px) {
      position: absolute;
      left: 0;

      @include translateTop;
    }

    label {
      margin: 0;
    }
  }

  .list__name {
    padding-left: 20px;

    @media all and (max-width: 1000px) {
      padding-left: 0;
    }
  }

  .popup__list {
    border-bottom: 1px solid $mediumGray;
    padding-bottom: 10px;

    @media all and (max-width: 1000px) {
      li {
        padding-left: 40px;
      }
    }
  }

  .btn {
    margin-top: 24px;
  }
}

.popup__locked {
  .popup__content {
    padding-top: 54px;

    p {
      margin-top: 9px;
      margin-bottom: 24px;
    }

    .btn {
      margin-top: 24px;
    }
  }
}

.popup--session {
  .standard-title {
    margin-top: 25px;
  }

  .popup__timer {
    display: inline-block;
    margin: 18px 0 30px;
  }
}

.popup--pdf-view {
  .popup__content {
    display: flex;
    flex-direction: column;
  }
}

.popup--signature-type {
  .popup__content {
    @media all and (max-width: 1000px) {
      width: unset;
      padding: 24px;
    }
  }
}

.modal-hubspot-form {
  width: 100%;
  height: 100%;
  min-width: 320px;
  position: relative;

  &__iframe {
    border: none;
    width: 100%;
    max-width: 768px;
    min-width: 320px;
    height: 613px;
    position: relative;

    @media (max-width: 524px) {
      height: 666px;
    }
  }
}

.modal-remove-reason {
  label {
    color: $black;
    font-weight: 400;
  }
}

.popup__has-illustration-image {
  .popup__content {
    padding: 0;
    position: relative;
    width: auto;
    margin: auto;
    background: #fff;
  }

  .popup__overlay > div {
    width: 100%;
    height: 100%;
  }
}

.zeal-tempo-modal {
  height: 40vh;
}
