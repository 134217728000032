.settings__top {
  @include fleXCenterBetween;

  margin-bottom: 24px;

  &--single {
    justify-content: flex-end;
  }

  @media all and (max-width: 1100px) {
    flex-direction: column;
    margin-bottom: 14px;
  }

  .form-wrap--search {
    width: 325px;

    @media all and (max-width: 1100px) {
      width: 100%;
    }
  }

  @media all and (max-width: 1100px) {
    .settings__top__left {
      width: 100%;
      text-align: right;
      margin-bottom: 22px;
    }
  }

  .settings__top__right {
    @media all and (max-width: 1100px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 17px;
    }

    .btn {
      margin-left: 16px;

      @media all and (max-width: 1100px) {
        margin-left: 0;
      }
    }
  }
}

.settings__contacts-count {
  font-size: 0.85em;
  padding-left: 25px;
  color: $gray;
  position: relative;
  margin-left: 12px;

  @media all and (max-width: 1000px) {
    padding-left: 20px;
    font-weight: 400;
  }

  &::before {
    content: "I";
    font-family: "Glyphter";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media all and (max-width: 1000px) {
      height: 11px;
    }
  }
}

.settings__documents-count {
  font-size: 0.85em;
  padding-left: 25px;
  color: $gray;
  position: relative;
  margin-left: 12px;

  @media all and (max-width: 1000px) {
    padding-left: 20px;
    font-weight: 400;
  }

  &::before {
    content: "C";
    font-family: "Glyphter";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    @media all and (max-width: 1000px) {
      height: 11px;
    }
  }
}
