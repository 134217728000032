#pricing-success {
  .pricing-success__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 970px;
    padding: 78px 284px 82px 284px;

    @media all and (max-width: 768px) {
      padding: 40px 60px 40px 60px;
    }
  }

  .logo {
    margin-bottom: 70px;
    width: 130px;
    height: 52px;
  }

  .standard-title {
    margin: 40px 0 12px 0;
  }

  .message {
    margin-bottom: 24px;
  }

  .sidebar__language {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 240px;
    border-top: none !important;
    z-index: 10;

    p {
      font-weight: 500;
    }
  }

  .pricing-success__user-info {
    text-align: center;
    margin-bottom: 14px;

    p {
      margin-bottom: 12px;
    }
  }

  .email-confirmation-content {
    padding: 0;

    @media all and (max-width: 768px) {
      padding: 0;
    }
  }
}
