@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.checkout {
  padding-bottom: 43px;

  @media (max-width: 1000px) {
    padding-bottom: 0;
    font-size: 12px;
  }

  &__header {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-right: 13px;
    }

    .sidebar__language {
      border-top: none;
      width: 175px;
      position: relative;

      .language--list {
        padding-top: initial;
        height: 144px;  // (n * 40) + ((n + 1) * 6), where n is the number of items in list
      }

      .language--list .language {
        padding-left: 62px;
      }

      .language--current {
        .sidebar__menu-item {
          &::before {
            left: 15px;
          }
        }

        .language {
          height: 64px;
          padding-left: 49px;

          img {
            left: 0;
          }
        }
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  &__section {
    padding-left: 35px;

    @media (max-width: 1000px) {
      padding-left: 0;
    }

    .info-details__info {
      display: flex;
      justify-content: flex-end;
      flex-direction: initial;

      & > * {
        position: relative;
        margin-right: 0;
      }
    }

    li {
      display: flex;
      margin-top: 30px;
    }

    label[for='radioPayment1'] {
      &:before {
        border-radius: 6px 0 0 6px;
      }
    }

    label[for='radioPayment2'] {
      &:before {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  &__pick-subscription {
    @media (max-width: 1000px) {
      flex-direction: column;
    }

    .info-details__info {
      width: 248px;

      @media (max-width: 1000px) {
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 30px auto 10px auto;
      }

      .form-group__radio {
        width: 100%;
        margin-left: 0;

        label {
          text-align: center;
          padding: 0;

          &:before {
            content: "";
            margin: 0;
            width: 100% !important;
            height: 32px !important;
            border: 1px solid #EDEFF2 !important;
          }
        }

        input {
          &:checked + label {
            color: $pacificBlue;
            font-weight: 500;

            &:before {
              border-color: #0096C7 !important;
              background: #0096c721;
            }

            &:after {
              opacity: 0 !important;
            }
          }
        }
      }
    }
  }

  &__form {

    li {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 0;

      .info-details__label {
        width: 100%;
        margin-bottom: 5px;

        .checkout__possible-payments {
          padding-top: 10px;

          img {
            margin-right: 10px;
          }
        }
      }

      .info-details__info {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > {
          * {
            flex: 1 1 0;
            margin-right: 0;
          }
        }
      }

      .checkout__cvc {
        position: relative;

        &:before {
          content: url("../../img/icons/credit-card-labeled.svg");
          position: absolute;
          top: 9px;
          right: 50px;
          z-index: 1;
        }
      }
    }
  }

  label[for="add-to-contacts"] {
    color: gray;
    margin-top: 10px;
  }

  .sticky-top {
    z-index: initial;
    .wrapped-section {
      position: fixed;
      width: 41%;
      top: 24px;

      @media (max-width: 1025px) {
        position: initial;
        width: 100%;
        margin-top: 9px;
      }
    }
  }

  &__discount {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-top: 30px;
    border-top: 1px solid $mediumGray;

    .info-details__label {
      padding-bottom: 15px;
    }

    .form-group__input {
      border-right: none;
    }

    .form-group__input.invalid {
      border-color: $red;
    }

    .info-details__info {
      width: 100%;
      position: relative;
      height: 70px;
      top: -15px;

      .form-group--fixed-size {
        width: 100%;
        display: flex;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 115px;
          height: 38px;
          width: 5px;
          border-top: 1px solid $pacificLight;
          border-bottom: 1px solid $pacificLight;
        }

        input[name="coupon"] {
          border-right: none;
          border-radius: 5px 0 0 5px !important;
        }
      }

      .form-group--fixed-size.invalid {
        &:before {
          border-top: 1px solid $red;
          border-bottom: 1px solid $red;
        }
      }
    }

    button {
      border-radius: 5px;
      cursor: pointer;
    }

    .checkout__discount--message {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      position: absolute;
      left: 0;
      top: 65px;

      img {
        margin-right: 5px;
        width: 16px;
      }

      p {
        font-weight: 400;
        color: $gray;
      }
    }

    &--message.invalid {
      p {
        color: $red;
      }
    }
  }

  &_label-info {
    display: flex;
    flex-direction: column;

    p {
      color: $green;
      font-weight: 500
    }
  }

  &__order-subscription {
    text-align: center;
    margin-top: 60px;

    p {
      margin-top: 20px;
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__prices-annual {
    position: absolute;
    font-size: 9px;
    top: 37px;
    width: 145px;
    text-align: center;
    animation: fadeIn 1.2s;

    span {
      color: $green;
    }
  }

  &__prices-monthly {
    position: absolute;
    font-size: 9px;
    top: 37px;
    width: 107px;
    text-align: center;
    animation: fadeIn 1.2s;

    span {
      color: $green;
    }
  }

  &__payment--method {
    p {
      color: $gray;
    }
  }

  .info-icon-grey {
    margin-left: 5px;
  }
}

.pacific-blue {
  color: $pacificBlue;
}

.price__anunal_lt {
  width: 150px !important;
}

.order-summary {
  max-height: 90vh;
  overflow-y: auto;
}