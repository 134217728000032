#header-menu {
  display: none;
  position: fixed;
  width: 100%;
  height: calc(100% - 76px);
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 101;

  @include smallShadow;

  flex-direction: column;
  justify-content: space-between;

  &.js-show-menu {
    @media all and (max-width: 1000px) {
      display: flex;
      overflow: auto;
    }
  }

  @media all and (max-width: 1000px) {
    .sidebar__top {
      &.js-show {
        .sidebar__user--more-users, .sidebar__user__overlay {
          display: block;
        }
      }
    }
  }

  .sidebar__user-logo {
    height: 117px;
  }
}

#mobile-header {
  background: $white;
  padding: 12px 0;
  position: relative;
  justify-content: center;
  display: none;
  width: 100%;
  z-index: 10;

  @media all and (max-width: 1000px) {
    display: flex;
  }

  .sidebar__logo {
    height: 55px;
    padding-top: 0;
    padding-left: 0;
  }

  &.js-menu-show {
    position: fixed;
    top: 0;
    left: 0;
  }
}

.hamburger {
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  position: absolute;
  top: 0;
  right: 8px;
  z-index: 101;
  display: flex;
  cursor: pointer;

  .line {
    width: 20px;
    height: 2px;
    background-color: $color-primary;
    display: block;
    margin: 4px 0;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;

    &:nth-child(2) {
      width: 15px;
    }
  }

  &.active {
    .line {
      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(1) {
        transform: translateY(6px) rotate(45deg);
      }

      &:nth-child(3) {
        transform: translateY(-6px) rotate(-45deg);
        width: 20px;
      }
    }
  }
}

.sidebar__user__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.67;
  display: none;
  z-index: 2;
}
