@import '~bootstrap/dist/css/bootstrap-utilities.css';

// 1 - Vendors
@import "1_vendors/1-swiperjs";
@import "1_vendors/2-simplebar";

// 2 - Reset
@import "2_reset/1-normalize";

// 3 - Setup
@import "3_setup/1-variables";
@import "3_setup/2-utilities";
@import "3_setup/3-typography";
@import "3_setup/4-fonts";
@import "3_setup/5-grid";
@import "3_setup/6-animations";
@import "3_setup/7-mixins";

// 4 - Components
@import "4_components/1-buttons";
@import "4_components/2-form";
@import "4_components/3-tabs";
@import "4_components/4-pagination";
@import "4_components/5-controls-buttons";
@import "4_components/6-statusbar";
@import "4_components/7-status";
@import "4_components/8-upload";
@import "4_components/9-accordion";
@import "4_components/10-table";
@import "4_components/_11-users";
@import "4_components/_12-pro-icons";
@import "4_components/_13-purpose-icons";
@import "4_components/_14-validation-report-modal";
@import "4_components/_15-additional-settings";
@import "4_components/_16-confirm-email";
@import "4_components/_17-anchor";
@import "4_components/_18-dropzone-progress";

// 5 - Layout
@import "5_layout/1-header";
@import "5_layout/2-footer";
@import "5_layout/3-sections";
@import "5_layout/4-sidebar";
@import "5_layout/5-document-list";
@import "5_layout/6-empty-list";
@import "5_layout/7-preview";
@import "5_layout/8-info-details";
@import "5_layout/9-side-document-preview";
@import "5_layout/10-bottom-bar";
@import "5_layout/11-popup";
@import "5_layout/12-sharing-settings";
@import "5_layout/13-popup-filter";
@import "5_layout/14-settings";
@import "5_layout/12-login";
@import "5_layout/15-pricing";
@import "5_layout/16-mobile-menu";
@import "5_layout/17-pricing-success";
@import "5_layout/18-billing";
@import "5_layout/19-navbar";
@import "5_layout/20-global-signatures";
@import "5_layout/21-temporary-document-preview";
@import "5_layout/22-popup-illustration";
@import "5_layout/23-usb-documents-signing";
@import "5_layout/24-registration";
@import "5_layout/25-software-diagnostic";
@import "5_layout/26-statistics";
@import "5_layout/27-info-messages";
@import "5_layout/28-popup-newsletter-decision";

// 6 - Pages
@import "6_pages/1-checkout";
@import "6_pages/2-page-not-found";

// 7 - Responsive
@import "7_responsive/1-responsive-layout";
