#upload {
  .grid {
    height: 100%;
  }

  .upload__content {
    height: calc(100vh - 204px);
    background-color: $white;
    border: 2px dashed $mediumGray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media all and (max-width: 1000px) {
      border: 0;
      padding: 10px 5px;
    }

    @media all and (max-width: 500px) {
      height: calc(100vh - 170px);
    }

    .upload__box {
      .box__input {
        p {
          color: $gray;
          margin-top: 8px;
        }

        .btn {
          margin-top: 20px;
          margin-bottom: 10px;
        }

        .box__input__title {
          cursor: pointer;

          span {
            color: $pacificBlue;
          }
        }

        &-supportedfiles {
          @media (max-width: 540px) {
            font-size: 0.9em;
          }
        }
      }
    }

    &.dz-drag-hover {
      background-color: $pacificLight;
    }
  }

  h3 {
    font-size: 1.14em;
    margin-top: 32px;
  }

  .upload-status {
    &--uploading {
      display: none;

      .uploading__icon {
        width: 110px;
        height: 110px;
        border: 6px solid $mediumGray;
        border-top: 6px solid $pacificBlue;
        border-radius: 50%;
      }
    }

    &--success {
      display: none;

      .success__icon {
        position: relative;
        display: inline-block;
        background-color: $pacificBlue;
        border-radius: 50%;
        width: 110px;
        height: 110px;

        &::before {
          content: url("../../img/icons/success.svg");
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50px;
          height: 50px;
        }
      }
    }

    &--error {
      display: none;
      background-color: $red;
      background: {
        image: url("../../img/icons/warning-white.svg");
        size: auto;
        position: 1%;
        repeat: no-repeat;
      };
      color: $white;
      font-weight: 500;
      padding: 16px 0 16px 48px;
      width: 100%;
      margin-top: 16px;
    }
  }

  .upload__list {
    border: 2px solid $color-pale-deep;
    border-radius: 7px;
    width: 500px;

    @media (max-width: 540px) {
      font-size: 0.9em;
      width: 100%;
    }

    & > * {
      width: 100%;
    }

    &-header {
      font-size: 1.14em;
      font-weight: 500;
      background-color: white;
      border-radius: 7px 7px 0px 0px;
      border-bottom: 2px solid $color-pale-deep;
      display: flex;
      justify-content: center;
      align-items: center;

      &-back {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top-left-radius: 7px;
        cursor: pointer;
        &::after {
          font-family: "Glyphter";
          content: "b";
          font-size: 15px;
          font-weight: bold;
        }
      }
      &-text {
        flex-grow: 1;
      }
    }

    &-container {
      text-align: left;
      border-radius: 0 0 7px 7px;
      display: flex;
      flex-direction: column;
    }

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      color: black;
      padding: 5px;

      &:first-child {
        padding-top: 15px;
      }

      &:last-child {
        padding-bottom: 15px;
      }

      &-label {
        flex-grow: 1;
      }
      &::before {
        font-family: "Glyphter";
        content: "a";
        font-weight: 800;
        color: transparent;
        font-size: 17px;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &.active::before {
        color: $pacificBlue;
      }
      &.disable {
        color: $gray;
      }
    }

    &-item--adocfile {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;
      color: black;

      &-name {
        flex-grow: 1;
      }

      &-btn {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 137px;

        &:not(.main):hover {
          color: $pacificBlue;
          &::before {
            color: $pacificBlue;
          }
        }

        &.main {
          text-decoration: none;
          &::before {
            color: transparent;
          }
        }

        &::before {
          font-family: "Glyphter";
          content: "J";
          font-weight: 800;
          color: black;
          font-size: 13px;
          width: 17px;
          height: 17px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .upload__filetypes {
    &-header {
      font-size: 1.14em;
      font-weight: 500;
      margin: 20px 0;
    }

    &-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 24px;

      @media (max-width: 500px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: min(24px);
        margin-inline: auto;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 70px;
      height: 70px;
      justify-content: center;
      align-content: center;
      gap: 7px;
      position: relative;
      background-color: $color-pale;
      border-width: 2px;
      border-style: solid;
      border-color: $color-pale-deep;
      border-radius: 7px;
      cursor: pointer;

      &.active {
        border-color: $pacificBlue;
        &::after {
          color: white;
          background-color: $pacificBlue;
          font-family: "Glyphter";
          content: "a";
          position: absolute;
          top: -2px;
          right: -2px;
          width: 22px;
          height: 22px;
          font-size: 15px;
          font-weight: bold;
          display: flex;
          align-content: center;
          justify-content: center;
          flex-direction: column;
          border-top-right-radius: 7px;
          border-bottom-left-radius: 5px;
        }
      }
    }
    &-label {
      font-size: 11px;
      font-weight: 500;
    }
    &-icon {
      width: 18px;
      height: 18px;
      &--pdf {
        background: url("../../img/icons/pdf.svg") center center no-repeat;
        background-size: contain;
      }

      &--bdoc {
        background: url("../../img/icons/bdoc.svg") center center no-repeat;
        background-size: contain;
      }

      &--adoc {
        background: url("../../img/icons/adoc.svg") center center no-repeat;
        background-size: contain;
      }

      &--asice {
        background: url("../../img/icons/asice.svg") center center no-repeat;
        background-size: contain;
      }

      &--xml {
        background: url("../../img/icons/xml.svg") center center no-repeat;
        background-size: contain;
      }
    }

    &-adoc-file-list {
      text-align: left;
      width: 350px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        &-btn {
          text-align: right;
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
          white-space: nowrap;
          align-items: center;
        }
      }
    }
  }

  .main-file-selection-container.upload__list-container {
    gap: 10px;
    padding: 10px;
  }
}
