.status {
  color: $gray;
  font-weight: 500;
  font-size: 0.85em;
  padding-left: 25px;
  position: relative;

  &::before {
    font-family: "Glyphter", serif;
    width: 15px;
    height: 20px;
    position: absolute;
    left: 0;

    @include translateTop;

    text-align: center;
    line-height: 20px;
  }

  &--waiting {
    &::before {
      content: "Q";
    }
  }

  &--signed {
    color: $green;

    &::before {
      content: "O";
    }
  }

  &--signlevel {
    color: $pacificBlue;

    &::before {
      content: "O";
    }
  }

  &--partially-signed {
    color: $orange;

    &::before {
      content: "F";
    }
  }

  &--not-signed {
    color: $red;

    &::before {
      content: "S";
    }
  }

  &--switch {
    color: $blue;

    &::before {
      content: "A";
    }
  }

  &--lock {
    width: 20px;

    &::before {
      content: "U";
      left: 5px;
    }
  }
}
