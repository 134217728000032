.global-signatures {
  .wrapped-section {
    @media (max-width: 1000px) {
      width: 100%;

      &:last-child {
        margin-bottom: 50px;
      }
    }
  }

  .headline {
    color: $black;
    margin-bottom: 12px;
  }

  &__content {
    width: 100%;

    &.first-time-zealid-connect {
      width: 90%;

      & ul li::before {
        top: 0px;
      }
    }

    @media (max-width: 1000px) {
      width: 100%;
    }

    &--info-block {
      display: flex;
      align-items: center;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      p {
        padding-bottom: 12px;
      }

      ol {
        padding: 0;
      }

      li {
        position: relative;
        margin: 0 0 0 32px;
        &:not(:last-child) {
          padding: 0 0 12px 0;
        }
      }

      ul {
        li {
          &:before {
            content: "a";
            font-family: Glyphter, serif;
            position: absolute;
            font-weight: bolder;
            font-size: 11px;
            top: 3px;
            left: -20px;
            color: $green;
          }
        }
      }

      a {
        color: $pacificBlue;
      }

      button {
        margin-top: 24px;
      }
    }

    &--paragraph {
      @media (max-width: 1000px) {
        margin-top: 24px;
      }
    }

    &--buttons {
      padding: 42px 0;
      text-align: center;

      @media (max-width: 1000px) {
        padding: 42px 12px;
      }

      a {
        color: $white;
        margin-right: 19px;
      }
    }

    &--img {
      max-width: 250px;
      margin-right: 50px;

      @media (max-width: 1000px) {
        margin-right: initial;
      }

      img {
        width: 100%;
      }
    }

    &--tablet-img-top {
      display: none;

      @media (max-width: 1000px ) {
        display: flex;
        justify-content: end;
        width: 100%;

        img {
          max-width: 290px;
        }
      }
    }

    &--tablet-img-bottom {
      display: none;

      @media (max-width: 1000px) {
        display: flex;
        justify-content: space-between;
        width: 100%;

        img {
          &:nth-child(1) {
            max-width: 273px;
            margin-right: 8px;
          }

          &:nth-child(2) {
            max-width: 257px;
          }
        }
      }
    }
  }

  .tabs-content {
    &:nth-child(even) {
      &:nth-child(2) {
        .global-signatures__content--img {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &:before {
            content: "";
            background-image: url("../../../assets/img/global-signatures/cloud.png");
            background-size: cover;
            position: absolute;
            left: 2px;
            bottom: -42px;
            width: 251px;
            height: 152px;
            z-index: 1;

            @media (max-width: 1000px) {
              left: -36px;
              bottom: -12px;
              width: 330px;
              height: 202px
            }
          }

          img {
            position: relative;
            z-index: 2;
            max-width: 150px;
          }
        }
      }

      &:nth-child(4) {
        .global-signatures__content--img {
          max-width: 250px;
          width: 100%;
          margin-right: 25px;
          margin-left: 25px;
          text-align: center;
          position: relative;

          &:before {
            content: "";
            background-image: url("../../../assets/img/global-signatures/cloud-upside.png");
            background-size: cover;
            position: absolute;
            left: 2px;
            top: -42px;
            width: 251px;
            height: 171px;
            z-index: 1;

            @media (max-width: 1000px) {
              left: -35px;
              top: -116px;
              width: 330px;
              height: 237px;
            }
          }

          img {
            position: relative;
            z-index: 2;
            max-width: 150px;
          }
        }

        a {
          img {
            margin-right: 8px;
            margin-bottom: 24px;
            width: 125px;
          }
        }
      }

      .wrapped-section {
        background: $lightBlue;

        &__headline {
          @media (max-width: 1000px) {
            justify-content: center;
          }
        }
      }
    }

    .wrapped-section {
      margin-bottom: 25px;

      @media (max-width: 1000px) {
        overflow: hidden;

        &__headline {
          display: flex;
          justify-content: center;
          margin-bottom: 24px;
        }
      }
    }
  }

  &__es-qualification {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    div {
      display: flex;

      img {
        width: auto;
        height: 45px;
        margin-right: 24px;
      }
    }

    @media (max-width: 1000px) {
      justify-content: initial;
    }
  }

  &__how-it-works {
    .row {
      & .col {
        padding-left: 20px;
        padding-right: 20px;

        @media (max-width: 1024px) {
          padding-bottom: 24px;
        }

        & > p {
          padding-bottom: 0px;
        }
      }
    }

    .headline--standard {
      margin-bottom: 24px;
      font-size: 1.2em;
    }

    img {
      height: 42px;
      margin-bottom: 10px;
    }

    & p.note {
      padding-top: 25px;
      padding-bottom: 12px;
    }

    strong {
      img {
        margin-bottom: 0;
        height: 13px;
        margin-right: 8px;
      }
    }

    & ul li::before {
      top: 0px;
    }
  }

  &__first-time-zealid {
    padding-bottom: 24px;

    &:last-child {
      padding-bottom: 0;
      & p {
        padding-bottom: 0;
      }
    }
  }

  &__cta {
    padding: 0;

    & .buttons-wrapper {
      display: flex;
      justify-content: center;

      & button {
        margin-top: 0;
        white-space: nowrap;
      }
    }

    .global-signatures__content {
      width: 100%;

      &--info-block {
        max-height: 183px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 1000px) {
          max-height: initial;
          height: 100%;
        }
      }

      h2 {
        margin-bottom: 32px;

        @media (max-width: 1000px) {
          margin-bottom: 0;
        }
      }

      &--side-images {
        img {
          max-height: 183px;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}