i {
  font-style: normal;
}

.d-none {
  display: none !important;
}

// element floats and positioning
.fl-left {
  float: left;
}

.fl-right {
  float: right !important;
}

.fl-center {
  margin-left: auto;
  margin-right: auto;
}

.fl-none {
  float: none;
}

.position-relative {
  position: relative;
}

.va-center {
  position: relative;
  top: 50%;
  transform: translatey(-50%);
}

// element text align
.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.ta-right {
  text-align: right;
}

.ta-justify {
  text-align: justify;
}

// Font size
.text-xs {
  font-size: $fs-xs !important;
}

.text-sm {
  font-size: $fs-sm !important;
}

.text-base {
  font-size: $fs-base !important;
}

.text-lg {
  font-size: $fs-lg !important;
}

.text-xl {
  font-size: $fs-xl !important;
}

.text-2xl {
  font-size: $fs-2xl !important;
}

.text-3xl {
  font-size: $fs-3xl !important;
}

// Font weight
.fw-light {
  font-weight: $fw-light;
}

.fw-regular {
  font-weight: $fw-regular;
}

.fw-bold {
  font-weight: $fw-bold;
}

// Width
.w-100 {
  width: 100%;
}

.w-75 {
  width: 100%;
}

.w-50 {
  width: 100%;
}

// float clear classes
.clear {
  clear: both;
}

.clearfix {
  &:before, &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

// Overflow
.ovh {
  overflow: hidden;
}

// Page container
#page-container {
  &.ovh {
    height: 100vh;
  }

  &.page-container--full-height {
    width: 100%;
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 700px;

    @media all and (max-width: 1000px) {
      overflow-y: auto;
      min-height: auto;
      display: block;
    }
  }

  &.page-container--full-width {
    position: relative;
    height: 100vh;
    display: initial !important;
    min-height: 700px;

    @media all and (max-width: 1000px) {
      overflow-y: auto;
      min-height: auto;
      display: block;
    }

    main {
      float: initial;
      width: initial;
    }
  }
}

// Image box
.img-box {
  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
  }

  &.img-box--full {
    img {
      width: 100%;
    }
  }
}

// Text box
.text-box {
  overflow: hidden;

  > * {
    margin: 1em 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .list-dot {
    padding-left: 0;

    li {
      padding: 5px 0;
      line-height: 1.6em;

      &::before {
        content: "•";
        width: 10px;
        height: 10px;
        text-align: center;
        margin-right: 5px;
      }
    }
  }

  article & {
    h1, h2, h3, h4, h5 {}

    ul {
      padding: 0;
      list-style-type: none;

      li {
        padding: 3px 0 3px 10px;
        position: relative;

        &::before {
          content: "";
          width: 3px;
          height: 1px;
          background: $color-primary;
          position: absolute;
          left: 0;
          top: 11px;
        }
      }
    }

    ol {
      counter-reset: section;
      list-style-type: none;
      padding: 0;
      list-style-type: none;
      margin: 2em 0;

      li {
        padding: 2px 0 2px 20px;
        position: relative;
        line-height: 1.5em;

        &::before {
          counter-increment: section;
          content: counters(section, ".") ".";
          color: $color-primary;
          position: absolute;
          top: 3px;
          left: 0;
          font-size: 0.9em;
        }
      }
    }

    table {
      tr {
        &:first-child {
          td {
            font-weight: 500;
          }
        }

        td {
          color: $color-primary;
          padding: 17px 0 17px 40px;

          @media all and (max-width: 768px) {
            white-space: nowrap;
          }

          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
}

// Hide recaptcha v3 badge

.grecaptcha-badge {
  display: none;
}

// Aspect ratio classes

[class*='ratio-'] {
  display: block;
  position: relative;

  > * {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.aspect-ratio-wide {
  padding-top: 56.25%;
}

.aspect-ratio-square {
  padding-top: 100%;
}

.email {
  &::after {
    content: attr(data-name) attr(data-domain);
  }
}

body {
  background: $lightGray;
}

a {
  color: $color-primary;
}

ul {
  padding-left: 0;
  margin: 0;
  list-style-type: none;
}

strong, b {
  font-weight: 500;
}

.center-element {
  display: flex;
  justify-content: center;

  &--space-between {
    justify-content: space-between;
  }
}

.top-border-separator {
  border-top: 1px solid $mediumGray;
  padding-top: 17px;
  margin-top: 17px;
}

.m-0 {
  margin: 0 !important;
}
.ml-0, .mx-0 {
  margin-left: 0 !important;
}
.mr-0, .mx-0 {
  margin-right: 0 !important;
}
.mb-24 {
  margin-bottom: 24px;
}

.hide {
  display: none;
}

.show {
  display: initial;
}

.display-tablet {
  @media (min-width: 1001px) {
    display: none !important;
  }
}

.display-desktop {
  @media (max-width: 1000px) {
    display: none !important;
  }
}

.anchor-tag-decoration {
  color: $pacificBlue;
  text-decoration: underline;
}

.display-tablet {
  @media (min-width: 1000px) {
    display: none;
  }
}

.display-desktop {
  @media (max-width: 1000px) {
    display: none;
  }
}

.cursor-initial {
  cursor: initial !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.text-capitalize, .capitalized-text {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-lowercase {
  text-transform: lowercase;
}

.border-none {
  border: none !important;
}

.of-scroll {
  overflow: scroll !important;
}

.bgc-white {
  background-color: $white !important;
}