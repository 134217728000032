#side-document-preview {
  position: fixed;
  width: calc(100% * 0.40 - 12px);
  background: $white;
  height: 100vh;
  right: 0;
  transition: width 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  @media print {
    display: none;
  }

  @media all and (max-width: 1600px) {
    width: calc(100% * 0.38 - 12px);
  }

  @media all and (max-width: 1400px) {
    width: calc(100% * 0.37 - 12px);
  }

  @media all and (max-width: 1200px) {
    width: calc(100% * 0.36 - 12px);
  }

  @media all and (max-width: 1100px) {
    width: calc(100% * 0.345 - 12px);
  }

  @media all and (max-width: 1000px) {
    visibility: hidden;
  }

  &.shrink {
    width: calc(100% * 0.45 - 7px);

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }
}

.preview__controls {
  height: 100px;
  border-bottom: 1px solid $mediumGray;
  margin-bottom: 24px;

  @include fleXCenterCenter;

  @media all and (max-width: 1000px) {
    display: none;
  }
}

.preview__document-box {
  padding: 0px 32px 64px;
  color: $primaryGray;
  font-weight: 500;

  @media all and (max-width: 1000px) {
    padding: 16px;
  }
}

.preview__document-content {
  margin-top: 24px;
  flex: 1 1 auto;
  width: 100%;
  height: 450px;
  background: $lightGray;
  color: $color-primary;
  font-size: $fs-base;
  padding: 24px;

  @media all and (max-width: 1000px) {
    padding: 16px;
  }

  img {
    width: 100%;
  }

  .pdf-viewer {
    height: 100%;
  }
}

.preview__document-file {
  padding: 8px 15px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  .controls__btn {
    width: 30px;
    height: 40px;
  }

  .status-and-controls {
    display: flex;
    justify-content: center;
    align-items: center;

    .status {
      color: $pacificBlue;
    }
  }
}

.react-pdf {
  background-color: $lightGray;

  &__document {
    text-align: center;
  }

  &__page {
    display: inline-block;
    border: 9px solid transparent;
    margin: 1px auto -8px;
  }
}