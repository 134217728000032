.modal-newsletter-decision {
  & .popup__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    width: 100%;
    @media all and (min-width: 601px) {
      width: 600px;
      padding: 40px 40px 32px 40px;
    }

    & .logo {
      width: 110px;
      @media all and (min-width: 601px) {
        width: 140px;
      }
    }

    & .title {
      font-size: 1.8em;
      @media all and (min-width: 601px) {
        font-size: 2em;
      }
    }

    & .description {
      padding: 0;
      font-size: 1em;
      @media all and (min-width: 601px) {
        font-size: 1.1em;
        padding: 0px 50px;
      }
    }

    & .form-group__checkbox {
      display: flex;
      justify-content: center;

      & > label {
          font-size: 1em !important;
          @media all and (min-width: 601px) {
          font-size: 1.1em !important;
        }
        margin: unset;
      }
    }

    & button {
      margin: unset;
      border-radius: 5px;
      width: 100%;
      padding: 12px 17px;
      @media all and (min-width: 601px) {
        width: 430px;
        padding: 15px 20px;
      }
    }

    & .t-and-c {
      font-size: .8em;
      line-height: 1.4em;
      padding: 0;
      & a {
        text-decoration: underline;
      }
      @media all and (min-width: 601px) {
        padding: 0px 50px;
        font-size: .9em;
        line-height: 1.5em;
      }
    }
  }
}

.profile-update-link {
  text-decoration: underline;
  cursor: pointer;
  color: $infoBlue;
  font-weight: bold;
  font-size: 14px;
}

.profile-description {
  color: #737373 !important;
}

.personal-account {
  font-weight: bold;
  color: $infoBlue !important;
}