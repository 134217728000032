#login {

  .language--list {
    top: 0;
    padding-top: 0;
    height: 145px;

    @media (max-width: 1000px) {
      top: -10px;
    }
  }

  .tabs-list {
    border-bottom: none;
    margin-bottom: 40px;

    @media (max-width: 522px) {
      display: flex;
      flex-direction: column;

      li {
        min-width: 240px;
      }
    }
  }

  .sidebar__menu-item--lang-switch {
    @media (max-width: 1000px) {
      padding-right: 55px;
    }
  }

  .login-tabs {
    margin: 30px;
    padding-bottom: 10px;

    a {
      padding: 8px 19px;
      color: $gray;
      display: flex;
      font-size: 14px;
      border-bottom: 2px solid transparent;
      border-radius: 25px;
      transition: color 0.2s ease;
      position: relative;
      white-space: nowrap;
      box-shadow: 0 3px 6px rgba(50, 62, 100, 0.04);

      @media (max-width: 522px) {
        justify-content: center;
        display: flex;
      }

      img {
        height: 29px;
        width: auto;
        margin: 0 10px 0 0;
      }

      p {
        align-items: center;
        display: flex;
      }

      &.active {
        box-shadow: 0 3px 6px rgba(50, 62, 100, 0.2);
      }
    }
  }

  .temporary-tab {
    margin: 30px 0;
  }

  @media all and (max-width: 999px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &.signature-type {
    @media all and (max-width: 1000px) {
      padding: unset;
    }
  }

  #login-checkbox {
    a {
      text-decoration: underline;
      color: $gray;
      &:hover {
        color: $pacificBlue
      }
    }
  }

  .grid {
    margin-left: 0;
    margin-right: 0;
  }

  img.logo {
    display: block;
    margin: 0 10px 0 auto;
  }

  .login__logo {
    width: 130px;
    height: 52px;
    position: absolute;
    left: 55px;
    top: 30px;

    @media (max-width: 1000px) {
      left: 10px;
    }

    @media (max-width: 280px) {
      left: -8px;
    }
  }

  .login__container {
    background-color: $white;
    padding: 48px 0;
    margin: 32px auto 0 auto;
    max-width: 568px;
    position: relative;

    @media all and (max-width: 1000px) {
      padding-top: 60px;
      margin-top: 0;
    }

    @media all and (min-width: 1281px) {
      min-width: 566px;
    }

    &--signature-type {
      background-color: $white;
      margin: 32px auto 0 auto;
      max-width: 568px;
      position: relative;

      @media all and (min-width: 1281px) {
        min-width: unset;
      }
    }

    .confirm-phone-number {
      display: flex;
      justify-content: space-between;

      .css-26l3qy-menu {
        left: 0;
      }

      .form-number-code {
        width: 70px;
        min-width: 70px;
        padding-right: 0;
        border: 1px solid #EDEFF2;

        &:after {
          content: '';
          position: absolute;
          background: url("../../../web/illustration/chevron-down.svg");
          background-repeat: no-repeat;
          height: 10px;
          width: 13px;
          top: 17px;
          left: 8px;
        }

        div {
          &:first-child {
            border-right: none;
            border-radius: 0;
            border-color: #EDEFF2;
            justify-content: flex-end;
            padding-right: 0;
          }
        }
      }
    }

    h5 {
      font-weight: 500;
      margin-bottom: 30px;
      font-size: 20px;
    }

    .login-type {
      font-weight: 400;
      margin-bottom: 30px;
      text-align: center;
      a {
        color: $pacificBlue;
      }
    }

    #enter-email-address {
      color: $darkGray;
      margin-bottom: 10px;
    }

    #enter-phone-number {
      color: $darkGray;
      margin-top: 15px;
      margin-bottom: 10px;
    }

    #login-checkbox {
      font-weight: unset;
      margin-top: 20px;
    }
  }

  .signature-type-content {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;

      @media all and (max-width: 1000px) {
        padding: 0 8px;
        max-width: 320px;
        margin: auto;
      }

      .form-group {
        &:not(:first-child) {
          label {
            margin-top: 24px;
          }
        }
      }

      .btn--primary {
        margin-top: 32px;
      }
    }
  }

  .login-content {
    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 40px;
      padding: 0 156px;

      .btn--primary {
        &.loading {
          &::after {
            content: "";
            position: absolute;
            top: 20%;
            left: 48%;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 3px solid transparentize($white, 0.4);
            border-top: 3px solid $white;
            animation: spin 2s linear infinite;

            @keyframes spin {
              100% {
                transform: rotateZ(360deg);
              }
            }
          }
        }
      }

      @media all and (max-width: 1000px) {
        padding: 0 8px;
        max-width: 320px;
        margin: auto;
      }

      .form-group {
        &:not(:first-child) {
          label {
            margin-top: 24px;
          }
        }
      }

      .btn--primary {
        margin-top: 32px;
      }
    }

    p {
      margin-top: 20px;
      text-align: center;
      font-size: 14px;
    }
  }

  .login__user {
    padding: 16px 156px 24px 156px;

    @media all and (max-width: 1000px) {
      padding: 12px 8px 0 8px;
    }

    span {
      padding-bottom: 0;
    }

    ul {
      display: flex;
      flex-direction: column;
      width: 113%;
      align-items: center;
      max-height: 64vh;
      overflow-y: auto;

      li {
        &:last-child {
          .sidebar__user {
            border-bottom: 1px solid $mediumGray;
          }
        }

        .sidebar__user {
          padding: 12px 0;
          min-height: auto;
          width: 250px;

          @media all and (max-width: 1000px) {
            &::before {
              right: 0;
              font-size: 0.85em;
            }
          }
        }
      }
    }
  }

  @media all and (min-width: 1000px) {
    .lgi--min-width {
      min-width: 505px;
    }
  }

  .lgi--margin-0auto {
    margin: 0 auto;
  }

  .lgi--background {
    background-color: #f8c200;
  }

  .lgi--margin-20px {
    margin-top: 20px;
  }

  .lgi--padding-30px {
    padding-top: 30px;
  }

  .lgi--padding-50px {
    padding-top: 50px;
  }

  .faq-links {
    text-align: center;
    color: $pacificBlue;
    margin: 45px 0;

    a {
      color: $pacificBlue;
      text-decoration: underline;
    }
  }

  .zeal-faq-links {
    text-align: center;
    color: $pacificBlue;
    margin: 12px 0 -12px;

    a {
      color: $pacificBlue;
      text-decoration: underline;
    }
  }

  .popup__content {
    height: 80vh;
    overflow: hidden;

    .standard-title {
      margin: 0;
    }

    .loader {
      height: 100%;
    }
  }

  #zealIdIframe {
    border: none;
    width: 100%;
    height: 100%;
  }
}

.iframe-logo {
  position: absolute;
  top: 22px;
  width: 100%;
  text-align: center;

  .logo .logo__img {
    height: 80px;
    width: auto;
    margin: 0;
  }

  @media (max-width: 767px) {
    text-align: left;
    left: 10px;
  }
}