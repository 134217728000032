////////////////////////////////////////////////////////////////////////
// THEME SETUP
////////////////////////////////////////////////////////////////////////

// Fonts (Include fonts here)
$font-primary: "Gordita",
sans-serif;

// Font weights
$light: 300;
$regular: 400;
$bold: 700;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;

// Font sizes
$fs-xs: 0.75em;
$fs-sm: 0.875em;
$fs-base: 1em;
$fs-lg: 1.125em;
$fs-xl: 1.25em;
$fs-2xl: 1.5em;
$fs-3xl: 1.7em;

// Colors (Define theme main colors)
$black: #000000;
$color-primary: #121727;
$color-primary-opacity: rgba(18, 23, 39, 0.7);
$green: #54B86A;
$greenDark: #4DA762;
$greenLight: #54B86A6E;
$greenLightBorder: #82F39A4D;
$red: #FA635F;
$redError: #FA645FC7;
$tomato: #F77575;
$darkRed: #E5473E;
$lightRed:#F27A7A4D;
$lightRedBorder:#FFBDBD05;
$white: #FFFFFF;
$lightWhite: #FBFBFB;
$lightGray: #F7F9FC;
$mediumGray: #EDEFF2;
$gray: #99989A;
$darkGray: #737373;
$infoBlue: #0096c7;
$pacificBlue: #0096C7;
$pacificLight: #E6F5F9;
$pacificDark: #0087B3;
$primaryGray: #BBC0CE;
$dark: #091231;
$yellow: #F8C100;
$orange: #E09605;
$blue: #428FD6;
$lightBlue: #EBF7FB;
$violet: #4542D6;
$darkBlue: #263E6E;
$skyBlue:#6AADD9;
$whiteGray: #90909005;
$grayWhite: #828282;
$color-orange: #F09135;
$color-pale: #F9F9F9;
$color-pale-deep: #EDEEF1;

// Transitions (Define theme transitions)
$cubic: cubic-bezier(0.19, 1, 0.22, 1);

// Font sizes (Define theme font sizes)
$body-font-size: 14px;
$body-line-height: 1.6em;

// Viewports
$mq-mobile-stop: 360px;
$mq-mobile: 430px;
$mq-tablet: 1000px;
