.purpose {
  color: $gray;
  font-weight: 500;
  font-size: 0.85em;
  padding-left: 25px;
  position: relative;
  display: inline-block;

  &::before {
    font-family: "Glyphter";
    width: 15px;
    height: 20px;
    position: absolute;
    left: 0px;

    @include translateTop;

    text-align: center;
    line-height: 20px;
  }

  &--info {
    &::before {
      content: "F";
    }
  }
}
