.statusbar__parent {
  position: fixed;
  right: 16px;
  bottom: 6px;
  z-index: 102;
  max-height: 100vh;
  overflow: auto;

  & > div {
    display: flex;
    justify-content: end;
  }

  @media only screen and (max-width: 600px) {
    width: calc(100% - 32px);
  }
}

.statusbar {
  position: relative;
  padding: 16px 70px 16px 50px;
  font-weight: 500;
  font-size: 0.85em;
  color: $white;
  min-width: 440px;
  max-width: 70%;
  display: none;
  text-align: left;
  box-shadow: 0 0 12px #999;
  align-items: center;
  margin: 10px 10px 8px 10px;
  min-height: 74px;
  opacity: .85;
  border-radius: 3px;

  &::before {
    font-family: "Glyphter", serif;
    width: 20px;
    height: 20px;
    position: absolute;

    @include translateTop;

    left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }

  @media all and (max-width: 600px) {
    min-width: unset;
    max-width: unset;
    width: calc(100% - 22px);
  }

  &.statusbar--full-width {
    width: calc(100% - 50px);
    max-width: 100%;

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  &.js-active {
    display: flex;
  }

  &--success {
    background: $green;

    &::before {
      content: "O";
    }
  }

  &--warning {
    background: $red;

    &::before {
      content: "S";
    }
  }

  &--fading {
    opacity: 0;
    transition: opacity 2s linear;
  }

  .close {
    width: 50px;
    height: 50px;
    position: absolute;
    right: 0;
    top: 12px;
    cursor: pointer;

    &::before {
      content: "B";
      font-family: "Glyphter";
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
    }
  }

  &.statusbar--login-page {
    position: absolute;
    bottom: -66px;
    width: 100%;
    max-width: none;
    right: 0;

    @media all and (max-width: 1000px) {
      position: relative;
      bottom: unset;
      margin-top: 24px;
    }
  }
}
