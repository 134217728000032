.users-list {
  .user__status {
    display: flex;
    align-items: center;

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
    }

    p {
      padding-left: 8px;
      color: $primaryGray;
    }

    &--active {
      &::before {
        background-color: $green;
      }
    }

    &--pending {
      &::before {
        background-color: $primaryGray;
      }
    }
  }

  .table__head {
    border: none;
    padding: 0 24px;
  }

  .table__body {
    li {
      background-color: $white;
      padding: 0 9px 0 24px;
      margin-bottom: 16px;
      min-height: 64px;
      font-size: 1em;

      @media all and (max-width: 1100px) {
        // padding-right: 0
      }

      .table__body__col {
        a {
          &.unassign-license {
            &::before {
              margin-left: 5px;
              cursor: pointer;
              content: url("../../img/icons/close.svg");
            }
          }
        }

        &:first-child {
          .user__status {
            display: none;

            @media all and (max-width: 1000px) {
              display: flex;
              margin-left: 10px;
            }
          }

          @media all and (max-width: 1000px) {
            display: flex;
            align-items: center;
          }
        }

        @media all and (max-width: 1000px) {
          &--status {
            display: none;
          }
        }
      }

      @media all and (max-width: 1000px) {
        padding: 24px;

        .table__body__col:last-child {
          right: 20px;
        }
      }
    }
  }

  .status {
    font-size: 1em;
  }

  &--workspace {
    .table__head li:first-child, .table__body li .table__body__col:first-child {
      width: 25%;
    }

    @media all and (max-width: 1000px) {
      .table__body li {
        .table__body__col {
          &:not(:first-child) {
            padding-left: 46px;
          }

          &:nth-child(2) {
            margin-bottom: 15px;
          }

          .controls__btn--delete {
            position: absolute;
            font-size: 14px;
            top: 16px;
            right: 16px;
          }

          .controls__btn--quit {
            position: absolute;
            font-size: 14px;
            top: 16px;
            right: 16px;
          }

          &:last-child {
            position: static;
            transform: none;
            width: 100%;
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      .table__head li:first-child, .table__body li .table__body__col:first-child {
        width: 35%;
      }
    }
  }

  &--system-users {
    .table__head li:first-child, .table__body li .table__body__col:first-child {
      width: 15%;
    }

    @media all and (max-width: 1000px) {
      .table__body li {
        .table__body__col {
          &:not(:first-child) {
            padding-left: 46px;
          }

          &:nth-child(2) {
            margin-bottom: 15px;
          }

          &:last-child {
            position: static;
            transform: none;
            width: 100%;
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      .table__head li:first-child, .table__body li .table__body__col:first-child {
        width: 35%;
      }
    }
  }

  &--company {
    @media all and (max-width: 1100px) {
      .table__head li:first-child, .table__body li .table__body__col:first-child {
        width: 35%;
      }
    }

    @media all and (max-width: 1100px) {
      .table__head li:first-child, .table__body li .table__body__col:first-child {
        width: 25%;
      }

      .table__head li:nth-child(3), .table__body li .table__body__col:nth-child(3) {
        width: 15%;
      }
    }
  }

  @media all and (max-width: 1000px) {
    .table__body li {
      .table__body__col {
        &:not(:first-child) {
          font-size: 0.85em;
        }

        &:nth-child(2) {
          color: $gray;

          p {
            line-height: 1em;
          }
        }
      }
    }
  }

  .user__initials {
    @extend .sidebar__initials;

    margin-right: 14px;

    @media all and (max-width: 1000px) {
      top: 8px;
    }
  }
}

.user {
  display: flex;
  align-items: center;
}
