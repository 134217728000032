.controls-buttons {
  @include flexCenter;

  &--inline {
    display: inline;
  }

  .controls__btn {
    width: 65px;
    height: 50px;

    @media (max-width: 1000px) {
      width: 38px;
    }

    .icon {
      height: 30px;

      @media all and (max-width: 1000px) {
        height: 24px;
      }
    }

    .mobile { display: none }

    @media all and (max-width: 1000px) {
      .mobile { display: block }
      .desktop { display: none }
    }
  }

  &--small {
    .controls__btn {
      width: 30px;
      height: 30px;
    }
  }
}
