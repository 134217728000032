#pricing {
  overflow-x: hidden;
  .grid {
    @include breakpoint(md) {
      padding: 0;
    }
  }

  .pricing-table__row--head {
    label {
      height: 54px;
      padding: 0 42px;
      line-height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      text-align: inherit;

      @media (min-width: 1341px) and (max-width: 1515px) {
        height: 100px;
      }

      @media (min-width: 1281px) and (max-width: 1340px) {
        height: 130px;
      }

      @media (min-width: 1187px) and (max-width: 1280px) {
        height: 150px;
      }

      @media (min-width: 1000px) and (max-width: 1186px) {
        height: 200px;
      }
    }

    button {
      width: auto;
    }
  }

  @media (max-width: 1130px) {
    .btn {
      padding: 10px 15px;
    }
  }

  .spacer {
    margin-top: 93px;
  }

  .overflow-mobile {
    padding: 0 20px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 425px) {
      display: block;
    }

    @media (max-width: 768px) {
      margin-bottom: 80px;
    }

    p {
      color: $pacificBlue;
      margin-left: 12px;

      @media (max-width: 425px) {
        margin: 3% 38% 16% 0;
      }
    }
  }
}

.pricing-table__row--header {
  height: 22px;
  position: relative;
}

.swiper-wrapper {
  display: flex;
  margin: 98px 40px 11px 40px;
  padding: 0 0 22px 0;
  background: $white;
  box-shadow: (-2px) -1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 35px 35px 0 0;

  &:first-child {
    margin: 89px 0 6px 0;

    @include breakpoint(md) {
      margin: 49px 7px 6px 7px;
    }
  }

  @media (max-width: 1000px) {
    width: 400%;
  }

  .pricing-table__col {
    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4){
      i {
        color: $pacificBlue;

        &::before {
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17'><path stroke='%230096C7' stroke-linejoin='round' stroke-width='2' d='M8.60092 15.7332a7.06231 7.06231 0 0 0 2.71138-.5375 7.06268 7.06268 0 0 0 2.2972-1.5372 7.05888 7.05888 0 0 0 1.5372-2.2973 7.05796 7.05796 0 0 0 .5375-2.71133 7.06007 7.06007 0 0 0-.5375-2.71135 7.05954 7.05954 0 0 0-1.5372-2.29728 7.06123 7.06123 0 0 0-2.2972-1.53719 7.0614 7.0614 0 0 0-2.71138-.53752 7.0615 7.0615 0 0 0-5.00863 2.07471 7.0615 7.0615 0 0 0-2.07471 5.00863 7.06078 7.06078 0 0 0 .53752 2.71133 7.06059 7.06059 0 0 0 1.53719 2.2973 7.0627 7.0627 0 0 0 2.29728 1.5372 7.06194 7.06194 0 0 0 2.71135.5375Z' fill='none' /><path fill='none' stroke='%230096C7' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='m5.60107 8.77484 2.125 2.12496 3.87503-3.74996'/></svg>") no-repeat 50% 50%;
          width: 17px;
          height: 17px;
          color: transparent;
        }
      }

      &:before {
        content: "";
        position: absolute;
        height: 842px;
        width: 1px;
        background: $mediumGray;
        right: 0;

        @include breakpoint(md) {
          content: none;
        }

        @media (max-width: 1515px) {
          height: 882px;
        }

        @media (min-width: 1281px) and (max-width: 1340px) {
          height: 900px;
        }
      }
    }

    &:nth-child(2) {
      i {
        color: $pacificBlue;
      }
    }

    &:nth-child(4) {
      i {
        color: $pacificBlue;
      }
    }
  }

  .discount {
    color: #FFFFFF;
    background: $pacificBlue;
    border-radius: 10px;
    padding: 3px 16px;
    font-weight: 500;
    position: absolute;
    top: 12px;
  }

  .plan_name {
    margin: 49px 0 6px 0;
    font-weight: 600;
    font-size: 20px;
    color: black;
  }

  .price_container {
    height: 169px;

    .pricing-table__row {
      margin-top: 38px;

      div {
        &:nth-child(1) {
          margin-bottom: 7px;
        }
      }
    }
  }

  .pricing_page_container {
    height: 100px;

    .pricing-table__row {
      margin-top: 38px;

      div {
        &:nth-child(1) {
          margin-bottom: 7px;
        }
      }
    }
  }

  .pricing_action {
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 2px;
  }
}

.horizontal_line_blue {
  background: $pacificBlue;
  width: 45px;
  height: 2px;
  margin-bottom: 15px;
}

.horizontal_line_gray {
  background: $mediumGray;
  width: 200px;
  height: 1px;
  margin: 31px 0 12px 0;
}

.price_crossed {
  text-decoration: line-through;
  margin-right: 5px;
}

.price_with_discount {
  color: $pacificBlue;
  font-weight: 700;
  margin-right: 5px;
}

.discount_column {
  margin-top: 19px;

  span.discount_subscription_type {
    display: block;
  }
}

.pricing-table-show {
  height: 100%;
  padding: 40px;

  @include breakpoint(md) {
    margin: 43px 0 40px 0;
    padding: 8px;
  }
}

.pricing-table-hidden {
  height: 0 !important;
  padding: 0 !important;
}

.pricing-table {
  display: flex;
  margin: 0 20px;
  background: #FFFFFF;
  overflow: hidden;
  height: 100%;
  padding: 40px;

  @media (max-width: 1025px) {
    padding: 15px 5px;
    margin: 0;
  }

  .info-icon {
    margin-left: 5px;
    content: url('../../../assets/img/icons/info.svg');
  }

  .info-icon {
    @include breakpoint(md) {
      display: none;
    }
  }

  .pricing-table__col {
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(5) {
      i {
        color: $pacificBlue;

        &::before {
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17'><path stroke='%230096C7' stroke-linejoin='round' stroke-width='2' d='M8.60092 15.7332a7.06231 7.06231 0 0 0 2.71138-.5375 7.06268 7.06268 0 0 0 2.2972-1.5372 7.05888 7.05888 0 0 0 1.5372-2.2973 7.05796 7.05796 0 0 0 .5375-2.71133 7.06007 7.06007 0 0 0-.5375-2.71135 7.05954 7.05954 0 0 0-1.5372-2.29728 7.06123 7.06123 0 0 0-2.2972-1.53719 7.0614 7.0614 0 0 0-2.71138-.53752 7.0615 7.0615 0 0 0-5.00863 2.07471 7.0615 7.0615 0 0 0-2.07471 5.00863 7.06078 7.06078 0 0 0 .53752 2.71133 7.06059 7.06059 0 0 0 1.53719 2.2973 7.0627 7.0627 0 0 0 2.29728 1.5372 7.06194 7.06194 0 0 0 2.71135.5375Z' fill='none' /><path fill='none' stroke='%230096C7' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='m5.60107 8.77484 2.125 2.12496 3.87503-3.74996'/></svg>") no-repeat 50% 50%;
          width: 17px;
          height: 17px;
          color: transparent;
        }
      }
    }

    &:nth-child(4) {
      box-shadow: (-2px) -1px 10px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      margin: 0 20px;

      @include breakpoint(md) {
        margin: 0 5px;
      }

      i {
        color: $green;

        &::before {
          background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='17' height='17'><path stroke='%2354B86A' stroke-linejoin='round' stroke-width='2' d='M8.60092 15.7332a7.06231 7.06231 0 0 0 2.71138-.5375 7.06268 7.06268 0 0 0 2.2972-1.5372 7.05888 7.05888 0 0 0 1.5372-2.2973 7.05796 7.05796 0 0 0 .5375-2.71133 7.06007 7.06007 0 0 0-.5375-2.71135 7.05954 7.05954 0 0 0-1.5372-2.29728 7.06123 7.06123 0 0 0-2.2972-1.53719 7.0614 7.0614 0 0 0-2.71138-.53752 7.0615 7.0615 0 0 0-5.00863 2.07471 7.0615 7.0615 0 0 0-2.07471 5.00863 7.06078 7.06078 0 0 0 .53752 2.71133 7.06059 7.06059 0 0 0 1.53719 2.2973 7.0627 7.0627 0 0 0 2.29728 1.5372 7.06194 7.06194 0 0 0 2.71135.5375Z' fill='none' /><path fill='none' stroke='%2354B86A' stroke-linecap='square' stroke-linejoin='round' stroke-width='2' d='m5.60107 8.77484 2.125 2.12496 3.87503-3.74996'/></svg>");
          width: 17px;
          height: 17px;
          color: transparent;
        }
      }
    }

    @include breakpoint(md) {
      .icon-single {
        height: 70px;
      }
    }
  }

  label {
    strong {
      color: $pacificBlue;
      font-weight: 900;
    }
  }

  i {
    strong {
      font-weight: 500;
    }
  }

  .price_container {
    padding: 5% 0;
    text-align: left;

  }

  .price_column {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__col {
    height: 100%;
    width: 100%;
    border-bottom: none;
    border-right: none;
    position: relative;

    &:last-of-type {
      border-right: 0;
    }

    &--label {
      width: 120%;

      .pricing-table__row {
        label {
          justify-content: space-between;
          color: $color-primary;
          text-transform: none;
          font-weight: 500;
          font-size: 14px;
        }

        &--colspan {
          label {
            font-size: 1em;
          }
        }
      }
    }
  }

  &__row {
    border-bottom: none;

    &:last-of-type {
      border-bottom: 0;
    }

    label {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.71em;
      color: $darkGray;
      text-align: inherit;

      @include breakpoint(md) {
        height: 70px;
      }
    }

    &--colspan {
      height: 60px;
      position: relative;

      label {
        height: 60px;
        font-size: 1em;
      }
    }

    &--empty {
      height: 350px;
    }

    &--head {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top: none;
      text-align: center;

      p {

        @media all and (max-width: 1100px) {
          font-size: 1em;
        }
      }

      .btn {
        min-width: 130px;
      }
    }

    &--signatures {
      padding: 0 8px;
      height: auto;
      width: 50%;
      max-width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media (max-width: 800px) {
        max-width: 100%;
        width: 100%;
        margin: 24px 0;
      }
    }
  }

  &__sub-row {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &-col {
      line-height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      font-size: 0.9em;
    }

    &--btop {
      border-top: 1px solid $mediumGray;
    }
  }

  .price {
    margin-top: 9px;
    margin-bottom: 9px;
  }

  .price.price--strikethrough {
    margin-top: 0;
    margin-bottom: 2px;
  }

  .price.price--discount {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .price.price--strikethrough {
    font-size: 1.5em;
    margin-left: 6px;
  }

  &__green-text {
    color: #00a74f;
    font-weight: 500;
    margin-bottom: 14px;
  }

  &__info-box {
    max-width: 176px;
    margin-top: 30px;

    p {
      margin-top: 20px;
    }
  }

  &__green-text-start {
    color: #00a74f;
    font-weight: 500;
    margin-top: 28px;
    height: 35px;
  }
}

.tab-pricing-table {
  padding: 0;

  @media (max-width: $mq-mobile) {
    display: block;
  }
}

.price {
  font-size: 1.4em;
  margin-bottom: 19px;

  span {
    font-weight: 400;
    font-size: 14px;
  }

  sup {
    font-size: 0.5em;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
    display: inline-block;
  }
}

.pricing__best-value {
  position: absolute;
  width: 100%;
  height: 872px;
  top: -28px;
  left: -1px;
  box-shadow: (-2px) -1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0 0;
  background: linear-gradient(180deg, white 0%, white 7%, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0) 100%);

  @media (max-width: 1025px) {
    width: calc(100% + -12px);
    height: 870px;
  }

  @media (max-width: 1515px) {
    height: 911px;
  }

  @media (min-width: 1281px) and (max-width: 1340px) {
    height: 928px;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }

  span {
    position: absolute;
    padding: 6px 26px;
    color: #00a74f;
    border-radius: 14px;
    border: 1px solid #00a74f;
    background: #fff;
    top: 0;
    transform: translate(-50%, -50%);
    left: 50%;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;
    min-width: 172px;
  }
}

.pricing__best-value_lt {
  height: 912px !important;

  @media (max-width: 1515px) {
    height: 950px !important;
  }
}

.pricing__mobile {
  &__item {
    background: $white;
    box-shadow: 4px 0px 18px rgba(9, 18, 49, 0.02);
    padding: 24px;
    position: relative;
    margin-bottom: 27px;
  }

  &__heading {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: none;

    p {
      font-size: 1.28em;
    }
  }

  &__possibilities {
    padding-top: 8px;
    padding-bottom: 8px;
    border-bottom: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 40px;
    }
  }

  &--checks {
    padding-top: 24px;
    border-bottom: none;

    span {
      font-weight: 500;
    }

    ul {
      margin-top: 20px;
      padding-bottom: 20px;

      li {
        display: flex;
        align-items: center;

        .icon-single {
          width: 30px;
          height: 35px;
          justify-content: flex-start;
          font-size: 1em;
        }
      }
    }
  }

  &--control {
    padding-top: 24px;
    text-align: center;

    .btn {
      min-width: 125px;
    }
  }
}

.switch-button {
  background: #ececec;
  border-radius: 30px;
  width: 300px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  color: $darkGray;
  position: relative;
  padding-right: 120px;

  @media (max-width: 425px) {
    margin: auto;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
      transform: translateX(155px);
      transition: transform 300ms linear;
    }

    & + .switch-button-label {
      position: relative;
      padding: 11px 0;
      display: block;
      user-select: none;
      pointer-events: none;

      &:before {
        content: "";
        background: $white;
        height: 100%;
        width: 82%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 30px;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.26);
        transform: translateX(0);
        transition: transform 300ms;
      }

      .switch-button-label-span {
        position: relative;
        font-size: 16px;
        right: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }

      .switch-button-monthly {
        color: #0096c7;
        position: absolute;
        right: -118px;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        top: 0;
        bottom: 0;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 3;
        pointer-events: none;
      }
    }
  }
}

.pricing_description {
  position: relative;
  max-width: 200px;
  text-align: initial;
  padding-right: 10px;

  div {
    margin-bottom: 22px;
    display: flex;

    .icon-single {
      height: 17px;
      width: initial;
      padding: 0px 10px 0px 10px;
    }
  }
}

.header_undertext {
  color: $gray;
  font-size: 17px;
  font-weight: 500;
  margin-left: 20px;
  position: relative;
  top: 37px;

  @media (max-width: 1362px) {
    top: 0;
    margin-bottom: 24px;
  }
}

.swiper-pagination {
  position: relative;
  text-align: center;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  margin: 53px 16px;
  background: $white;
  border: $darkGray 2px solid;
}

.swiper-pagination-bullet-active {
  background: $darkGray;
}

.swiper-container {
  overflow: initial;
}

.border_bottom_padding {
  border-bottom: solid 1px $mediumGray;
  padding-bottom: 5px;
}

.border_bottom-margin_auto {
  border-bottom: solid 1px $mediumGray;
  width: 80%;
  margin: auto;
  padding-bottom: 5px;
}

.pricing-table_header-between {
  margin-bottom: 20px;
  h2 {
    position: relative;
    font-size: 20px;
    margin-top: 39px;
    margin-bottom: 6px;
  }

  i {
    height: 16px;
    margin-top: 12px;
    color: $black;
    font-size: 23px;
  }

  &:hover {
    div, i {
      cursor: pointer;
      color: $pacificBlue;
    }
  }
}

#faq-pricing {
  background: $lightGray;
  height: 800px;

  @include breakpoint(md) {
    height: auto;
  }


  ul {
    padding: 15px;

    li {
      margin-bottom: 10px;
      padding-left: 25px;

      i {
        position: relative;
        height: 0;
        right: 53%;
        top: 9px;
      }
    }
  }

  .faq__content {
    margin-left: 30px;

    a {
      text-decoration: underline;

      &:hover {
        color: $pacificBlue;
      }
    }

    @include breakpoint(md) {
      margin: 24px auto 42px auto;
      max-width: 572px;
      padding-left: 4px;
    }
  }

  h2 {
    font-size: 36px;
    margin: 24px 30px 42px 0;
    line-height: 47px;
  }

  .accordion-container {
    position: relative;
    max-width: 569px;
    height: auto;
    margin: 10px auto;
  }

  > h2 {
    text-align: center;
    color: #fff;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
}

.content {
  border-bottom: 1px solid #ddd;
  display: none;

  p {
    padding: 10px 15px;
    margin: 0;
    color: #333;
  }
}

.rotate_180 {
  transform: rotate(180deg);
  transition: 200ms ease;
}

.rotate_to_0 {
  transform: rotate(0);
  transition: 200ms ease;
}

.fade_in {
  opacity: 1;
  transition: 200ms ease opacity;
}

.fade_out {
  opacity: 0;
  transition: 200ms ease opacity;
}

#pricing_nav {
  position: fixed;
  width: 100%;
  height: 282px;
  background: $lightGray;
  z-index: 10;

  @include breakpoint(md) {
    height: 147px;
  }

  .pricing-table__row--head {
    height: 282px;

    @include breakpoint(md) {
      display: none;
    }
  }

  .mobile_table--head {
    display: none;

    @media (max-width: 1025px) {
      display: block;
      position: absolute;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      bottom: 5%;
      font-weight: 500;
      left: 43%;

      @media (max-width: 426px) {
        left: 40%;
      }

      @media (max-width: 376px) {
        left: 32%;
      }
    }
  }

  .pricing-table__col {
    box-shadow: (-2px) -1px 10px rgba(0, 0, 0, 0.1);

    @include breakpoint(md) {
      box-shadow: none;
    }

    &:nth-child(2) {
      .price {
        margin: 0;
      }

      .price_column {
        height: 27px;
      }
    }

    &:nth-child(4) {
      border-radius: 0;
      margin: 0;
    }

    &:nth-child(5) {
      margin-right: 13px;

      @media (max-width: 1025px) {
        margin-right: 0;
      }

      .btn {
        margin-top: 34px;
      }
    }
  }

  .pricing-table {
    height: 282px;
    margin: 0 40px;
  }

  .pricing-table__col--label {
    width: 123%;
    box-shadow: none;

    @media (max-width: 1025px) {
      width: 114%;
    }

    @media (max-width: 426px) {
      width: 130%;
    }

    @media (max-width: 376px) {
      width: 154%;
    }

    @media (max-width: 321px) {
      width: 192%;
    }
  }

  .btn {
    margin-top: 18px;
  }

  .pricing-table {
    background: transparent;

    @media (max-width: 1025px) {
      height: 147px;
      margin: 0;
    }
  }

  .pricing_nav-content {
    display: flex;

    .pricing_nav-card_body {
      width: 25%;
      height: 282px;

      @include breakpoint(md) {
        height: 147px;
      }
    }
  }

  .popular {
    position: absolute;
    padding: 6px 26px;
    color: #00a74f;
    border-radius: 14px;
    border: 1px solid #00a74f;
    top: 27px;
    transform: translate(-50%, -50%);
    left: 49.8%;
    font-size: 16px;
    font-weight: 500;
    z-index: 2;

    @include breakpoint(md) {
      display: none;
    }
  }

  .plan_name {
    color: black;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .price {
    color: black;
    font-weight: 500;
  }

  .grid {
    @include breakpoint(md) {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .btn {
    padding: 10px 20px;
  }
}

.display_nav {
  top: 0;
  transition: 300ms ease-in top;
}

.hide_nav {
  top: -282px;
  transition: 300ms ease-out top;
}

.pricing-table__modal {
  .pricing-table__row-heading {
    font-size: 1.28em;
    line-height: 1.6em;
    text-align: left;
    font-weight: 500;
    color: $color-primary;
  }

  .pricing-table__modal-content {
    position: relative;
    display: flex;
    width: auto;
    margin: auto;
    background: $white;

    @media (max-width: 800px) {
      display: block;
      width: 330px;
    }
  }

  .pricing-table__modal-img {
    display: flex;
    align-items: center;
    height: auto;
    width: 50%;
    max-width: 50%;
    padding: 0 8px;

    @media (max-width: 800px) {
      margin: 0;
      width: 100%;
      max-width: 100%;
    }

    img {
      width: 100%;
      height: 76%;

      @media (max-width: 800px) {
        padding: 15px;
      }
    }
  }

  .pricing-table__sub-rows {
    margin: 9px 0;
    max-width: 100%;
  }

  .pricing-table__sub-row {
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .pricing-table__sub-row-col {
    line-height: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
  }

  .pricing-table__sub-row .pricing-table__sub-row-col {
    &:first-child {
      justify-content: left;
      width: 66.6%;
    }

    &:last-child {
      width: 33.3%;
    }
  }

  .input-minus-plus {
    display: flex;

    button {
      padding: 0 10px;
      background-color: transparent;
    }

    input[type=number] {
      margin: 0;
      padding: 0;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      max-width: 100px;
      min-width: 45px;
    }
  }
}

.pricing-add {
  background: $green;
  padding: 10px 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    background: $green;
    height: 100%;
    width: 20px;
    left: -20px;
    top: 0;
  }

  p {
    color: $white;
    font-size: 18px;
    text-align: center;

    @media (max-width: 1025px) {
      padding: 0;
    }

    span {
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}

.tooltip-new {
  color: $white;
  background: $green;
  padding: 5px 7px;
  font-size: 7px;
  border-radius: 25px;
}

.christmas-add {
  margin: 40px 0 12px;
  background: $green;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  position: relative;
  overflow: hidden;

  @media (max-width: 1000px){
    margin: 40px 0 80px;
  }

  @media (max-width: 768px){
    margin: 40px 0 110px;
  }

  p {
    color: $white;

    a {
      color: $white;
      text-decoration: underline;
    }
  }
}

.double-btn {
  background: #F1F6FA;
  margin-top: 9px;
  padding: 12px 3px;
  border-radius: 18px;

  a {
    color: #16275C;
    font-size: 12px;
    font-weight: bold;
    padding: 10px;
    border-radius: 18px;
    cursor: pointer;
  }

  a.active {
    background: white;
  }
}

.double-pricing-helper {
  margin-top: 9px;

  p {
    font-size: 12px;
    text-align: center;
  }
}
