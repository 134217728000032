.additional-settings {
  .wrapped-section__headline {
    margin-bottom: 20px;
  }

  .wrapped-section {
    .info-details {
      li {
        min-height: 46px;
      }
    }
  }

  & .form-group {
    label {
      font-size: 14px;
      font-weight: initial;
      margin-bottom: 0;
    }

    &__checkbox {
      display: flex;
      align-items: center;

      & label {
        font-weight: unset;
      }
    }

    &__select {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-flow: row wrap;
      gap: 5px;

      &-wrapper {
        width: 250px;
      }

      &__single-value {
        position: relative;
        top: unset;
        transform: unset;
        overflow: unset;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__input {
        color: transparent;
      }
    }
  }
}