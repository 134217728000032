@keyframes heightUp {
  0%    {height: 0}
  50%   {height: 225px}
  100%  {height: 450px}
}

@keyframes heightDown {
  0%    {height: 450px}
  50%   {height: 225px}
  100%  {height: 0}
}

@keyframes heightUpPlatform {
  0%    {height: 0}
  50%   {height: 400px}
  100%  {height: 800px}
}

@keyframes heightDownPlatform {
  0%    {height: 800px}
  50%   {height: 400px}
  100%  {height: 0}
}

@media (max-width: 1000px) {
  @keyframes heightUpPlatform {
    0%    {height: 0}
    50%   {height: 210px}
    100%  {height: 420px}
  }

  @keyframes heightDownPlatform {
    0%    {height: 420px}
    50%   {height: 210px}
    100%  {height: 0}
  }
}

.usb-documents {
  padding: 0;

  #header {
    padding-bottom: 48px;

    .grid {
      padding-left: 0;
    }
  }

  .headline {
    color: $black;

    @media (max-width: 1000px) {
      display: block;
      text-align: center;
    }
  }

  h2 {
    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  .page-title {
    @media (max-width: 1000px) {
      text-align: center;
    }
  }

  &__documents {
    .mobile-document-status {
      margin: 0 12px;
      padding: 24px 24px 24px 16px;
      background: $white;
      border-radius: 0 0 8px 8px;
    }
  }

  &__document {
    background: $white;
    border-radius: 8px;
    margin: 24px 0;
    overflow: hidden;

    @media (max-width: 1000px) {
      margin: 24px 12px 0;
      padding: 0;
    }

    .review-download-document {
      position: relative;
      border: none;
      background: $white;
      padding: 24px 24px 24px 45px;
      color: $pacificBlue;
      width: 100%;
      border-top: 1px solid $mediumGray;
      border-bottom: 1px solid $mediumGray;
      text-align: start;
      text-decoration: underline;

      &::before {
        font-family: "Glyphter", sans-serif;
        content: "X";
        font-size: 20px;
        left: 12px;
        top: 21px;
        position: absolute;
      }
    }

    &--header {
      @include fleXCenterBetween;

      padding: 24px;

      @media (max-width: 1000px) {
        justify-content: initial;
        padding: 26px 12px;
      }

      &__info {
        display: flex;
        align-items: center;

        @media (max-width: 1000px) {
          width: 100%;
        }
      }

      .document--header{
        &__info--img {
          img {
            height: 32px;
          }
        }

        &__document-info {
          margin-left: 24px;

          @media (max-width: 1000px) {
            width: 100%;
          }

          &--document-title {
            margin-bottom: 12px;
          }

          &--date {
            color: $gray;
          }
        }
      }

      &__controls {
        display: flex;
        z-index: 1;

        button {
          position: relative;
          color: $gray;
          margin: 0 24px;
          cursor: pointer;
          background: transparent;
          border: none;

          &:hover {
            color: $pacificBlue;
          }
        }

        div {
          position: relative;
          color: $gray;
          margin: 0 24px;
        }
      }
    }

    .tabs-content {
      overflow-y: auto;
      max-height: 290px;
    }
  }

  &__document-preview {
    .preview__document-content {
      padding: 0;
      overflow: scroll;
      flex: 1;
      max-height: 50%;
    }
  }

  &__document-info-tabs {
    .usb-tab-list {
      position: relative;
    }

    .grid {
      padding: 0;

      .tabs-mobile-overlay {
        @media (min-width: 1000px) {
          display: none;
        }
      }
    }

    .tabs-list {
      justify-content: space-between;
      border: none;

      .login-tabs {
        width: 100%;
        text-align: center;
        margin: 0;

        @media(max-width: 1000px) {
          min-width: 160px;
        }

        a {
          justify-content: center;
          background: $mediumGray;
          display: flex;
          align-items: center;

          img {
            height: 20px;
            filter: opacity(0.4);
            margin-right: 12px;
          }

          p {
            font-weight: 500;
            font-size: 16px;
            color: $gray;
          }
        }

        a.active {
          border: none;
          background: $white;
        }
      }
    }
  }

  .usb-document-information {
    padding: 24px 24px 12px 24px;
    font-size: 14px;

    &__title {
      color: $gray;
    }

    &__title,
    &__name {
      margin-bottom: 8px;
    }

    li {
      margin-bottom: 12px;
    }

    li:not(:nth-last-of-type(1)) {
      border-bottom: 2px solid $mediumGray;
    }
  }

  .usb-document-members {
    padding: 24px 24px 12px 24px;

    ul {
      span {
        margin-bottom: 8px;
      }

      li:not(:nth-last-of-type(1)) {
        margin-bottom: 12px;
        border-bottom: 2px solid $mediumGray;
      }

      li {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
      }
    }
  }

  .usb-document-history {
    .wrapped-section {
      padding: 0;
      margin-bottom: 0;

      .info-details {
        li {
          padding: 18px 0;
        }

        &__history-list {
          overflow: visible;
        }
      }

      .info-details__history-list {
        width: 40%;

        @media (max-width: 1000px) {
          width: 100%;
        }
      }
    }
  }

  .usb-document-comments {
    padding: 24px 24px 12px 24px;

    li {
      margin-bottom: 18px;
    }

    &__date {
      color: $gray;
      margin-bottom: 8px;
    }

    &__commenters-name {
      margin-bottom: 24px;
    }

    &__comment-text {
      margin-bottom: 18px;
    }

    li:not(:nth-last-of-type(1)) {
      border-bottom: 2px solid $mediumGray;
    }
  }

  .usb-document-information__name,
  .usb-document-members__name,
  .usb-document-comments__commenters-name {
    font-weight: 500;
  }

  &__signing-tabs {
    margin: 24px 0;

    .tabs-list {
      li {
        margin: 0 7px;
      }
    }

    @media (max-width: 1000px) {
      margin: 24px 12px;
    }
  }

  .usb-documents__document--body {
    padding: 0 24px 0 24px;
    .preview__document-content {
      margin: 0;
    }
  }

  .usb-documents__document-preview {
    display: flex;
    flex-direction: column;
  }

  .open {
    &__icon {
      transform: rotate(180deg);
      transition-property: transform;
      transition-duration: 150ms;
    }

    .usb-documents__document-preview {
      height: 675px;
      animation: heightUp 150ms ease-in-out;
    }

    .usb-documents__document--body {
      .usb-documents__document-preview {
        animation: none;
      }
      overflow: visible;
      animation: heightUpPlatform 150ms ease-in-out;
    }
  }

  .closed {
    &__icon {
      transform: rotate(0deg);
      transition-property: transform;
      transition-duration: 150ms;
    }

    .usb-documents__document-preview {
      height: 0;
      animation: heightDown 150ms ease-in-out;
    }

    .usb-documents__document--body {
      .usb-documents__document-preview {
        animation: none;
      }
      height: 0;
      overflow: hidden;
      animation: heightDownPlatform 150ms ease-in-out;
    }
  }

  .usb-document-information-bar {
    margin: 24px 0 42px;
    padding: 16px;
    display: flex;
    border-radius: 8px;
    border: 1px solid $gray;

    @media (max-width: 1000px) {
      align-items: flex-start;
    }

    p {
      padding-left: 18px;
    }
  }

  .modal-sign-usb {
    &__header {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid $lightGray;
      margin: auto;
      max-width: 340px;

      img {
        padding-left: 24px;
      }
    }

    &__form {
      max-width: 300px;
      margin: auto;

      label {
        margin: 30px 0 3px 0;
      }

      .form-group__input {
        height: 40px;
      }

      .btn {
        margin: 35px 0;
        width: 100%;
        justify-content: center;
      }
    }

    &__info {
      max-width: 340px;
      margin: auto;
      text-align: center;
    }
  }

  &__information {
    margin: 54px 0 24px;

    @media (max-width: 1000px) {
      margin: 54px 12px 24px;
    }
  }

  .usb-signing {
    &__button {
      margin: 24px 0;
    }
  }

  .form-btn {
    margin-left: -45px;
    padding-top: 5%;
  }

  .usb-documents__document-info-tabs {
    flex: 1;
    max-height: 50%;
  }
}

.button {
  &--download {
    &::before {
      font-family: "Glyphter", sans-serif;
      content: "D";
      font-size: 18px;
      left: -21px;
      top: -2px;
      position: absolute;
    }
  }

  &--reviewed {
    color: $green !important;
    transition-property: color;
    transition-duration: .3s;

    &::before {
      font-family: "Glyphter", sans-serif;
      content: "a";
      font-size: 16px;
      font-weight: 600;
      left: -27px;
      top: -2px;
      position: absolute;
    }
  }

  &--unreviewed {
    &::before {
      font-family: "Glyphter", sans-serif;
      content: "X";
      font-size: 20px;
      left: -27px;
      top: -2px;
      position: absolute;
    }
  }
}

.temporary-header {
  padding: 12px 0;
}

.temporary-document {
  padding: 12px 24px 12px 24px;
}

.iframe-bulk-documents {
  background: #F7F9FC;
  padding-top: 20px;
}

.iframe-bulk-documents-headline {
  display: block;

  h2 {
    font-size: 1em !important;
  }
}

.iframe-bulk-documents-tab {
  margin-left: 24px;
  margin-right: 24px;
}

.usb-documents__document--body.iframe {
  @media (max-width: 1000px) {
    height: 820px !important;
  }

  @media (max-width: 390px) {
    height: 363px !important;
  }
}