.information-message {
  display: flex;
  background: $white;
  min-height: 48px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 24px;

  .massage-icon {
    width: 70px;
    opacity: .8;

    @include fleXCenterCenter;

    @media (max-width: 1000px) {
      display: flex;
      align-items: flex-start;
      padding-top: 12px;
    }

    img {
      width: 37%
    }
  }

  .massage-text {
    border-left: none;
    height: 100%;
    width: 100%;
    border-radius: 0 8px 8px 0;
    padding: 18px 24px;
    display: flex;
    align-items: center;

    @include flexCenter;
  }

  &_bottom-mobile-bar {
    border-radius: 8px;
    min-height: initial;
    margin-bottom: 25px;

    @media (max-width: 430px) and (max-width: 500px) {
      margin-bottom: 15px;
    }

    @media (min-width: $mq-mobile-stop) and (max-width: $mq-mobile) {
      margin-bottom: 5px;
    }

    @media (min-width: 431px) {
      margin-left: 16px;
    }

    .massage-text {
      height: 100%;
      width: 100%;

      display: flex;
      align-items: center;

      font-size: 0.9em;
      padding: 11px 24px;

      @media (max-width: 1100px) {
        font-size: 0.8em;
        padding: 13px 15px;
      }

      @media (max-width: 1000px) {
        font-size: 0.8em;
        padding: 10px 5px;
      }

      @media (max-width: 800px) {
        font-size: 0.75em;
        padding: 10px 5px;
      }

      @media (max-width: 430px) and (max-width: 500px) {
        font-size: 0.75em;
        padding: 3px 5px;
      }

      @media (min-width: $mq-mobile-stop) and (max-width: $mq-mobile) {
        font-size: 0.75em;
        padding: 10px 5px;
      }

      @include flexCenter;
    }
  }

  .no-icon {
    border-radius: 8px;
  }

  &__error {
    .massage-icon {
      background: $red;
    }

    .massage-text {
      border: 1px solid rgba(250, 99, 95, .8);
    }
  }

  &__warning {
    border: 1px solid $orange;

    .massage-icon {
      background: $orange;

      @media (max-width: $mq-tablet) {
        display: flex;
        align-items: flex-start;
        padding-top: 7px;
      }

      @media (min-width: $mq-mobile) and (max-width: 530px) {
        padding-top: 13px;
      }

      @media (min-width: $mq-mobile-stop) and (max-width: $mq-mobile) {
        padding-top: 6px;
      }
    }
  }

  &__success {
    .massage-icon {
      background: $green;
    }

    .massage-text {
      border: 1px solid $green;
    }
  }

  &__info {
    .massage-icon {
      background: $infoBlue;
    }

    .massage-text {
      border: 1px solid $mediumGray;
    }
  }
}
