#sidebar {
  background: $white;
  box-shadow: 4px 0 18px rgba(9, 18, 49, 0.02);
  position: fixed;
  width: 320px;
  height: 100%;
  left: 0;
  top: 0;
  font-weight: 500;
  transition: width 0.3s ease-in-out;
  z-index: 99;

  @media print {
    display: none;
  }

  .sidebar__content {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media all and (max-height: 600px) {
      overflow-y: auto;
    }

    .sidebar__top {
      .sidebar__user-logo {
        height: 117px;
      }

      .sidebar__user {
        margin: 0 12px 0 20px;
        padding: 34px 12px 0 8px;
      }
    }
  }

  @media all and (max-width: 1000px) {
    display: none;
  }

  .shrink-hide {
    transition: opacity 0.1s 200ms, visibility 0.1s 200ms, font-size 0s 200ms;
  }

  &.shrink {
    width: 85px;

    .shrink-hide {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0s, visibility 0s, font-size 0s;
      font-size: 0;
    }

    .sidebar__logo {
      width: 54px;
    }

    .sidebar__user-logo {
      height: 64px !important;
    }

    .sidebar__user:not(.sidebar__user--disabled)::before {
      right: 0;
    }
  }

  .sidebar__user-information {
    cursor: pointer;
  }
}

.sidebar__logo {
  text-align: center;
  padding-top: 22px;
  transition: width 0.3s ease;
  overflow: hidden;

  img {
    width: auto;
    height: auto;
    max-height: 86px;
    max-width: 240px;

    @media (max-width: 1000px) {
      height: 100%;
    }
  }
}

.sidebar__menu-item {
  height: 68px;
  display: flex;
  align-items: center;
  padding-left: 80px;
  border-left: 2px solid transparent;
  color: $gray;
  font-weight: 500;
  position: relative;

  @media (max-height: 810px) {
    height: 60px;
  }

  &::before {
    width: 21px;
    height: 22px;
    font-size: 21px;
    position: absolute;

    @include translateTop;

    left: 25px;
    color: $gray;
    font-family: "Glyphter";
  }

  &--messenger {
    cursor: pointer;
    &::before {
      content: "m";
    }
  }

  &--pricing {
    &::before {
      content: "c";
    }
  }

  &--global-signatures {
    &::before {
      content: "o";
    }
  }

  &--documents {
    &::before {
      content: "C";
    }
  }

  &--validate-document {
    &::before {
      content: "R";
    }
  }

  &--settings {
    &::before {
      content: "M";
    }
  }

  &--logout {
    &::before {
      content: "G";
    }
  }

  &--faq {
    &::before {
      content: "d";
    }
  }

  &--lang-switch {
    img {
      display: none;
    }

    &::before {
      content: "i";
    }
  }

  &--active,
  &:hover {
    background: $lightBlue;
    color: $pacificBlue;
    border-color: $pacificBlue;

    &::before {
      color: $pacificBlue;
    }
  }
}

.sidebar__top {
  .sidebar__user {
    padding: 25px 27px 25px 32px;
  }

  .sidebar__user-list {
    padding: 16px 16px 0 16px;

    .sidebar__user-item {
      display: flex;
      align-items: center;
      padding-bottom: 20px;

      .sidebar__users-logo {
        margin-right: 10px;
        height: auto;
      }

      span {
        padding: 0;
      }
    }
  }

  &.js-show {
    .js-sidebar-more-users {
      display: block;

      @media (min-width: 1000px) {
        visibility: visible;
      }
    }
  }
}

.user__logo {
  margin-right: 10px;
}

#js-sidebar-more-users {
  @media (min-width: 1000px) {
    padding-top: 0;

    ul {
      max-height: calc(100vh - 62px);
      overflow: auto;
    }
  }
}

.sidebar__language {
  width: 100%;

  &.js-show {
    .language--list {
      display: block;
    }
  }

  .language {
    width: 100%;
    padding-left: 80px;
    color: $gray;
    position: relative;
    transition: background 0.2s ease, color 0.2s ease;

    &:hover {
      background: $pacificLight;
      color: $pacificBlue !important;
    }

    img {
      width: 16px;
      height: 12px;
      position: absolute;
      left: 25px;

      @include translateTop;
    }

    &--current {
      height: 64px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      padding-left: 0;
      cursor: pointer;

      &::before {
        content: "A";
        width: 15px;
        height: 15px;
        font-family: "Glyphter";
        position: absolute;
        right: 20px;
        top: 50%;
        line-height: 15px;
        transform: rotate(90deg) translateY(-50%);
        transform-origin: top;
        transition: opacity 0.1s ease 200ms, visibility 0.1s ease 200ms;
        z-index: 5;

        &:hover {
          background: $pacificLight;
          color: $pacificBlue !important;
        }
      }

      .shrink & {
        &::before {
          opacity: 0;
          visibility: hidden;
          transition: opacity 0s ease, visibility 0s ease;
        }
      }
    }

    &--list {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 150px;
      background: $white;

      @include wideShadow;
      padding-top: 10px;
      z-index: 10;
      display: none;

      .shrink & {
        width: 100%;
        left: 0;
      }

      .language {
        height: 40px;
        display: flex;
        align-items: center;
        margin: 6px 0;
      }
    }
  }
}

.sidebar__user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 25px 12px;
  position: relative;

  &--disabled {
    cursor: default;

    .sidebar__initials {
      opacity: 0.4;
    }
  }

  &:not(&--disabled)::before {
    content: "A";
    font-family: "Glyphter";
    width: 15px;
    height: 15px;
    position: absolute;

    @include translateTop;

    text-align: center;
    line-height: 15px;
    right: 10px;
    transition: transform 0.3s ease;

    @include breakpoint(md) {
      transform: translateY(0);
      top: 37%;
    }
  }

  &:hover {
    &::before {
      transform: translateY(-50%) translateX(5px);

      @include breakpoint(md) {
        transform: rotateY(180deg);
      }
    }
  }

  &.js-show {}
}

.sidebar__initials {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  color: $white;
  position: relative;

  .overlay {
    background: $pacificBlue;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
  }
}

.sidebar__user-info {
  width: calc(100% - 50px);
  line-height: 1.14em;

  span {
    font-weight: 500;
    display: block;
    word-wrap: break-word;
    max-width: 90%;
    padding-bottom: 17px;
  }

  p {
    color: $gray;
    display: block;
    line-height: 1.14em;
    font-weight: 400;
    margin-bottom: 1px;
  }

  &>p:last-child {
    padding-bottom: 17px;
    border-bottom: 1px solid $mediumGray;
    margin-top: 16px;
    margin-bottom: 17px;
  }

  .sidebar__user-plan-info {
    @media (min-width: 1000px) {
      padding-bottom: 17px;
      margin-bottom: 17px;
      border-bottom: 1px solid $mediumGray;

      p {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

.js-show {
  .sidebar__user--more-users {
    @media (max-width: 1000px) {
      visibility: visible;
    }

    .sidebar__user-info {
      span {
        padding-bottom: 0;
      }
    }

    ul {
      @media (max-width: 1000px) {
        overflow: scroll;
        max-height: 75vh;
      }
    }
  }
}

.sidebar__user--more-users {
  width: 240px;
  position: absolute;
  top: 0;
  right: -240px;
  background: $white;
  border-radius: 0 14px 14px 0;

  @include wideShadow;

  visibility: hidden;
  padding-top: 10px;
  z-index: 10;

  @media (min-width: 1000px) {
    display: block;
  }

  @media all and (max-width: 1000px) {
    position: fixed;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 32px);
    padding-top: 40px;
    border-radius: 14px;
  }

  &.js-show {
    display: block;
  }

  &.stick-bottom {
    bottom: 64px;
    top: inherit;
  }

  .sidebar__user-info span {
    color: $gray;
    transition: color 0.2s ease;
  }

  .sidebar__user {
    padding-top: 12px;
    padding-bottom: 12px;
    border: 0;

    &::before {
      content: none;
    }

    &:not(&--disabled):hover {
      .sidebar__user-info {
        span {
          color: $pacificBlue;
        }
      }
    }

    &--active {
      .sidebar__user-info {}

      span {
        color: $pacificBlue;
      }

      .sidebar__initials {
        &::before {
          content: "";
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 2px solid $pacificBlue;
          position: absolute;
          left: -4px;

          @include translateTop;
        }

        &::after {
          content: "a";
          width: 15px;
          height: 15px;
          position: absolute;
          font-family: "Glyphter";
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
        }

        .overlay {
          opacity: 0.6;
        }
      }
    }

    &--disabled {
      cursor: default;

      .sidebar__initials {
        opacity: 0.4;
      }
    }
  }
}

.sidebar__user-new {
  border-top: 1px solid $mediumGray;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85em;
}

#user__add-new {
  color: $pacificBlue;
  padding: 8px 0 8px 25px;
  position: relative;
  font-weight: 500;

  &::before {
    content: "J";
    font-family: "Glyphter";
    position: absolute;
    left: 0;
    width: 15px;
    height: 15px;
    font-size: 15px;

    @include translateTop;

    @media (min-width: 1000px) {
      top: 44%;
    }
  }
}

#user__view-all {
  padding: 8px 0;
  color: $gray;
}

#shrink-sidebar {
  width: 30px;
  height: 60px;
  position: absolute;
  right: -25px;

  @include translateTop;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    width: 3px;
    height: 12px;
    display: inline-block;
    background: $pacificBlue;
    position: absolute;
    transition: transform 0.3s ease, top 0.3s ease;
  }

  &::before {
    top: 18px;
  }

  &::after {
    top: 30px;
  }

  &:hover {
    &::before {
      transform: rotate(45deg) translateY(3px);
    }

    // top: 20px
    &::after {
      transform: rotate(-45deg) translateY(-3px);
    }
  }

  // top: 28px

  .shrink & {
    &:hover {
      &::before {
        transform: rotate(-45deg) translateY(3px);
      }

      &::after {
        transform: rotate(45deg) translateY(-3px);
      }
    }
  }
}

.sidebar__upgrade {
  padding: 24px 24px;
  transition: padding 0.1s ease 200ms;
  height: 100%;
  display: flex;
  align-items: flex-end;

  @media (max-width: 1000px) {
    height: initial;
  }

  .btn {
    width: 100%;
    max-width: 400px;
    text-align: center;
    transition: padding 0.1s ease 200ms, border 0.1s ease 200ms, font-size 0.1s ease 200ms, background 0.2s ease, color 0.2s ease;

    &--secondary {
      &:hover {
        background: $pacificBlue;
        color: $white;
      }
    }
  }

  .shrink & {
    padding: 0;
    transition: padding 0s ease;

    .btn {
      padding: 29px 0;
      border: 0;
      font-size: 0.85em;
      transition: padding 0s ease, border 0s ease, font-size 0s ease;
      border-radius: 0;

      &:hover {
        background: $pacificLight;
        color: $pacificBlue;
      }
    }
  }

  &--start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    a {
      margin-bottom: 10px;
    }
  }
}

.sidebar__upgrade.start {
  flex-direction: column;

  .btn--green {
    margin-bottom: 5px;
  }
}

iframe[data-testid="bubble_iframe"] {
  @media (max-width: 1000px) {
    display: none !important;
  }
}