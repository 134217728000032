@media all and (min-width: 1001px) {
  .show-tablet {
    display: none !important;
  }
}

@media all and (max-width: 1000px) {
  .hide-tablet {
    display: none !important;
  }
}

@media all and (min-width: 1076px) {
  .show-lt-1076px {
    display: none !important;
  }
}

@media all and (max-width: 1075px) {
  .hide-gt-1075px {
    display: none !important;
  }
}
