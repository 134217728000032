.billing {
  &__row-plan-business {
    flex-direction: row;
    display: flex;

    .btn {
      padding: 10px 30px;
    }
  }

  .grid--flex {
    width: 100%;
    height: 100%;
  }

  .grid {
    .row {
      .col {
        float: initial;
      }
    }
  }

  &__row-plan > .col,
  &__row-plan .grid {
    height: 100%;
  }

  &__cta {
    width: 100%;
    height: 50%;
    padding: 30px 32px;
    display: flex;

    @media (min-width: 1740px) {
      height: 184px;
    }

    div {
      & > * {
        margin-bottom: 10px;
      }
      &:nth-child(1) {
        padding-right: 15px;
        h2 {
          font-size: 16px;
        }
        a {
          color: $pacificBlue;
        }
      }
      &:nth-child(2) {
        width: 134px;
        height: 101px;
        img {
          height: 100%;
        }
      }
    }
  }

  &__cta-business {
    width: 100%;
    padding: 30px 32px;
    display: flex;

    div {
      & > * {
        margin-bottom: 10px;
      }
      &:nth-child(1) {
        padding-right: 15px;
        h2 {
          font-size: 16px;
        }
        a {
          color: $pacificBlue;
        }
      }
      &:nth-child(2) {
        width: 134px;
        height: 101px;
        img {
          height: 100%;
        }
      }
    }
  }

  &__counter {
    width: 100%;
    margin-bottom: 24px;

    @media (max-width: 1739px) {
      margin-top: 24px
    }

    .headline {
      max-height: 2.28em;
    }

    &:not(:first-child) {
      margin-top: 24px;
    }

    @media all and (max-width: 1025px) {
      margin-top: 24px;
    }
  }
}

.billing__col-plan {
  display: flex;

  @media (max-width: 1741px) {
    width: 66.66667% !important
  }
  @media (min-width: 1740px) {
    width: 33.33333% !important
  }
  @media (max-width: 1281px) {
    width: 83.33333% !important
  }
  @media (max-width: 1000px) {
    width: 100% !important
  }
}

.billing__user-information {
  margin-top: 24px;
}
