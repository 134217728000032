@import "3_setup/1-variables";
@import "3_setup/7-mixins";

.login__user-info {
  padding: 0 32px;

  .info-details {
    padding-left: 0;
    padding-bottom: 10px;
    border-bottom: 1px solid $mediumGray; } }

.login__user-email {
  padding: 32px 32px 0;

  .form-group-grid {
    @include fleXCenterBetween;
    flex-wrap: wrap;

    .form-group {
      flex: 1 1 100px; }

    .form-group:first-child {
      padding-right: 5px; }
    .form-group:not(:first-child) {
      padding-left: 5px; }

    @media (max-width: 1000px) {
      .form-group:first-child {
        padding-right: 0; }
      .form-group:not(:first-child) {
        padding-left: 0;
        margin-top: 24px; }

      .form-group {
        min-width: 100%; } } }

  .btn-submit {
    margin-top: 24px; } }
