#confirm-email {
  .pricing-success__content {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 970px;
    padding: 78px 284px 82px 284px;

    @media all and (max-width: 768px) {
      padding: 40px 60px 40px 60px;
    }
  }

  .logo {
    margin-bottom: 70px;
    width: 130px;
    height: 52px;

    @media all and (max-width: 668px) {
      position: absolute;
      left: 55px;
      top: 30px;
    }

    @media all and (max-width: 320px) {
      left: 0px;
    }
  }

  .middle-image { 
    @media all and (max-width: 668px) {
      margin-top: 100px;
    }
  }

  .standard-title {
    margin: 40px 0 12px 0;
  }

  .message {
    margin-bottom: 24px;
  }

  .sidebar__language {
    position: absolute;
    right: 70px;
    top: 20px;
    width: 175px;
    border-top: none !important;
    z-index: 10;

    &.js-show {
      .language--list {
        display: block;
      }
    }

    .language {
      height: 64px;
      padding-left: 49px;
      color: $gray;
      position: relative;
      transition: background 0.2s ease, color 0.2s ease;

      @media (max-width: 688px) {
        padding-left: 50px;
      }

      img {
        width: 16px;
        height: 12px;
        position: absolute;
        left: 25px;

        @include translateTop;
      }

      &--current {
        height: 64px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        padding-left: 0;
        cursor: pointer;

        &:hover {
          background: $pacificLight;
          color: $pacificBlue !important;
        }

        &::before {
          content: "A";
          width: 15px;
          height: 15px;
          font-family: "Glyphter";
          position: absolute;
          right: 20px;
          top: 50%;
          line-height: 15px;
          transform: rotate(90deg) translateY(-50%);
          transform-origin: top;
          transition: opacity 0.1s ease 200ms, visibility 0.1s ease 200ms;
          z-index: 5;
        }

        .shrink & {
          &::before {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0s ease, visibility 0s ease;
          }
        }
      }

      &--list {
        position: absolute;
        bottom: -73px;
        left: 0;
        width: 100%;
        height: 132px;
        background: $white;

        @include wideShadow;
        padding-top: 10px;
        z-index: 10;
        display: none;

        .shrink & {
          width: 100%;
          left: 0;
        }

        .language {
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          margin: 6px 0;
        }
      }
    }

    @media (max-width: 1000px) {
      right: 10px;
      top: 20px;
    }

    .sidebar__menu-item::before {
      left: 15px;
    }
  }

  .sidebar__menu-item {
    &:hover {
      &::before {
        @media (max-width: 688px) {
          color: $pacificBlue !important;
        }
      }
    }
  }

  .language--current {
    height: 66px;
  }

  .login--language {
    @media (max-width: 688px) {
      color: $gray !important;
      font-weight: 500;
    }
  }

  .email-confirmation-content {
    padding: 0;

    @media all and (max-width: 768px) {
      padding: 0;
    }
  }
}