.popup-illustration {
  position: fixed;
  display: flex;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.67);

  &>.popup-illustration__content {
    position: relative;
    display: flex;
    max-width: 800px;
    margin: auto;
    background: #FFFFFF;
    width: 590px;

    @media (max-width: 800px) {
      display: block;
      width: 350px;
    }

    &>.popup-illustration__image {
      display: flex;
      align-items: center;
      height: auto;
      width: 50%;
      justify-content: flex-end;

      @media (max-width: 800px) {
        width: 100%;
      }

      &>img {
        width: 100%;

        @media (max-width: 800px) {
          padding: 15px;
        }
      }
    }

    &>.popup-illustration__content-body {
      padding: 15px;
      height: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 50%;
      margin: 24px 15px 24px 0;

      @media (max-width: 800px) {
        width: 100%;
        margin: 0;
        height: fit-content;
      }

      & .popup-illustration__title {
        margin-bottom: 15px;
      }

      & .popup-illustration__message {
        margin-bottom: 15px;
      }
    }
  }
}