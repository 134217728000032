.popup__filter {
  margin-top: 10px;

  form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media all and (max-width: 1000px) {
      flex-direction: column;
    }

    label {
      margin-top: 0;
    }
  }

  .form-group--select {
    width: 184px;
    margin-right: 34px;

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  .form-wrap--search {
    width: 370px;

    @media all and (max-width: 1000px) {
      width: 100%;
      margin-top: 8px;
    }
  }
}

.popup__list {
  height: 420px;
  padding-top: 10px;
  border-top: 1px solid $mediumGray;
  margin-top: 24px;

  &--document-categories {
    margin-top: 45px;
  }

  li {
    @include flexCenter;

    height: 40px;

    @media all and (max-width: 1000px) {
      flex-direction: column;
      position: relative;
      font-size: 0.85em;
      justify-content: center;
      height: 50px;
    }

    .list__name {
      width: 40%;
      font-weight: 500;

      @media all and (max-width: 1000px) {
        width: 100%;
      }
    }

    .list__email {
      width: 55%;

      @media all and (max-width: 1000px) {
        width: 100%;
        margin-top: 5px;
      }
    }

    .btn {
      width: 100%;

      @media all and (max-width: 1000px) {
        font-size: 1em;
        position: absolute;
        right: 13px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .status {
      font-size: 1em;

      @media all and (max-width: 1000px) {
        position: absolute;
        right: 13px;
        font-size: 1em;
        top: 50%;
        transform: translateY(-50%);
        width: 23%;
      }
    }
  }
}

.form-group--share-link {
  width: 100% !important;
  .form-group__input {
    width: 388px;

    @media all and (max-width: 1000px) {
      margin-bottom: 16px;
    }
  }
}

.popup__password-protected {
  padding: 24px;
  background: $mediumGray;
  margin-top: 32px;

  &--top {
    @include fleXCenterBetween;
  }

  &--bottom {
    margin-top: 24px;
    display: none;

    @media all and (max-width: 1000px) {
      margin-top: 0;
    }

    form {
      display: flex;
      align-items: flex-end;

      @media all and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      .form-group {
        margin-right: 16px;

        @media all and (max-width: 1000px) {
          margin-right: 0;
          width: 100%;
        }
      }
    }

    &.js-show {
      display: block;
    }

    .form-wrap--code {
      .error {
        display: block;
        margin-top: 5px;
        position: absolute;
        color: $red;
        font-size: 0.7em;
      }
    }

    .btn--primary {
      margin-top: 32px;

      @media all and (max-width: 1000px) {
        margin-top: 15px;
      }
    }
  }
}