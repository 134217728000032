////////////////////////////////////////////////////////////////////////
// THEME TYPOGRAPHY
////////////////////////////////////////////////////////////////////////

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1, h2, h3, h4, h5, h6, ul, ol, p {
  margin-top: 0;
  margin-bottom: 0;
}

ul {
  list-style: none;
  padding-top: 0;
}

body {
  font-size: $body-font-size;
  font-family: $font-primary;
  color: $color-primary;
}

h1 {
  font-size: 4.5em;

  &.page-title {
    font-size: 1.7em;

    @media all and (max-width: 1000px) {
      font-size: 1.14em;
    }
  }
}

h2 {
  font-size: 3.4em;
}

h3 {
  font-size: 2.5em;
}

#privacy-policy {
  h3 {
    margin-top: 36px;
  }
}

h4 {
  font-size: 1.7em;
}

h5 {
  font-size: 1.14em;
}

h6 {
  font-size: 0.6em;
}

p {
  line-height: $body-line-height;
}

a {
  text-decoration: none;
}

q {
  font-size: 1.5em;
}

blockquote {
  font-style: italic;
}

article {
  p, ul, ol {
    margin-bottom: 1em;
  }
}

.strong-text {
  font-weight: 500;
  color: $color-primary;
}

.strong-text-grey {
  font-weight: 500;
  color: $gray;
}

.standard-title {
  font-size: 1.14em;
  font-weight: 500;
}
