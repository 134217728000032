.validation-report-modal .popup__content {
    padding-top: 7px;
    padding-bottom: 30px;

    & .tabs-list {
        margin-top: initial;
        margin-bottom: initial;
    }

    & .tabs-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &__tab {
            height: 100%;
            display: none;

            &.active {
                display: flex;
                flex-direction: column;
                gap: 7px;
            }

            & > .bottom-buttons {
                display: flex;
                justify-content: center;
            }
        }

        & .preview__document-content {
            margin-top: 0px;
            padding: 7px 7px;

            &.xml {
                font-family: "Monaco", "Gordita", sans-serif;
                overflow: scroll;
            }
        }
    }
}