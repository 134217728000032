.info-details {
  padding-left: 35px;
  margin-top: 24px;

  @media all and (max-width: 1000px) {
    padding-left: 2px;
  }

  &--signing-order {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  &--members {
    &__inline {
      .info-details__info.info-details__info--members {
        @media (max-width: 1500px) {
          flex-direction: row;
          align-items: flex-start;

          .document-list__select-filters {
            margin-top: 0;
          }

          .info-details__purpose {
            width: 100%;
          }
        }
      }
      .info-details__info {
        width: 100%;
        justify-content: end;

        .info-details__purpose {
          max-width: 230px;
          display: flex;
          flex-direction: row;

          .document-list__select-filters {
            margin-right: 10px;
          }
        }

        @media (max-width: 1600px){
          .info-details__label {
            margin-bottom: 8px;
          }

          .controls-buttons {
            width: 11%;
          }
        }
      }
    }
  }

  .edit-document-title {
    display: flex;

    input[name="documentTitle"] {
      max-width: 170px;
      border-radius: 10px !important;
    }

    .save-title {
      background: transparent;
      border: none;

      &::before {
        content: url("../../img/icons/save.svg");
        margin-left: 5px;
        height: 20px;
        cursor: pointer;
        display: block;
      }
    }
  }

  #edit-title-mode {
    margin-right: 0;
  }

  .choose-placing {
    padding-left: 16px;
    width: 100%;
  }

  li {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 8px 0;

    @media print {
      padding: 2px 0;
    }

    @media all and (max-width: 1000px) {
      align-items: flex-start;
    }
  }

  &__purpose {
    display: flex;
    width: 100%;

    .info-details__label {
      width: 100%;
    }

    .document-list__select-filters{
      width: 100%;
    }
  }

  &__select {
    width: 100%;
    max-width: 195px;

    & > * {
      width: 100%;
    }
  }

  &--standard {
    padding-left: 0;

    .info-details__info {
      &--plan, &--no-plan {
        border: 1px solid $mediumGray;
        width: 358px;
        padding: 16px;
        display: flex;
        justify-content: space-between;

        p {
          margin-bottom: 5px;
        }

        .validity {
          font-weight: 500;

          &--valid {
            color: $green;

            span {
              font-weight: $regular;
            }
          }

          &--expired {
            color: $red;
          }
        }
      }

      &--no-plan {
        width: auto;
      }
    }

    @media all and (max-width: 1000px) {
      li {
        flex-direction: column;

        .info-details__info {
          width: 100%;
          margin-top: 4px;
        }

        .info-details__label {
          font-size: 0.71em;
        }
      }
    }
  }

  &--with-purpose {
    li {
      display: block;
    }

    .info-details__label {
      width: 100%;
    }

    .info-details__info {
      justify-content: start;
    }
  }

  &__row {
    position: relative;

    &--members {
      &::before {
        content: url("../../img/icons/draggable.svg");
        margin-right: 10px;
      }

      @media all and (max-width: 1500px) {
        &::before {
          margin-right: 0;
          position: absolute;
          left: 0;
          top: calc(50% - 11px);
        }

        .info-details__label,
        .info-details__info {
          padding-left: 30px;
        }
      }
    }
  }

  &__label {
    width: 34%;
    color: $gray;
    position: relative;
    padding-right: 10px;

    &--comments {
      min-width: 34%;
      color: $gray;

      b {
        color: $color-primary;
      }

      @media all and (max-width: 1000px) {
        min-width: 50%;
      }
    }

    @media all and (max-width: 1000px) {
      width: 50%;
    }

    &--shorter {
      width: 17%;

      @media all and (max-width: 1000px) {
        width: 50%;
      }
    }

    &--members {
      &::before {
        content: url("../../img/icons/draggable.svg");
        margin-right: 10px;
      }
    }

    label {
      overflow: hidden;
      overflow-wrap: break-word;
    }

    &--index {
      margin-right: 10px;
    }
  }

  &__info {
    width: 66%;

    @include flexCenter;

    .btn--reminder__disabled {
      margin-right: 0;
    }


    &--comments {
      max-width: 60%;
    }

    @media all and (max-width: 1000px) {
      width: 50%;
      flex-direction: column;
      align-items: flex-start;

      .form-group__radio {
        margin-right: 24px;
        padding: 15px 0;
      }
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;
    }

    &--categories {
      display: block;

      &.disabled-checkbox {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
      }

      & > .btn {
        padding: 0;
        width: auto;
      }

      & > * {
        flex-basis: content;
      }
    }

    &--members {
      width: 55%;
      justify-content: space-between;
      margin-top: 8px;

      .btn--reminder {
        margin-right: 0;
        padding-right: 24px;
      }
    }

    > * {
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }

      @media all and (max-width: 1000px) {
        margin-right: 0;
        width: 100%;
      }
    }

    p {
      font-weight: 500;
      overflow: hidden;
      overflow-wrap: break-word;

      a {
        &.remove-category {
          &::before {
            margin-left: 5px;
            cursor: pointer;
            content: url("../../img/icons/close.svg");
          }
        }

        &.edit-title {
          &::before {
            content: url("../../img/icons/pencil.svg");
            margin-left: 5px;
            cursor: pointer;
            position: relative;
            right: 0;
            top: 0;
          }
        }
      }
    }

    @media all and (max-width: 1000px) {
      .controls-buttons,
      .controls-buttons .controls__btn {
        margin-left: auto;
      }
    }

    .form-group__radio {
      padding: 7px 0;
    }
  }

  &--wider {
    li {
      min-height: 40px;

      @media all and (max-width: 1500px) {
        flex-direction: column;
        position: relative;

        .info-details__label, .info-details__info {
          width: 100%;
        }

        .info-details__info {
          flex-direction: row;
          align-items: center;

          > * {
            width: 50%;
          }

          .info-details__select {
            width: 100%;
          }

          .status {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }

      @media all and (max-width: 1000px) {
        .info-details__info {
          .status {
            padding-top: 4px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }

  &--w50 {
    .info-details__label, .info-details__info {
      width: 50%;
    }
  }

  &--plan-details {
    li {
      padding: 3px 0;
    }
  }

  &__document-history {
    font-size: 0.71em;
    color: $gray;

    b, strong {
      color: $color-primary;
    }
  }

  &__status {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;

    @include translateTop;

    left: -13px;

    &--owner {
      background: $green;
    }
  }

  &__history-list {
    max-height: 260px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    width: 75%;

    @media all and (max-width: 1100px) {
      width: 100%;
    }

    &.js-show-all {
      max-height: 100%;
    }

    .info-details__dots {
      width: 7%;
      position: relative;
      height: 20px;

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        border-radius: 50%;
        border: 2px solid $primaryGray;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;

        @media all and (max-width: 767px) {
          top: 4px;
        }
      }

      &::after {
        content: "";
        width: 1px;
        height: 35px;
        background: $mediumGray;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 14px;

        @media all and (max-width: 767px) {
          top: 19px;
        }
      }
    }

    .info-details__label {
      width: 32%;

      @media all and (min-width: 1700px) {
        width: 32%;
      }

      @media all and (max-width: 1000px) {
        font-size: 0.85em;
      }
    }

    .info-details__info {
      width: 61%;
      padding-left: 7%;

      @media all and (max-width: 1000px) {
        padding-left: 11%;
      }
    }

    li {
      justify-content: space-between;
      align-items: center;

      &:last-child {
        .info-details__label {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  &__gray-area {
    @include fleXCenterCenter;

    height: 140px;
    background: $lightGray;
    margin-top: 32px;

    @media all and (max-width: 1000px) {
      margin-top: 24px;
    }

    &--clickable {
      cursor: pointer;
    }

    .btn {
      font-size: 1em;
    }
  }

  label[for="is-forbid-signature"] {
    @media (max-width: 1500px) {
      margin-top: 8px;
    }
  }
}

.arrow-down {
  width: 15px;
}

#show-more {
  margin-top: -65px;

  .btn {
    position: relative;
    left: 21%;

    @media all and (max-width: 1000px) and (min-width: 767px) {
      left: 28%;
    }
  }

  &.js-show-less {
    margin-top: 0;

    .show-more__overlay {
      display: none;
    }
  }
}

.show-more__overlay {
  width: 100%;
  height: 65px;
  display: block;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.98) 0%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(180deg);
}

.status-tooltip {
  &::after {
    content: attr(data-tooltip);
    height: 20px;
    width: auto;
    padding: 0 8px;
    background: $color-primary-opacity;

    @include fleXCenterCenter;

    color: $white;
    font-size: 0.7em;
    position: absolute;
    top: 17px;
    transform: translateX(-50%);
    left: 50%;
    display: none;
    pointer-events: none;
    white-space: nowrap;
  }

  &::before {
    content: "";
    background-image: url("../../img/icons/tooltip-triangle.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    display: none;
  }

  &--info-icon {
    &::after {
      content: url("../../img/icons/info.svg");
    }
  }
}

.status-tooltip--hover {
  position: relative;

  &:hover {
    .status-tooltip::after, .status-tooltip::before {
      display: flex;
    }
  }
}

.info-icon-grey {
  display: inline-block;
  vertical-align: middle;

  &::after {
    content: url("../../img/icons/grey-info.svg");
  }
}

.document-validation {
  transition: 0.4s;

  &__document-header {
    display: none;

    @media (max-width: 1000px) {
      display: flex;
      justify-content: space-between;

      .preview__controls {
        display: flex;
        height: auto;
        margin: 0;
        border: none;

        .controls__btn {
          width: 48px;
        }

        .controls__btn--delete {
          display: none;
        }
      }
    }

    @media print {
      display: none;
    }
  }

  &__align_center {
    display: flex !important;
    align-items: center !important;
  }

  &__align_center {
    display: flex !important;
    align-items: center !important;
  }

  .info-details__info {
    p {
      @media (max-width: 1000px ) {
        width: 100%;
      }
    }
  }

  &__qualified-signature {
    color: $pacificBlue;
    margin-right: 5px
  }

  &__header {
    border-bottom: 1px solid $gray;
    padding-bottom: 15px;
    margin-bottom: 15px;
    cursor: pointer;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: flex-start;

      & > * {
        margin: 5px 0;
      }
    }

    .document-validation__to-print {
      display: none;

      @media print {
        display: block;

        .document-validation__username {
          font-size: 14px;
          font-weight: 500;
          color: #0096c7;
        }

        .status--signed {
          float: right;
          margin-top: 2px;

          @include breakpoint(xs) {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .document-validation__qualified-signature {
          float: right;
        }
      }
    }

    .document-validation__to-display {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      @media print {
        display: none;
      }

      @include breakpoint(xs){
        flex-direction: column;
        align-items: flex-start;
      }

      .status {
        font-size: 14px;
        display: flex;
      }

      .document-validation__validation-information {
        display: flex;
        align-items: center;

        @include breakpoint(xs){
          flex-direction: column;
          align-items: flex-start;
        }
      }

      span {
        display: flex;
        margin-right: 5px;

        img {
          margin-left: 5px;
          transition: .2s ease;
        }
      }

      h3 {
        font-size: 14px;
        color: $pacificBlue;
      }
    }
  }
  &__certificates {
    max-height: 0;
    overflow: hidden;
    transition: 200ms ease-in-out;

    h3 {
      font-size: 14px;
      color: $pacificBlue;
      margin-top: 24px;

      @media print {
        margin-bottom: 4px;
      }
    }
  }
}

@media print {
  .document-validation__certificates {
    max-height: 100%;
    margin-bottom: 50px;
  }
}

.active > .document-validation__certificates {
  max-height: 100%;
  margin-bottom: 50px;
}
.active > .document-validation__header {
  div {
    span {
      img {
        transform: rotate(180deg);
        transition: .2s ease;
      }
    }
  }
}

.label-perks {
  padding-bottom: 5px;
}

