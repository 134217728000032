.display-tablet {
  @media (width: 1000px) {
    display: block;
  }
  @media (min-width: 1001px) {
    display: none !important;
  }
}

.widget-shrink.temporary-document-preview {
  width: 100%;

  @media (min-width: 1001px) {
    padding-bottom: 0;
    height: 100vh;
  }
}

.temporary-document-preview {
  @media (min-width: 1001px) and (max-width: 1345px) {
    padding: 0 60px;
  }

  @media (min-width: 1346px) {
    float: initial;
    padding: 0 120px;
  }

  @media (max-width: 1000px) {
    padding-bottom: 0;
  }

  .row .col {
    &__information {
      @media (min-width: 1001px) {
        width: 33%;
        padding: 0;
        float: right;
      }
    }

    &__preview {
      @media (min-width: 1001px) {
        width: 65%;
      }

      .preview__controls {
        justify-content: end;
        height: 86px;
      }
    }
  }

  .header {
    height: 66px;
    margin-left: -120px;
    margin-right: -120px;
    padding: 0 120px;
    margin-bottom: 50px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
    border-bottom: 1px solid rgb(237, 239, 242);

    @media (max-width: 1000px) {
      margin: 0 0 15px;
      padding: 0;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }

    .language-container {
      float: right;
      position: relative;
      max-width: 175px;
    }

    .language--current .language,
    .language--list .language {
      padding-left: 49px;
    }

    .language .sidebar__menu-item::before {
      left: 15px;
    }

    .logo {
      display: inline-block;

      .logo__img {
        height: 64px;
      }

      @media (max-width: 1000px) {
        margin-left: 24px;
      }
    }
  }

  &__section-headline {
    margin: 32px 0;
    text-align: center;
    font-size: $fs-xl;
  }

  .wrapped-section {
    padding: 0;

    .headline {
      margin-bottom: 10px;
      font-size: $fs-xl;

      @media (min-width: 1001px) and (max-width: 1200px) {
        font-size: $fs-lg;
      }
    }

    .info-details .info-details__label {
      width: 66%;
    }
    .info-details .info-details__info {
      width: 33%;
    }

    &__info, &__members {
      margin: 42px 32px 0 32px;
    }

    &__members {
      margin-bottom: 24px;

      @media (max-width: 1000px) {
        margin-bottom: 42px;
      }

      .wrapped-section {
        margin-bottom: 0;
        box-shadow: none;
      }

      .info-details .info-details__label {
        width: 100%;
      }
    }

    &__information {
      overflow-y: auto;
      max-height: 80vh;

      @media (max-width: 1000px) {
        max-height: 100%;
      }
    }
  }

  #bottom-bar {
    position: sticky;
    bottom: 0;
    border-left: none;
    display: flex;
    justify-content: center;
    padding: 24px;
    height: 100%;
  }

  .document-preview-v2 {
    padding: 0;
    background: initial;
    transition: width 0.3s ease-in-out;

    @media (max-width: 1000px) {
      margin-bottom: 90px;
    }

    .temporary-document {
      margin: 0 0 24px;
      box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
    }
  }

  .btn {
    margin: 0 5px;

    @media (min-width: 1000px) and (max-width: 1300px) {
      padding: 10px 19px;
    }
  }

  .preview__document-box {
    padding: 0 32px;
  }

  .preview__document-content {
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
  }

  .sidebar__language {
    .language {
      @media (max-width: 1000px) {
        margin-right: 24px;

        img {
          width: 16px;
          height: 12px;
        }
      }

      .shrink-hide {
        padding-right: 50px;

        @media (max-width: 350px) {
          padding-right: 20px;
        }
      }
    }

    .language--list {
      max-width: 212px;
      height: 145px;
      left: initial;
      top: -6px;
      padding-top: 0;

      @media (max-width: 1000px) {
        right: initial;
        top: 0;
      }
    }
  }
}

.temporary-document-preview .wrapped-section__information {
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 3px;
}

.temporary-tab {
  img {
    width: 20px;
    float: left;
    padding-right: 3px;
  }
}

.iframe-download-btn-section-tablet {
  text-align: center;
  padding-bottom: 10px;

  button {
    position: relative;
    color: $gray;
    margin: 0 24px;
    cursor: pointer;
    background: transparent;
    border: none;

    &:hover {
      color: $pacificBlue;
    }
  }
}