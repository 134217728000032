#empty-list {
  height: calc(100vh - 400px);

  @media all and (max-width: 1000px) {
    height: auto;
    margin-top: 70px;
  }

  .grid {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}

.empty__content {
  display: flex;
  align-items: center;
  flex-direction: column;

  @media all and (max-width: 1000px) {
    img {
      height: 125px;
    }
  }

  p {
    color: $gray;
    margin-top: 8px;
    text-align: center;

    @media all and (max-width: 1000px) {
      font-size: 0.85em;
    }
  }

  .btn {
    margin-top: 24px;
  }
}

.empty__title {
  font-size: 1.14em;
  margin-top: 20px;
}
