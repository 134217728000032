.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s, padding 0.3s;
  padding: 0 24px 0px 24px;
}

.accordion__element {
  &.active {
    .accordion__content {
      max-height: 100%;
      padding: 0 24px 15px 24px;
    }

    .accordion__title {
      .accordion__clickable {
        &::before {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }
  }
}

.accordion__content_addition {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s, padding 0.3s;
  padding: 0 24px 0px 24px;
}

.accordion__element {
  &.active {
    .accordion__content_addition {
      max-height: 1000px;
      padding: 0 24px 42px 24px;
      overflow: visible;
    }

    .accordion__clickable {
      &::before {
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
}

.accordion-document-category__title {
  height: 72px;
  padding: 0 26px 0 0px;
  font-weight: 500;

  @include fleXCenterBetween;

  position: relative;

  .headline {
    color: $color-primary;
  }
}

.accordion__title {
  height: 72px;
  padding: 0 26px 0 0px;
  font-weight: 500;

  @include fleXCenterBetween;

  cursor: pointer;
  position: relative;

  .accordion__clickable {
    padding-left: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    width: calc(100% - 250px);

    @media all and (max-width: 1000px) {
      width: calc(100% - 60px);
    }

    &::before {
      content: "A";
      font-family: "Glyphter";
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 15px;
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translateY(-50%) rotate(90deg);

      @media all and (max-width: 1000px) {
        left: 18px;
      }
    }

    @media all and (max-width: 1000px) {
      padding-left: 50px;
    }
  }

  @media all and (max-width: 1000px) {
    height: 64px;
    padding: 0 15px 0 0px;
  }

  .headline {
    color: $color-primary;
  }
}

.accordion__title__additional {
  height: 100px;
  padding: 0 26px 0 0px;
  margin-left: 30px;
  font-weight: 500;

  @include fleXCenterBetween;

  cursor: pointer;
  position: relative;

  .accordion__clickable {
    padding-top: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 250px);

    @media all and (max-width: 1000px) {
      width: calc(100% - 60px);
    }

    &::before {
      content: "A";
      font-family: "Glyphter";
      width: 15px;
      height: 15px;
      text-align: center;
      line-height: 20px;
      transform: translateY(-50%) rotate(90deg);

      @media all and (max-width: 1000px) {
        left: 30px;
      }
    }

    @media all and (max-width: 1000px) {
      padding-left: 100px;
    }
  }

  @media all and (max-width: 1000px) {
    height: 64px;
    padding: 0 15px 0 0px;

  }

  .headline {
    color: $color-primary;
  }
}

.accordion__controls {
  @include flexCenter;

  @media all and (max-width: 1000px) {
    .btn--add {
      font-size: 0;

      &::before {
        font-size: 14px;
      }
    }
  }
}

.accordion__controls-menu {
  position: absolute;
  top: 66px;
  right: 16px;

  @include wideShadow;

  padding: 12px 16px;
  background: $white;
  z-index: 2;
  min-width: 150px;
  display: none;

  .js-show-controls & {
    display: block;
  }

  .controls__btn {
    display: flex;
    align-items: center;
    width: auto;

    .icon,
    p {
      font-size: 1em;
    }

    p {
      margin-left: 16px;
      color: $color-primary;
    }

    &:hover {
      p {
        color: $pacificBlue;
      }
    }
  }
}

.additional_member {
  .wrapped-section:last-child {
    margin-bottom: 24px !important;
  }

  .info-details {
    margin-top: 0px !important;
  }

  .headline--members {
    margin-bottom: 2px !important;
  }
}