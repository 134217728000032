@mixin softShadow {
  box-shadow: 0px 4px 18px rgba(9, 18, 49, 0.02);
}

@mixin wideShadow {
  box-shadow: 0px 4px 18px rgba(9, 18, 49, 0.08);
}

@mixin smallShadow {
  box-shadow: 4px 0px 18px rgba(9, 18, 49, 0.02);
}

@mixin flexCenter {
  display: flex;
  align-items: center;
}

@mixin translateTop {
  top: 50%;
  transform: translateY(-50%);
}

@mixin translateCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fleXCenterCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin fleXCenterBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin translateY {
  top: 50%;
  transform: translateY(-50%);
}

// Media breakpoints
@mixin breakpoint($point) {
  @if $point == xl {
    @media (max-width: 1441px) {
      @content;
    }
  }
  @else if $point == lg {
    @media (max-width: 1281px) {
      @content;
    }
  }
  @else if $point == md {
    @media (max-width: 1025px) {
      @content;
    }
  }
  @else if $point == sm {
    @media (max-width: 769px) {
      @content;
    }
  }
  @else if $point == xs {
    @media (max-width: 645px) {
      @content;
    }
  }
  @else if $point == xxs {
    @media (max-width: 426px) {
      @content;
    }
  }
}

