@import "3_setup/1-variables";
@import "3_setup/7-mixins";

.tooltip {
  position: relative;

  &--inline {
    display: inline; }

  &::after {
    content: attr(data-tooltip);
    height: 20px;
    width: auto;
    padding: 0 8px;
    background: $color-primary-opacity;
    @include fleXCenterCenter;
    color: $white;
    font-size: .65rem;
    position: absolute;
    bottom: -25px;
    transform: translateX(-50%);
    left: 50%;
    display: none;
    pointer-events: none;
    white-space: nowrap;
    z-index: 100; }

  &--new-line {
    &::after {
      height: 30px;
      padding: 4px 8px;
      bottom: -43px;
      white-space: pre;
      z-index: 100; } }

  &--new-line-big {
    &::after {
      height: 60px; } }

  &--align-middle {
    vertical-align: middle; }

  &::before {
    content: "";
    background-image: url("../../../img/icons/tooltip-triangle.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    display: none; }

  &:hover::after, &:hover::before {
    display: flex;
    white-space: pre; } }

.tooltip_custom {
  position: relative;

  &--inline {
    display: inline; }

  &::after {
    content: attr(data-tooltip);
    height: 20px;
    width: auto;
    padding: 0 8px;
    background: $color-primary-opacity;
    @include fleXCenterCenter;
    color: $white;
    font-size: .65rem;
    position: absolute;
    bottom: -25px;
    transform: translateX(-50%);
    left: 50%;
    display: none;
    pointer-events: none;
    white-space: nowrap;
    z-index: 100; }

  &--new-line {
    &::after {
      height: 196px;
      width: 500px;
      font-size: 11.5px;
      padding: 4px 8px;
      left: 187px;
      bottom: 26px;
      white-space: pre;
      z-index: 100; } }


  &::before {
    content: "";
    background-image: url("../../../img/icons/tooltip-triangle.svg");
    background-position: center;
    background-repeat: no-repeat;
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 10px;
    left: -11%;
    transform: rotate(180deg);
    display: none; }


  &:hover::after, &:hover::before {
    display: flex; } }
