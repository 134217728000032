.form-wrap {
  background: $white;
  border: 1px solid $mediumGray;
  border-radius: 2px;
  height: 40px;
  transition: border 0.2s ease;

  &:hover {
    border-color: $pacificLight;
  }

  input {
    background: none;
    outline: none;
    border: none;
    height: 37px;
    padding: 0 12px;
    color: $gray;
    font-family: $font-primary;
    width: 100%;
    line-height: normal;

    &::-webkit-input-placeholder {
      color: $gray;
    }

    &:-ms-input-placeholder {
      color: $gray;
    }

    &::placeholder {
      color: $gray;
    }
  }

  &--search {
    position: relative;

    &::before {
      font-family: "Glyphter";
      content: "L";
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      position: absolute;
      left: 12px;

      @include translateTop;

      color: $gray;
    }

    input {
      padding-left: 44px;
    }
  }

  &--password {
    @include fleXCenterBetween;

    width: 250px;

    @media all and (max-width: 1000px) {
      width: 100%;
    }

    input {
      width: calc(100% - 60px);
    }

    &--full {
      width: 100%;
    }
  }
}

.login-content {
  .form-number-code {
    position: absolute;
    width: 48px !important;
    background: transparent;
    border: none;
    padding-left: 11px;
    padding-right: 0;
    color: #000;
  }

  .form-rest-phone-number {
    width: 100% !important;
    padding-left: 48px;
  }

  .form-group__input {
    width: 256px;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.signature-type-content {
  .form-number-code {
    position: absolute;
    width: 48px !important;
    background: transparent;
    border: none;
    padding-left: 11px;
    padding-right: 0;
    color: #000;
  }

  .form-rest-phone-number {
    width: 100% !important;
    padding-left: 48px;
  }

  .form-group__input {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.form-group {
  position: relative;

  .form-user-settings {
    display: flex;

    .form-user-settings__span {
      padding-top: 7px;
    }
  }

  @media all and (max-width: 1000px) {
    width: 100%;
  }

  &__checkbox, &__radio {
    position: relative;

    input {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      visibility: hidden;
    }

    label {
      position: relative;
      padding-left: 28px;
      line-height: 16px;
      cursor: pointer;
      display: block;
      font-size: 14px !important;
      margin-bottom: 0;
    }

    &--members {
      label {
        display: inline;
      }
    }
  }

  &--add_to_contact {
    width: 53%;
    margin: auto;

    @media all and (max-width: $mq-tablet) {
      width: 100%;
    }
  }

  &--show_inviter {
    margin-left: 8.65em;

    @media all and (max-width: $mq-tablet) {
      margin-left: 0;
    }
  }

  &--fixed-size {
    width: 250px;

    @media all and (max-width: 1000px) {
      width: 100%;
    }
  }

  &__checkbox {
    input {
      &:checked + label {
        &::before {
          background: $pacificBlue;
          border-color: $pacificBlue;
        }

        &::after {
          content: "a";
        }
      }

      &:checked:disabled + label {
        &::before {
          background: $primaryGray;
          border-color: $primaryGray;
        }
      }
    }

    label {
      &::before {
        content: "";
        width: 16px;
        height: 16px;
        border-radius: 3px;
        background: $white;
        border: 2px solid $mediumGray;
        position: absolute;
        left: 0;

        @include translateTop;

        transition: border 0.2s ease;
      }

      &::after {
        width: 14px;
        height: 14px;
        position: absolute;

        @include translateTop;

        left: 3px;
        font-family: "Glyphter";
        color: $white;
        text-align: center;
        font-size: 0.8em;
      }
    }

    &.after-text {
      label {
        padding-left: initial;
        padding-right: 28px;

        &::before {
          left: initial;
          right: 0;
        }

        &::after {
          left: initial;
          right: 3px;
        }
      }
    }

    &:hover {
      label {
        &::before {
          border-color: $pacificBlue;
        }
      }
    }

    &--single {
      width: 20px;
      height: 20px;

      label {
        padding-left: 0;

        &::before {
          top: 0;
          left: 0;
        }

        &::before, &::after {
          transform: translateY(0%);
        }
      }

      input:checked + label {
        &::after {
          top: 3px;
        }
      }
    }

    &--show-senders-name {
      margin-top: 15px;
      margin-bottom: -5px;
    }
  }

  &__radio {
    input {
      &:checked + label {
        &::before {
          border-color: $pacificBlue;
        }

        &::after {
          opacity: 1;
        }
      }
    }

    label {
      padding-left: 30px;

      &::before {
        content: "";
        width: 14px;
        height: 14px;
        border-radius: 50%;
        border: 2px solid $mediumGray;
        position: absolute;
        left: 0;

        @include translateTop;
      }

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $pacificBlue;
        position: absolute;

        @include translateTop;

        left: 4px;
        opacity: 0;
      }
    }
  }

  label {
    font-size: 0.71em;
    color: $gray;
    display: block;
    margin-bottom: 3px;
    font-weight: $fw-medium;
  }

  #settings-checkbox {
    font-weight: unset;
    margin-top: 20px;
  }

  .settings-checkbox {
    font-weight: unset;
    margin-top: 20px;
  }

  &__input {
    height: 40px;
    padding: 0 12px;
    background: $white;
    border: 1px solid $mediumGray;
    font-family: $font-primary;
    width: 100%;
    transition: border 0.2s ease;
    line-height: normal;
    -webkit-appearance: none;

    &--commentary-text {
      min-height: 80px;
    }

    &:hover, &:focus, &:active {
      border-color: $pacificLight;
    }
  }

  &--share-link {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &:hover, &:focus, &:active {
    border-color: hsl(0,0%,80%);
  }

  &--switcher {
    position: relative;

    @media all and (max-width: 1000px) {
      width: auto;
    }

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      visibility: hidden;
      display: none;
    }

    label {
      width: 55px;
      height: 32px;
      border-radius: 22px;
      background: $primaryGray;
      position: relative;
      transition: background 0.25s ease-in-out;
      will-change: background;
      cursor: pointer;
      margin: 0 !important;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: $white;
        position: absolute;
        top: 4px;
        left: 4px;
        transition: transform 0.25s ease-in-out;
        transform: translateX(0px);
        will-change: transform;
      }
    }

    input:checked + label {
      background: $green;

      &::before {
        transform: translateX(23px);
      }
    }
  }

  &--error {
    color: $red;

    label {
      color: $red;
    }

    .form-group__input {
      border-color: $red !important;
    }

    .form-group__error {
      display: block;
    }

    .form-group__comment {
      display: none;
    }
  }

  &__error {
    font-size: 0.71em;
    display: none;
    margin-top: 3px;
  }

  &__comment {
    color: $gray;
    font-size: 0.71em;
    position: absolute;
    left: 0;
    bottom: -15px;
  }

  &__submit-commentary {
    display: flex;

    .btn--primary {
      margin-top: 15px;
      flex-direction: row;
    }

    .private-comment-checkbox {
      flex-direction: row;
      margin-left: 10px;
      margin-top: 25px;
    }
  }
}

.input-minus-plus {
  display: flex;
  justify-content: center;

  * {
    height: 1.5em;
  }

  button {
    padding: 0 10px;
    background-color: transparent;
  }

  input[type=text] {
    margin: 0;
    padding: 0;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    max-width: calc(100% - 40px);
    min-width: 45px;
  }
}

.nice-select {
  position: relative;
  width: 100%;

  .current {
    height: 40px;
    line-height: 40px;
    border: 1px solid $mediumGray;
    padding: 0 35px 0 12px;
    color: $gray;
    display: block;
    font-size: 1em;
    cursor: pointer;
    position: relative;
    transition: border 0.2s ease;

    &::before {
      content: "Y";
      width: 20px;
      height: 20px;
      font-family: "Glyphter";
      position: absolute;

      @include translateTop;

      right: 5px;
      line-height: 20px;
      font-size: 0.7em;
      text-align: center;
    }

    &:hover, &:focus, &:active {
      border-color: $pacificLight;
    }
  }

  .list {
    background: $white;
    padding: 10px 0;
    position: absolute;
    left: 0;
    top: 42px;
    z-index: 12;
    width: 100%;

    @include wideShadow;

    display: none;

    li {
      height: 40px;
      line-height: 40px;
      padding: 0 16px;
      cursor: pointer;

      &:hover, &.selected {
        background: $pacificLight;
      }
    }
  }

  &.open {
    .list {
      display: block;
    }

    .current {
      &::before {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
}

.form-group_signatures-header {
  margin-bottom: 20px;
}

.form-group_signatures {
  display: flex;
  overflow-x: auto;
  @media (max-width: 400px) {
    overflow-x: auto;
    padding-bottom: 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  label {
    margin: 20px 15px 0 0;

    [type=radio] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }

    &[for="bill-document-owner"] {
      margin: 0;
    }
  }

  img:not(.pro-icon) {
    width: 100px;
  }

  p {
    text-align: center;
    margin-top: 10px;
  }
}

.form-group_signatures {
  label {
    [type=radio] + img {
      padding: 20px;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.form-group_signatures {
  label {
    [type=radio]:checked + img {
      padding: 18px;
      border-radius: 5px;
      border: 2px solid $mediumGray;
    }

    [type=radio]:checked + img + p {
      font-weight: $fw-medium;
      color: $color-primary;
    }
  }
}

.wrapped-section__headline {
  .form-group__checkbox {
    color: $darkGray;
  }
}

body .headline--information, body .headline--members {
  color: $color-primary;
}

.info-details {
  .form-group_signatures {
    label {
      p {
        font-size: 12px;
        font-weight: $fw-regular;
      }
    }
  }
}

.popup__content--faq.popup__content {
  padding: 0;

  label, p, h2 {
    color: $lightGray;
  }
}

.form {
  &--main {
    background-color: $pacificBlue;
    padding: 75px 120px 48px 120px;

    @media screen and (max-width: 1024px) {
      padding: 40px;
    }

    @media screen and (max-width: 540px) {
      padding: 40px 16px;
    }

    p {
      margin: 26px 0 10px 0;
    }

    a {
      margin: 17px 0 3px 5px;
      color: $white;
      text-decoration: underline;
      height: 20px;

      &:hover {
        color: #000;
      }
    }

    span {
      display: inline-flex;
    }

    .form__content {
      display: flex;
      flex-direction: column;

      input:not([type=checkbox]) {
        height: 40px;
        margin-bottom: 0;
        padding: 0 12px;
      }

      input:not([type=checkbox]), textarea {
        font-family: $font-primary;
        border: 1px solid $lightGray;
        border-radius: 2px;
        color: $color-primary;
        outline: none;
      }

      textarea {
        height: 142px;
        resize: none;
        padding: 8px 12px;
      }

      label:not([for=accept]) {
        font-weight: $fw-medium;
        font-size: 0.7em;
        color: $lightGray;
        margin-bottom: 3px;
      }

      label[for=accept] {
        position: relative;
        display: flex;
        align-items: center;
        height: 20px;
        padding-left: 28px;
        margin-bottom: 36px;
        font-size: 0.89em;

        &.active {
          &::before {
            background-color: $darkGray;
            border-color: $darkGray;
          }

          &::after {
            display: block;
          }
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              border-color: $blue;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          background-color: $white;
          border: 2px solid $lightGray;
          border-radius: 3px;
          transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
          will-change: background-color, border-color;
        }

        &::after {
          display: none;
          content: url("/icons_thumb/form-checkmark.svg");
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
    }

    input[type=checkbox] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
}

::placeholder {
  color: $darkGray;
}

label[for="signing-order"] {
  padding-right: 5px;

  @media (max-width: 350px) {
    font-size: 0.75em !important;
  }
}

.popup__content--faq.popup__content {
  padding: 0;

  label, p, h2 {
    color: $lightGray;
  }
}

.form {
  &--main {
    background-color: $pacificBlue;
    padding: 75px 120px 48px 120px;

    @media screen and (max-width: 1024px) {
      padding: 40px;
    }

    @media screen and (max-width: 540px) {
      padding: 40px 16px;
    }

    p {
      margin: 26px 0 10px 0;
    }

    a {
      margin: 17px 0 3px 5px;
      color: $white;
      text-decoration: underline;
      height: 20px;

      &:hover {
        color: #000;
      }
    }

    span {
      display: inline-flex;
    }

    .form__content {
      display: flex;
      flex-direction: column;

      input:not([type=checkbox]) {
        height: 40px;
        margin-bottom: 0;
        padding: 0 12px;
      }

      input:not([type=checkbox]), textarea {
        font-family: $font-primary;
        border: 1px solid $lightGray;
        border-radius: 2px;
        color: $color-primary;
        outline: none;
      }

      textarea {
        height: 142px;
        resize: none;
        padding: 8px 12px;
      }

      label:not([for=accept]) {
        font-weight: $fw-medium;
        font-size: 0.7em;
        color: $lightGray;
        margin-bottom: 3px;
      }

      label[for=accept] {
        position: relative;
        display: flex;
        align-items: center;
        height: 20px;
        padding-left: 28px;
        margin-bottom: 36px;
        font-size: 0.89em;

        &.active {
          &::before {
            background-color: $darkGray;
            border-color: $darkGray;
          }

          &::after {
            display: block;
          }
        }

        @media (hover: hover) {
          &:hover {
            &::before {
              border-color: $blue;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 16px;
          height: 16px;
          background-color: $white;
          border: 2px solid $lightGray;
          border-radius: 3px;
          transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
          will-change: background-color, border-color;
        }

        &::after {
          display: none;
          content: url("/icons_thumb/form-checkmark.svg");
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
    }

    input[type=checkbox] {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
    }
  }
}

::placeholder {
  color: $darkGray;
}

.disabled-checkbox {
  display: flex !important;
  align-items: center;

  label, span {
    color: $gray;
    margin-right: 0;
  }

  &:hover {
    label, span {
      cursor: initial;
      color: $gray;

      &:before {
        border-color: $mediumGray;
        cursor: initial;
      }
    }
  }

  .btn {
    padding: 10px 10px 10px 30px;
  }

  .pro-icon__mobile-adjustment {
    margin-right: 8px;

    @media (min-width: 538px) and (max-width: 1000px) {
      .tooltip--new-line {
        &::after {
          left: -80px;
        }
      }
    }

    @media (max-width: 537px) {
      .tooltip--new-line {
        &::after {
          left: -62px;
        }
      }
    }
  }
}

.border-white {
  border: white !important;
}
