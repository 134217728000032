.wrapped-section {
  background: $white;

  @include smallShadow;

  padding: 42px 32px;
  margin-bottom: 24px;

  @media print {
    padding: 0 32px;
    margin-bottom: 0;
  }

  @media all and (max-width: 1000px) {
    padding: 26px 20px;
    margin-bottom: 16px;
  }

  &--shrink {
    padding: 0;
  }

  &__headline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 560px) {
      display: block;
    }
    label[for='bill-document-owner'] {
      @media (max-width: 537px) {
        margin-top: 10px;
        margin-left: 6px;
      }
    }
  }

  &__bill-document-check {
    margin-top: 12px;
    color: $gray;
  }
}

.headline {
  font-size: 1.14em;
  color: $gray;
  padding-left: 35px;
  position: relative;
  padding-right: 16px;
  white-space: nowrap;

  @media all and (max-width: 1000px) {
    font-size: 1em;
  }

  &::before {
    font-family: "Glyphter";
    width: 30px;
    height: 30px;

    @include fleXCenterCenter;

    position: absolute;
    left: 0;
    color: $primaryGray;
    font-size: 1.5em;

    @include translateTop;
  }


  &--standard {
    padding-left: 0;
    padding-right: 0;
    white-space: initial;
  }

  &--wrap {
    white-space: initial;
  }

  &--document-information {
    &::before {
      content: "e";
    }
  }

  &--information {
    &::before {
      content: "g";
    }
  }

  &--document-alert {
    &::before {
      content: "S";
      color: $red;
    }
  }

  &--members {
    &::before {
      content: "H";
    }
  }

  &--history {
    &::before {
      content: "E";
    }
  }

  &--extra-settings {
    &::before {
      content: "j";
    }
  }

  &--plan {
    margin-top: 10px;
    color: $color-primary;
    font-weight: $fw-regular;
    font-size: $fs-3xl;
  }

  &--comments {
    margin-bottom: 15px;

    &::before {
      content: "d";
    }
  }

  a {
    &.edit-title {
      &::before {
        content: url("../../img/icons/pencil.svg");
        margin-left: 5px;
        cursor: pointer;
        position: relative;
        right: 0;
        top: 0;
      }
    }

    &.save-title {
      background: transparent;
      border: none;

      &::before {
        content: url("../../img/icons/save.svg");
        margin-left: 5px;
        height: 20px;
        cursor: pointer;
        right: 0;
        top: 0;
      }
    }

  }
}

.reminder-type {
  display: flex;

  & > div {
    padding-right: 8px;
    min-width: 118px;
  }

  &__sms, &__email {
    display: flex;
  }

  .form-group__checkbox {
    display: flex;
    align-items: center;
  }
}

.checkbox-selector {
  width: 100%;
  overflow: hidden;
}

.btn--primary, .btn--secondary {
  &.loading {
    &::after {
      content: "";
      position: absolute;
      top: 20%;
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 3px solid transparentize($white, 0.4);
      border-top: 3px solid $white;
      animation: spin 2s linear infinite;

      @keyframes spin {
        100% {
          transform: rotateZ(360deg);
        }
      }
    }
  }
}

#preview {
  .wrapped-section__headline--two {
    @media (min-width: 1001px) and (max-width: 1640px) {
      display: block;
    }
  }

  & .headline {
    color: $color-primary;
  }

  .controls__btn {
    color: $pacificBlue;
    .icon::before {
      color: $pacificBlue;
    }
    p {
      color: $pacificBlue;
    }
    div {
      @media (max-width: 1000px) {
        display: none;
      }
    }
    &:hover {
      color: $pacificDark;
      .icon::before {
        color: $pacificDark;
      }
      p {
        color: $pacificDark;
      }
    }
  }
}

.form-group__checkbox {
  &.signature-deduction {
    margin-top: 24px;

    &:not(.disabled-checkbox) {
      & label {
        color: $color-primary;
      }
    }
  }
}

.document-preview {
  &__modal {
    .pricing-table__modal {
      position: initial;
      background: $white;
    }

    .standard-title {
      display: none;
    }

    .popup__close {
      z-index: 1;
    }

    .pricing-table__row-heading {
      margin-bottom: 30px;
    }

    .pricing-table__sub-rows {
      line-height: 22.4px;
      margin: 0;
    }

    &--buttons {
      margin-top: 9px;
      display: flex;
      justify-content: space-between;
    }
  }
}